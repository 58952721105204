"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductContentsForMobile = void 0;
var goober_1 = require("goober");
var preact_1 = require("preact");
var src_1 = require("../../../../../lib/component/src");
var src_2 = require("../../../../../lib/const/src");
var preview_1 = require("../../module/preview");
var user_config_1 = require("../../module/user-config");
var chapter_list_1 = require("../common/chapter-list");
var content_renderer_1 = require("../common/content-renderer");
var preview_card_1 = require("../common/preview-card");
var content_info_1 = require("./content-info");
var DefaultFinishButtonMessage = "視聴終了";
var ProductContentsForMobile = function (_a) {
    var pageState = _a.pageState, backToPreviousQuestion = _a.backToPreviousQuestion, handleClickNextButton = _a.handleClickNextButton, sending = _a.sending, handleChangeCurrentChapter = _a.handleChangeCurrentChapter, setVideoPlayer = _a.setVideoPlayer;
    var _b = preview_1.usePreviewState(), shouldSendLog = _b.shouldSendLog, showPopup = _b.showPopup;
    var _c = user_config_1.useUserConfig(), hashToken = _c.hashToken, sessionId = _c.sessionId;
    if (pageState.type !== "loaded")
        return null;
    return (preact_1.h(preact_1.Fragment, null,
        showPopup ? preact_1.h(preview_card_1.PreviewCard, { isMobile: true }) : null,
        preact_1.h("div", { className: styles.innerWrapper },
            preact_1.h("div", { className: styles.contentWrapper },
                preact_1.h(content_renderer_1.ContentRenderer, { content: pageState.content, config: pageState.config, className: styles.content, loggingOption: {
                        loggerEndpoint: pageState.loggerEndpoint,
                        userId: undefined,
                        placement: pageState.linkType === "hearing" ? "hearing_link" : "none",
                        serviceResourceId: pageState.serviceResourceIdForLogger + ":" + pageState.content.serviceResourceIdForLogger,
                        logging: !!shouldSendLog,
                        customValue: { sales_session_id: sessionId },
                    }, setVideoPlayer: setVideoPlayer })),
            preact_1.h(content_info_1.ContentInfo, { key: pageState.content.contentId, title: pageState.content.title, description: pageState.content.public_description || "", className: styles.contentInfo, isMobile: true }),
            pageState.content.contentType === "videos" &&
                pageState.content.chapters &&
                pageState.content.chapters.length > 0 && (preact_1.h(chapter_list_1.ChapterList, { className: styles.chapterList, chapters: pageState.content.chapters, handleClickChapter: function (chapterIndex) {
                    return handleChangeCurrentChapter(chapterIndex);
                } }))),
        preact_1.h("div", { className: styles.buttonRowWrapper },
            preact_1.h(src_1.ButtonRow, { onClickNext: handleClickNextButton, onClickBack: backToPreviousQuestion, canGoNext: true, cursor: pageState.cursorPositon, sending: sending, finishButtonMessage: DefaultFinishButtonMessage, hideFinishButton: pageState.linkType === "default" }))));
};
exports.ProductContentsForMobile = ProductContentsForMobile;
var styles = {
    innerWrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""]))),
    contentWrapper: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    margin-top: 16px;\n  "], ["\n    width: 100%;\n    margin-top: 16px;\n  "]))),
    content: goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100vw;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "], ["\n    width: 100vw;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "]))),
    contentInfo: goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin: 16px;\n  "], ["\n    margin: 16px;\n  "]))),
    chapterList: goober_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin: 16px 16px 0px;\n  "], ["\n    margin: 16px 16px 0px;\n  "]))),
    buttonRowWrapper: goober_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin: 16px;\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n  "], ["\n    margin: 16px;\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n  "])), src_2.BUTTON_ROW_BACK, src_2.BUTTON_ROW_NEXT, src_2.BUTTON_ROW_SEND),
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
