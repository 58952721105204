"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewSegment = void 0;
var log_1 = require("./log");
var FirstIndexNotFoundError = /** @class */ (function (_super) {
    __extends(FirstIndexNotFoundError, _super);
    function FirstIndexNotFoundError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FirstIndexNotFoundError;
}(Error));
var FirstIndexIsNegativeError = /** @class */ (function (_super) {
    __extends(FirstIndexIsNegativeError, _super);
    function FirstIndexIsNegativeError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FirstIndexIsNegativeError;
}(RangeError));
var LastIndexIsNegativeError = /** @class */ (function (_super) {
    __extends(LastIndexIsNegativeError, _super);
    function LastIndexIsNegativeError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LastIndexIsNegativeError;
}(RangeError));
var FirstIndexIsGreaterThanLastIndexError = /** @class */ (function (_super) {
    __extends(FirstIndexIsGreaterThanLastIndexError, _super);
    function FirstIndexIsGreaterThanLastIndexError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FirstIndexIsGreaterThanLastIndexError;
}(RangeError));
var ViewSegmentIsEmptyError = /** @class */ (function (_super) {
    __extends(ViewSegmentIsEmptyError, _super);
    function ViewSegmentIsEmptyError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ViewSegmentIsEmptyError;
}(Error));
/**
 * @classdesc `start`から始まり、`pause`または`end`で終わるログ列を表すクラス。
 * @param logSequence a sequence of logs the video component has
 * @param last a last index of the view segment in the log sequence
 * @param log a log to be added to the log sequence
 * @param logs a log sequence representing a view segment
 * @param first a first index of the view segment in the log sequence
 * @throws {FirstIndexIsNegativeError} if `first` is negative
 * @throws {LastIndexIsNegativeError} if `last` is negative
 * @throws {FirstIndexIsGreaterThanLastIndexError} if `first` is greater than `last`
 * @throws {ViewSegmentIsEmptyError} if a view segment has no logs
 * */
var ViewSegment = /** @class */ (function () {
    function ViewSegment(logSequence, last, log, logs, first) {
        var _this = this;
        if (logs === void 0) { logs = []; }
        if (first === void 0) { first = 0; }
        this.logSequence = logSequence;
        this.last = last;
        this.log = log;
        this.logs = logs;
        this.first = first;
        /**
         *
         * @param logSequence a sequence of logs the video component has
         * @param minDurationMs a duration of a view segment must be longer than this value
         * @returns a boolean value indicating whether the view segment is valid
         */
        this.isValid = function (logSequence, minDurationMs) {
            if (logSequence.filter(function (log) { return log.event === "pause"; }).length < 2) {
                return (ViewSegment.constraints.assureDurationIsLongerThanMin(_this, minDurationMs) &&
                    ViewSegment.constraints.assureDurationDiffIsCloseToViewingTime(_this, 2000));
            }
            return (ViewSegment.constraints.assureDurationIsLongerThanMin(_this, minDurationMs) &&
                ViewSegment.constraints.assureIdentity(logSequence, _this) &&
                ViewSegment.constraints.assureDurationDiffIsCloseToViewingTime(_this, 2000));
        };
        this.send = function () { return __awaiter(_this, void 0, Promise, function () {
            var _i, _a, log, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        _i = 0, _a = this.logs;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        log = _a[_i];
                        return [4 /*yield*/, log.send()];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_1 = _b.sent();
                        if (e_1 instanceof log_1.LogWasNotSentError) {
                            console.error(e_1.message);
                        }
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        var unfilteredLogSequence = this.log
            ? this.logSequence.concat(this.log)
            : this.logSequence;
        try {
            this.first = ViewSegment.findStartIndex(unfilteredLogSequence);
        }
        catch (error) {
            if (error instanceof FirstIndexNotFoundError) {
                console.error(error);
            }
        }
        if (this.first < 0)
            throw new FirstIndexIsNegativeError("first index must be greater than 0");
        if (this.last < 0)
            throw new LastIndexIsNegativeError("last index must be greater than 0");
        if (this.first > this.last)
            throw new FirstIndexIsGreaterThanLastIndexError("first index must be less than last index");
        this.logs = unfilteredLogSequence.slice(this.first, this.last + 1);
        if (this.logs.length === 0)
            throw new ViewSegmentIsEmptyError("view segment must not be empty");
    }
    /**
     * @throws {FirstIndexNotFoundError} if the first index of the view segment is not found
     */
    ViewSegment.findStartIndex = function (logs) {
        var startIndex = logs.map(function (log) { return log.event; }).lastIndexOf("start");
        if (startIndex === -1) {
            throw new FirstIndexNotFoundError("ログ列にstartフラグが見つかりませんでした。");
        }
        return startIndex;
    };
    /** ログ断片が負うべき制約群。この制約群を満たさない場合、ログ断片は棄却される。 */
    ViewSegment.constraints = {
        /** 制約：ログ断片の視聴時間（範囲）は正である。 */
        assureDurationIsLongerThanMin: function (viewFragment, minDurationMs) {
            return viewFragment.logs[viewFragment.logs.length - 1].positionMs -
                viewFragment.logs[0].positionMs >
                minDurationMs;
        },
        /** 制約：ログ断片は重複しない。 */
        assureIdentity: function (logSequence, viewFragment) {
            var previousSegment = new ViewSegment(logSequence, viewFragment.first);
            return (JSON.stringify(viewFragment.logs.map(function (log) { return [log.event, log.positionMs]; })) !==
                JSON.stringify(previousSegment.logs.map(function (log) { return [log.event, log.positionMs]; })));
        },
        /** 制約：ログ断片の始終のdurationの差は，ログ断片の視聴時間に近似する。 */
        assureDurationDiffIsCloseToViewingTime: function (viewFragment, tolerance) {
            var durationDifference = viewFragment.logs[viewFragment.logs.length - 1].durationMs -
                viewFragment.logs[0].durationMs;
            var viewingTime = viewFragment.logs[viewFragment.logs.length - 1].positionMs -
                viewFragment.logs[0].positionMs;
            return Math.abs(durationDifference - viewingTime) < tolerance;
        },
    };
    return ViewSegment;
}());
exports.ViewSegment = ViewSegment;
