"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormContextProvider = exports.useUpdateForm = exports.useFormState = void 0;
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var use_form_1 = require("../hooks/use-form");
var FormContext = compat_1.createContext(false);
var useFormState = function () { return compat_1.useContext(FormContext); };
exports.useFormState = useFormState;
var UpdateFormContext = compat_1.createContext(function () {
    // no op
});
var useUpdateForm = function () {
    return compat_1.useContext(UpdateFormContext);
};
exports.useUpdateForm = useUpdateForm;
function FormContextProvider(_a) {
    var children = _a.children;
    var _b = use_form_1.useForm(), showForm = _b.showForm, setShowForm = _b.setShowForm;
    return (preact_1.h(FormContext.Provider, { value: showForm },
        preact_1.h(UpdateFormContext.Provider, { value: setShowForm }, children)));
}
exports.FormContextProvider = FormContextProvider;
