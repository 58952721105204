"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PDFLinkViewer = void 0;
var goober_1 = require("goober");
var preact_1 = require("preact");
var src_1 = require("../../../../../../../lib/util/src");
var detect_ie_user_1 = require("../../../../util/detect-ie-user");
var PDFLinkViewer = function (_a) {
    var content = _a.content;
    var isAndroid = function () {
        var userAgent = window.navigator.userAgent;
        if (/android/i.test(userAgent)) {
            return true;
        }
        return false;
    };
    if (detect_ie_user_1.detectIEUser() || isAndroid()) {
        return (preact_1.h("embed", { src: "https://drive.google.com/viewerng/viewer?embedded=true&url=" + (content === null || content === void 0 ? void 0 : content.url), key: content === null || content === void 0 ? void 0 : content.id, className: styles.pdfLinkViewer }));
    }
    return (
    //FYI: https://github.com/ojisan-toybox/universal-pdf-component
    preact_1.h("div", { style: {
            position: "relative",
            width: "100%",
            height: 0,
            paddingTop: "50%",
        } },
        preact_1.h("object", { key: content === null || content === void 0 ? void 0 : content.id, type: "application/pdf", data: src_1.isSupportedOntouch()
                ? "https://drive.google.com/viewerng/viewer?embedded=true&url=" + (content === null || content === void 0 ? void 0 : content.url)
                : (content === null || content === void 0 ? void 0 : content.url) + "#view=FitV&pagemode=none&toolbar=0", style: {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
            } },
            preact_1.h("embed", { src: src_1.isSupportedOntouch()
                    ? "https://drive.google.com/viewerng/viewer?embedded=true&url=" + (content === null || content === void 0 ? void 0 : content.url)
                    : (content === null || content === void 0 ? void 0 : content.url) + "#view=FitV&pagemode=none&toolbar=0", style: { height: "100%", width: "100%" } }))));
};
exports.PDFLinkViewer = PDFLinkViewer;
var styles = {
    pdfLinkViewer: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    min-height: 100%;\n    ", " {\n      height: 400px;\n    }\n  "], ["\n    width: 100%;\n    min-height: 100%;\n    ", " {\n      height: 400px;\n    }\n  "])), src_1.query("sm")),
};
var templateObject_1;
