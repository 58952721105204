"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePdfStateManagement = void 0;
var hooks_1 = require("preact/hooks");
var hearing_state_1 = require("../module/hearing-state");
var product_content_state_1 = require("../module/product-content-state");
var use_hearing_1 = require("./pages/use-hearing");
var use_show_product_1 = require("./pages/use-show-product");
var usePdfStateManagement = function () {
    var currentIdx = hearing_state_1.useHearingState().currentIdx;
    var productContentsState = product_content_state_1.useProductContentState().productContentsState;
    var _a = hooks_1.useState(1), pageNum = _a[0], setPageNum = _a[1];
    var _b = hooks_1.useState(1), currentPage = _b[0], setCurrentPage = _b[1];
    var _c = hooks_1.useState([
        { kaizen_files_bucket_id: "", kaizen_files_url: "" },
    ]), pdfImages = _c[0], setPdfImages = _c[1];
    var _d = hooks_1.useState({
        url: "",
        fileName: "",
    }), contentFile = _d[0], setContentFile = _d[1];
    var hearingState = use_hearing_1.useHearing().pageState;
    var showProductState = use_show_product_1.useShowProduct().pageState;
    var handleSetContentFile = function (pdfLink, fileName) {
        if (!pdfLink)
            return "";
        var url = new URL(pdfLink);
        var pathnameArr = url.pathname.split("/");
        var fileName_ = fileName || pathnameArr[pathnameArr.length - 1];
        var linkForDownload = url.href.replace(url.search, "");
        setContentFile({ url: linkForDownload, fileName: fileName_ });
    };
    hooks_1.useEffect(function () {
        var _a;
        if (showProductState.type === "loaded" &&
            showProductState.content.extractedImages) {
            setPageNum(showProductState.content.extractedImages.length);
            setPdfImages(showProductState.content.extractedImages);
            handleSetContentFile(showProductState.content.url || "", showProductState.content.title);
        }
        if (hearingState.type === "loaded" &&
            ((_a = hearingState.current.content) === null || _a === void 0 ? void 0 : _a.contentType) === "pdfs" &&
            hearingState.current.content.extractedImages) {
            var extracted_images_ = hearingState.current.content.extractedImages;
            setPageNum(extracted_images_.length);
            setPdfImages(extracted_images_);
            handleSetContentFile(hearingState.current.content.url, hearingState.title);
        }
    }, [showProductState, hearingState]);
    var goToNextPage = hooks_1.useCallback(function () {
        setCurrentPage(function (prv) {
            if (prv < pageNum) {
                return prv + 1;
            }
            return prv;
        });
    }, [pageNum]);
    var jumpTo = function (target) {
        if (target < 1) {
            setCurrentPage(1);
            return;
        }
        if (target > pageNum) {
            setCurrentPage(pageNum);
            return;
        }
        setCurrentPage(target);
    };
    // コンテンツが変更されたときの処理
    hooks_1.useEffect(function () {
        setCurrentPage(1);
    }, [currentIdx, productContentsState]);
    var backToPreviousPage = hooks_1.useCallback(function () {
        setCurrentPage(function (prv) {
            if (prv > 1) {
                return prv - 1;
            }
            return prv;
        });
    }, []);
    return {
        pageNum: pageNum,
        currentPage: currentPage,
        pdfImages: pdfImages,
        goToNextPage: goToNextPage,
        backToPreviousPage: backToPreviousPage,
        jumpTo: jumpTo,
        contentFile: contentFile,
    };
};
exports.usePdfStateManagement = usePdfStateManagement;
