"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastContextProvider = exports.useSetToastMessage = exports.useToast = void 0;
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var src_1 = require("../../../../lib/component/src");
var ToastContext = compat_1.createContext(undefined);
/**
 * email 情報を返す
 * @returns email の設定が不要な場合は undefined を返す。
 */
var useToast = function () { return compat_1.useContext(ToastContext); };
exports.useToast = useToast;
var SetToastMessage = compat_1.createContext(function () {
    // no op
});
/**
 * email の setter を返す
 * @returns email をセットする関数
 */
var useSetToastMessage = function () {
    return compat_1.useContext(SetToastMessage);
};
exports.useSetToastMessage = useSetToastMessage;
function ToastContextProvider(_a) {
    var children = _a.children;
    var _b = compat_1.useState(false), show = _b[0], setState = _b[1];
    var _c = compat_1.useState(undefined), message = _c[0], setMessage = _c[1];
    var setToast = compat_1.useCallback(function (message) {
        setState(true);
        setTimeout(function () {
            setState(false);
        }, 3000);
        setMessage(message);
    }, []);
    return (preact_1.h(ToastContext.Provider, { value: message },
        preact_1.h(SetToastMessage.Provider, { value: setToast },
            show && message && preact_1.h(src_1.Toast, { message: message }),
            children)));
}
exports.ToastContextProvider = ToastContextProvider;
