"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v1ChoiceSchema = void 0;
var zod_1 = require("zod");
var tag_1 = require("./tag");
exports.v1ChoiceSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content: zod_1.z.string(),
    feedback: zod_1.z.string(),
    question_id: zod_1.z.number(),
    can_delete: zod_1.z.boolean(),
    tags: zod_1.z.array(tag_1.tagSchema),
});
