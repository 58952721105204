"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoutingContextProvider = exports.useRoutingUpdate = exports.RoutingUpdateContext = exports.useRouting = void 0;
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var use_root_1 = require("../hooks/use-root");
var RoutingContext = compat_1.createContext("init");
var useRouting = function () { return compat_1.useContext(RoutingContext); };
exports.useRouting = useRouting;
exports.RoutingUpdateContext = compat_1.createContext(function () {
    // no op
});
var useRoutingUpdate = function () {
    return compat_1.useContext(exports.RoutingUpdateContext);
};
exports.useRoutingUpdate = useRoutingUpdate;
function RoutingContextProvider(_a) {
    var children = _a.children;
    var _b = use_root_1.useRoot(), page = _b.page, changeRoute = _b.changeRoute;
    return (preact_1.h(RoutingContext.Provider, { value: page },
        preact_1.h(exports.RoutingUpdateContext.Provider, { value: changeRoute }, children)));
}
exports.RoutingContextProvider = RoutingContextProvider;
