"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentList = void 0;
var goober_1 = require("goober");
var preact_1 = require("preact");
var content_card_1 = require("./content-card");
var ContentList = function (_a) {
    var contents = _a.contents, handleClickContent = _a.handleClickContent, showListTitle = _a.showListTitle, className = _a.className;
    var scrollToTop = function () {
        window.scrollTo(0, 0);
    };
    return (preact_1.h("div", { className: styles.wrapper + " " + className },
        !!showListTitle && (preact_1.h("h3", { className: styles.listTitle }, "\u4ED6\u306E\u30B3\u30F3\u30C6\u30F3\u30C4\u3092\u898B\u308B")),
        preact_1.h("div", { className: styles.listItem }, contents.map(function (content) {
            return (preact_1.h(content_card_1.ContentCard, { className: styles.listItem, key: content.id, contentType: content.contentType, thumbnailUrl: content.thumbnail || "", title: content.title, handleClick: function () {
                    handleClickContent(content.id);
                    scrollToTop();
                } }));
        }))));
};
exports.ContentList = ContentList;
var styles = {
    wrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""]))),
    listTitle: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    margin-bottom: 10px;\n    font-weight: bold;\n  "], ["\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    margin-bottom: 10px;\n    font-weight: bold;\n  "]))),
    listWrapper: goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""]))),
    listItem: goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-bottom: 10px;\n  "], ["\n    margin-bottom: 10px;\n  "]))),
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
