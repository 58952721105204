"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnswerContextProvider = exports.useUpdateAnswer = exports.UpdateAnswerContext = exports.useAnswerState = void 0;
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var use_answer_1 = require("../hooks/use-answer");
var AnswerContext = compat_1.createContext({
    currentUserAnswer: undefined,
    userAnswer: undefined,
});
var useAnswerState = function () { return compat_1.useContext(AnswerContext); };
exports.useAnswerState = useAnswerState;
exports.UpdateAnswerContext = compat_1.createContext(function () {
    // no op
});
var useUpdateAnswer = function () {
    return compat_1.useContext(exports.UpdateAnswerContext);
};
exports.useUpdateAnswer = useUpdateAnswer;
function AnswerContextProvider(_a) {
    var children = _a.children;
    var _b = use_answer_1.useAnswer(), answerQuestion = _b.answerQuestion, answerState = _b.answerState;
    return (preact_1.h(AnswerContext.Provider, { value: answerState },
        preact_1.h(exports.UpdateAnswerContext.Provider, { value: answerQuestion }, children)));
}
exports.AnswerContextProvider = AnswerContextProvider;
