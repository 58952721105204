"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPageForMobile = void 0;
var goober_1 = require("goober");
var preact_1 = require("preact");
var use_show_product_1 = require("../../hooks/pages/use-show-product");
var playlist_mode_1 = require("../../module/playlist-mode");
var preview_1 = require("../../module/preview");
var entry_form_1 = require("../../pages/entry-form");
var preview_card_1 = require("../common/preview-card");
var playlist_for_mobile_1 = require("./playlist-for-mobile");
var product_contents_for_mobile_1 = require("./product-contents-for-mobile");
var ProductPageForMobile = function () {
    var _a = use_show_product_1.useShowProduct(), pageState = _a.pageState, backToPreviousQuestion = _a.backToPreviousQuestion, handleClickNextButton = _a.handleClickNextButton, sending = _a.sending, handleChangeCurrentChapter = _a.handleChangeCurrentChapter, setVideoPlayer = _a.setVideoPlayer, handleClickContent = _a.handleClickContent, isContentDisplay = _a.isContentDisplay, handleBackToContentList = _a.handleBackToContentList;
    var _b = playlist_mode_1.usePlaylistModeState(), isPlaylistMode = _b.isPlaylistMode, playlist = _b.playlist;
    var isPlaylistMode_ = isPlaylistMode && playlist.contents.length > 0;
    var showPopup = preview_1.usePreviewState().showPopup;
    return (preact_1.h("div", null,
        showPopup ? preact_1.h(preview_card_1.PreviewCard, { isMobile: true }) : null,
        pageState.type === "loaded" ? (pageState.showForm ? (preact_1.h(entry_form_1.EntryForm, null)) : (preact_1.h(Wrapper, null, isPlaylistMode_ ? (preact_1.h(playlist_for_mobile_1.PlaylistForMobile, { playlist: playlist, pageState: pageState, handleClickContent: handleClickContent, sending: sending, handleChangeCurrentChapter: handleChangeCurrentChapter, setVideoPlayer: setVideoPlayer, isContentDisplay: isContentDisplay, handleBackToContentList: handleBackToContentList })) : (preact_1.h(product_contents_for_mobile_1.ProductContentsForMobile, { pageState: pageState, backToPreviousQuestion: backToPreviousQuestion, handleClickNextButton: handleClickNextButton, sending: sending, handleChangeCurrentChapter: handleChangeCurrentChapter, setVideoPlayer: setVideoPlayer }))))) : pageState.type === "error" ? (preact_1.h("div", null, pageState.message)) : (preact_1.h("div", null))));
};
exports.ProductPageForMobile = ProductPageForMobile;
var Wrapper = goober_1.styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"])));
var templateObject_1;
