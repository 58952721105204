"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogStateContextProvider = exports.useUpdateLogState = exports.UpdateLogStateContext = exports.useLogState = void 0;
var error_1 = require("@kaizen-dx-tools/error");
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var use_log_state_management_1 = require("../hooks/use-log-state-management");
var LogStateContext = compat_1.createContext(undefined);
var useLogState = function () {
    var state = compat_1.useContext(LogStateContext);
    if (state === undefined)
        throw error_1.Errors.UNEXPECTED_VALUE;
    return state;
};
exports.useLogState = useLogState;
exports.UpdateLogStateContext = compat_1.createContext(function () {
    // no op
});
var useUpdateLogState = function () {
    return compat_1.useContext(exports.UpdateLogStateContext);
};
exports.useUpdateLogState = useUpdateLogState;
function LogStateContextProvider(_a) {
    var children = _a.children;
    var state = use_log_state_management_1.useLogStateManagement();
    return (preact_1.h(LogStateContext.Provider, { value: state }, children));
}
exports.LogStateContextProvider = LogStateContextProvider;
