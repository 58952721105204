"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHearingStateManagement = void 0;
var hooks_1 = require("preact/hooks");
var useHearingStateManagement = function () {
    var _a = hooks_1.useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    var initState = hooks_1.useCallback(function (questions) {
        var questionIds = Array.from(questions.keys());
        dispatch(initialize({ questionIds: questionIds }));
    }, []);
    var goToNextQuestion = hooks_1.useCallback(function () {
        dispatch(nextContent());
    }, []);
    var backToPreviousQuestion = hooks_1.useCallback(function () {
        dispatch(previousContent());
    }, []);
    return {
        currentQuestionId: state.currentQuestionId,
        previousQuestionId: state.previousQuestionId,
        goToNextQuestion: goToNextQuestion,
        backToPreviousQuestion: backToPreviousQuestion,
        initState: initState,
        currentIdx: state.currentIdx,
        questionIds: state.questionIds,
        latestMove: state.latestMove,
    };
};
exports.useHearingStateManagement = useHearingStateManagement;
var initialState = {
    currentQuestionId: undefined,
    previousQuestionId: undefined,
    questionIds: undefined,
    currentIdx: undefined,
    latestMove: undefined,
};
var actionTypes = {
    INIT: "APP/INIT",
    NEXT_CONTENT: "APP/NEXT_CONTENT",
    BACK_CONTENT: "APP/BACK_CONTENT",
};
var initialize = function (data) {
    return { type: actionTypes.INIT, payload: data.questionIds };
};
var nextContent = function () {
    return { type: actionTypes.NEXT_CONTENT };
};
var previousContent = function () {
    return { type: actionTypes.BACK_CONTENT };
};
var reducer = function (state, action) {
    switch (action.type) {
        case actionTypes.INIT:
            return __assign(__assign({}, state), { currentQuestionId: action.payload[0], questionIds: action.payload, currentIdx: 0 });
        case actionTypes.NEXT_CONTENT: {
            if (state.currentQuestionId === undefined) {
                console.error("should init before click");
                return __assign({}, state);
            }
            var nextIdx = state.currentIdx + 1;
            return __assign(__assign({}, state), { currentIdx: nextIdx, currentQuestionId: state.questionIds[nextIdx], previousQuestionId: state.currentQuestionId, latestMove: "go" });
        }
        case actionTypes.BACK_CONTENT: {
            if (state.currentQuestionId === undefined) {
                console.error("should init before click");
                return __assign({}, state);
            }
            if (state.currentIdx === 0) {
                console.error("should not call backing");
                return __assign({}, state);
            }
            var prevIdx = state.currentIdx - 1;
            return __assign(__assign({}, state), { currentIdx: prevIdx, currentQuestionId: state.questionIds[prevIdx], previousQuestionId: state.currentQuestionId, latestMove: "back" });
        }
        default: {
            return state;
        }
    }
};
