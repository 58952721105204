"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviewCard = void 0;
var component_1 = require("@kaizen-dx-tools/component");
var const_1 = require("@kaizen-dx-tools/const");
var goober_1 = require("goober");
var preact_1 = require("preact");
var hooks_1 = require("preact/hooks");
var PreviewCard = function (_a) {
    var isMobile = _a.isMobile;
    var _b = hooks_1.useState(true), showPreviewNotice = _b[0], setShowPreviewNotice = _b[1];
    var handleClick = function () {
        setShowPreviewNotice(false);
    };
    if (!showPreviewNotice)
        return null;
    return (preact_1.h("div", { className: styles.wrapper + " " + (isMobile ? styles.mobilePosition : null) },
        preact_1.h("div", { className: styles.notice },
            preact_1.h("p", { className: styles.noticeSentence }, "\u3053\u308C\u306F\u30B3\u30F3\u30C6\u30F3\u30C4\u306E\u898B\u305F\u76EE\u3092\u78BA\u8A8D\u3059\u308B\u305F\u3081\u306E\u30D7\u30EC\u30D3\u30E5\u30FC\u30E2\u30FC\u30C9\u3067\u3059\u3002 \u8996\u8074\u6642\u9593\u306E\u8A08\u6E2C\u306F\u3055\u308C\u307E\u305B\u3093\u306E\u3067\u3001\u3053\u306EURL\u306F\u304A\u5BA2\u69D8\u3078\u5171\u6709\u3057\u306A\u3044\u3067\u304F\u3060\u3055\u3044\u3002")),
        preact_1.h(component_1.Button, { skin: "outlined", onClick: handleClick }, "OK")));
};
exports.PreviewCard = PreviewCard;
var styles = {
    wrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 400px;\n    max-width: 100vw;\n    position: fixed;\n    background: ", ";\n    color: ", ";\n    padding: 1rem;\n    text-align: center;\n    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 9px;\n    z-index: 999;\n    bottom: 10px;\n    right: 10px;\n    line-height: 140%;\n  "], ["\n    width: 400px;\n    max-width: 100vw;\n    position: fixed;\n    background: ", ";\n    color: ", ";\n    padding: 1rem;\n    text-align: center;\n    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 9px;\n    z-index: 999;\n    bottom: 10px;\n    right: 10px;\n    line-height: 140%;\n  "])), const_1.COLOR.greenPrimary, const_1.COLOR.white),
    mobilePosition: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100vw;\n    bottom: 0;\n    right: 0;\n  "], ["\n    width: 100vw;\n    bottom: 0;\n    right: 0;\n  "]))),
    notice: goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin: 0;\n    text-align: left;\n  "], ["\n    margin: 0;\n    text-align: left;\n  "]))),
    noticeSentence: goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin: 1rem 0;\n  "], ["\n    margin: 1rem 0;\n  "]))),
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
