"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v1QuestionSetSchema = void 0;
var zod_1 = require("zod");
var v1_content_1 = require("./v1-content");
var v1_question_1 = require("./v1-question");
exports.v1QuestionSetSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content: v1_content_1.v1ContentSchema,
    questions: zod_1.z.array(v1_question_1.v1QuestionSchema),
    order_index: zod_1.z.number(),
    pop_up_frame: zod_1.z.number().nullable().optional(),
    color_code: zod_1.z.string().nullable().optional(),
    choices_column_count: zod_1.z.number().nullable().optional(),
    border_radius: zod_1.z.number().nullable().optional(),
    is_skip_questions: zod_1.z.boolean().nullable().optional(),
    can_delete: zod_1.z.boolean(),
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
    service_resource_id_for_logger: zod_1.z.string(),
});
