"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MovieRenderer = void 0;
var preact_1 = require("preact");
var src_1 = require("preact-kaizen-video-player/src");
var src_2 = require("../../const/src");
var Component = function (props) {
    var _a;
    var videoId = props.videoId, manifestUrl = props.manifestUrl, loggingOption = props.loggingOption, shouldStop = props.shouldStop, config = props.config, poster = props.poster, plyrOptions = props.plyrOptions, outsideControls = props.outsideControls, autoHeight = props.autoHeight, maxHeight = props.maxHeight, markers = props.markers, muteButton = props.muteButton, listeners = props.listeners, autoplay = props.autoplay, customSpeedSetting = props.customSpeedSetting;
    var mobile = Boolean(window.navigator.userAgent.match(/iPhone|Android.+Mobile/));
    // デフォルトのplyrOptionsとマージ
    var mutedOverride = props.muted;
    var defaultPlyrOptions = {
        controls: ["play", "progress", "current-time", "mute", "volume"],
        muted: mutedOverride === undefined ? mobile : mutedOverride,
    };
    var mergedPlyrOptions = Object.assign({}, defaultPlyrOptions, plyrOptions || {});
    var muted = mergedPlyrOptions.muted, plyrOptionsWithoutMuted = __rest(mergedPlyrOptions, ["muted"]);
    var customListeners = listeners !== null && listeners !== void 0 ? listeners : [];
    customListeners.push([
        "volumechange",
        function (event) {
            localStorage.setItem("playerVolume", event.detail.plyr.volume);
        },
    ]);
    return (preact_1.h(src_1.KaizenPreactVideoPlayer, { videoType: "shaka", videoId: videoId, kzHearingToken: props.kzHearingToken, manifestUrl: manifestUrl, autoplay: autoplay !== null && autoplay !== void 0 ? autoplay : true, loggerEndpoint: loggingOption.loggerEndpoint, shouldPause: shouldStop !== undefined ? shouldStop : false, scriptKey: String(config.loggerScriptKey), 
        // https://github.com/kaizenplatform/sales-dx-hearing-tools/issues/129
        service: "sales", placement: loggingOption.placement, serviceResourceId: loggingOption.serviceResourceId, userId: loggingOption.userId, logging: loggingOption.logging, poster: poster, plyrOptions: plyrOptionsWithoutMuted, 
        // plyrOptions内のプロパティでmutedを渡しても適応されなかったので、こちらから渡している。
        muted: muted, plyrCss: "\n      .kaizen-player-root {\n        height:100%\n      }\n      .plyr__poster {\n        background-color:" + src_2.COLOR.black + ";\n      }\n      .plyr--video {\n        " + (outsideControls ? "" : "--plyr-video-background:transparent;") + "height:100% \n      }", outsideControls: outsideControls, autoHeight: autoHeight, maxHeight: maxHeight, markers: markers, muteButton: muteButton, listeners: customListeners, volume: Number((_a = localStorage.getItem("playerVolume")) !== null && _a !== void 0 ? _a : 0.5), customValue: loggingOption.customValue, customSpeedSetting: customSpeedSetting }));
};
exports.MovieRenderer = Component;
