"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HearingView = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var util_1 = require("@kaizen-dx-tools/util");
var goober_1 = require("goober");
var preact_1 = require("preact");
var styles = {
    title: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-size: 20px;\n    font-weight: bold;\n    color: ", ";\n    overflow: hidden;\n\n    ", " {\n      font-size: 16px;\n    }\n  "], ["\n    font-size: 20px;\n    font-weight: bold;\n    color: ", ";\n    overflow: hidden;\n\n    ", " {\n      font-size: 16px;\n    }\n  "])), const_1.COLOR.inputDefault, util_1.query("sm")),
    imageWrapper: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 48px;\n    height: 48px;\n    margin-right: 12px;\n    border-radius: 6px;\n    background: ", ";\n    overflow: hidden;\n  "], ["\n    width: 48px;\n    height: 48px;\n    margin-right: 12px;\n    border-radius: 6px;\n    background: ", ";\n    overflow: hidden;\n  "])), const_1.COLOR.black),
    image: goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  "], ["\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  "]))),
    question: goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-weight: bold;\n    font-size: 14px;\n    padding: 10px 0px;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    color: ", ";\n  "], ["\n    font-weight: bold;\n    font-size: 14px;\n    padding: 10px 0px;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    color: ", ";\n  "])), const_1.COLOR.default),
    item: goober_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-top: 32px;\n    margin-bottom: 20px;\n  "], ["\n    margin-top: 32px;\n    margin-bottom: 20px;\n  "]))),
    table: goober_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    border-collapse: collapse;\n    border-spacing: 0;\n    width: 100%;\n  "], ["\n    border-collapse: collapse;\n    border-spacing: 0;\n    width: 100%;\n  "]))),
    thead: goober_1.css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    background-color: ", ";\n  "], ["\n    background-color: ", ";\n  "])), const_1.COLOR.lightGray),
    th: goober_1.css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    width: 50%;\n    padding: 8px 16px;\n    font-size: 0.75rem;\n    color: ", ";\n    font-weight: bold;\n  "], ["\n    width: 50%;\n    padding: 8px 16px;\n    font-size: 0.75rem;\n    color: ", ";\n    font-weight: bold;\n  "])), const_1.COLOR.darkMedium),
    tbody: goober_1.css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    background-color: ", ";\n  "], ["\n    background-color: ", ";\n  "])), const_1.COLOR.grayMedium),
    td: goober_1.css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    font-size: 0.875rem;\n    padding: 10px 16px;\n    color: ", ";\n  "], ["\n    font-size: 0.875rem;\n    padding: 10px 16px;\n    color: ", ";\n  "])), const_1.COLOR.inputDefault),
};
var HearingView = function (props) {
    return (preact_1.h("div", null,
        preact_1.h("h3", { className: styles.title }, props.hearingSetTitle),
        props.data.map(function (d) { return (preact_1.h("div", { key: d.questionSetId, className: styles.item },
            preact_1.h("div", { className: styles.imageWrapper },
                preact_1.h("img", { src: d.thumbnail, className: styles.image })),
            d.questions.map(function (q) { return (preact_1.h(preact_1.Fragment, { key: q.id },
                preact_1.h("h4", { className: styles.question }, q.title),
                preact_1.h("table", { className: styles.table },
                    preact_1.h("thead", { className: styles.thead },
                        preact_1.h("th", { className: styles.th }, "\u56DE\u7B54"),
                        preact_1.h("th", { className: styles.th }, "\u56DE\u7B54\u306B\u5BFE\u3059\u308B\u30D5\u30A3\u30FC\u30C9\u30D0\u30C3\u30AF")),
                    preact_1.h("tbody", { className: styles.tbody }, q.answers.map(function (a) { return (preact_1.h("tr", { key: a.id },
                        preact_1.h("td", { className: styles.td }, a.content),
                        preact_1.h("td", { className: styles.td }, a.feedback))); }))))); }))); })));
};
exports.HearingView = HearingView;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
