"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
var goober_1 = require("goober");
var preact_1 = require("preact");
var create_button_style_1 = require("./util/create-button-style");
var Component = function (_a) {
    var children = _a.children, onClick = _a.onClick, className = _a.className, disabled = _a.disabled, type = _a.type, id = _a.id, title = _a.title;
    return (preact_1.h("button", { id: id, onClick: onClick, className: className, disabled: disabled, type: type, title: title }, children));
};
var StyledComponent = goober_1.styled(Component)(function (props) { return (__assign({ fontSize: "14px", fontWeight: "bold", height: "40px", cursor: (props.disabled ? "not-allowed " : "pointer") + " !important", textAlign: "center", borderRadius: "6px", border: "none", padding: "0 24px" }, create_button_style_1.createButtonStyle(props.skin || "default"))); });
var ContainerComponent = function (props) {
    var _a = props.skin, skin = _a === void 0 ? "default" : _a;
    var passedProps = __assign(__assign({}, props), { skin: skin });
    return preact_1.h(StyledComponent, __assign({}, __assign({}, passedProps)));
};
exports.Button = ContainerComponent;
