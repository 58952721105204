"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionCard = void 0;
var goober_1 = require("goober");
var preact_1 = require("preact");
var src_1 = require("../../../../../lib/const/src");
var SectionCard = function (_a) {
    var title = _a.title, titleClassName = _a.titleClassName, className = _a.className, children = _a.children;
    return (preact_1.h("div", { className: styles.wrapper + " " + className },
        preact_1.h("h3", { className: styles.title + " " + titleClassName }, title),
        children));
};
exports.SectionCard = SectionCard;
var styles = {
    wrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    border-radius: 4px;\n    box-shadow: 0px 2px 3px ", ";\n    padding: 16px 0px;\n  "], ["\n    background: ", ";\n    border-radius: 4px;\n    box-shadow: 0px 2px 3px ", ";\n    padding: 16px 0px;\n  "])), src_1.COLOR.white, src_1.COLOR.boxShadow),
    title: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    padding: 0px 24px;\n    border-left: solid 4px ", ";\n    color: ", ";\n    font-size: 16px;\n    font-weight: bold;\n  "], ["\n    padding: 0px 24px;\n    border-left: solid 4px ", ";\n    color: ", ";\n    font-size: 16px;\n    font-weight: bold;\n  "])), src_1.COLOR.normal, src_1.COLOR.darkMedium2),
};
var templateObject_1, templateObject_2;
