"use strict";
// TODO: eslint + TS friendly なコードに書き換える
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
var error_1 = require("@kaizen-dx-tools/error");
if (!Object.assign) {
    Object.defineProperty(Object, "assign", {
        enumerable: false,
        configurable: true,
        writable: true,
        // @ts-ignore
        value: function (target) {
            "use strict";
            if (target === undefined || target === null) {
                throw error_1.Errors.UNEXPECTED_VALUE;
            }
            var to = Object(target);
            for (var i = 1; i < arguments.length; i++) {
                var nextSource = arguments[i];
                if (nextSource === undefined || nextSource === null) {
                    continue;
                }
                nextSource = Object(nextSource);
                var keysArray = Object.keys(Object(nextSource));
                for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
                    var nextKey = keysArray[nextIndex];
                    var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
                    if (desc !== undefined && desc.enumerable) {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
            return to;
        },
    });
}
