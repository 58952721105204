"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Radios = void 0;
var component_1 = require("@kaizen-dx-tools/component");
var const_1 = require("@kaizen-dx-tools/const");
var preact_1 = require("preact");
var Radios = function (props) {
    return (preact_1.h(preact_1.Fragment, null,
        props.choices.map(function (c) { return (preact_1.h(component_1.RadioButtonItem, { key: c.id, id: c.id.toString(), content: c.content, name: "choice", onChange: function () { return props.handleCheck(c.id, "radio"); }, checked: props.answerState === undefined
                ? false
                : props.answerState.answerIds.includes(c.id) })); }),
        props.isNeedOther && (preact_1.h(component_1.RadioButtonItem, { key: "other", id: const_1.OTHER_KEY.toString(), content: "その他", name: "choice", onChange: function () { return props.handleCheck(const_1.OTHER_KEY, "radio"); }, checked: props.isSelectedOther }))));
};
exports.Radios = Radios;
