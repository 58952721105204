"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFullscreenMode = void 0;
var hooks_1 = require("preact/hooks");
var useFullscreenMode = function (elRef) {
    var _a = hooks_1.useState(false), isElementDisplayed = _a[0], setElementDisplayed = _a[1];
    var _b = hooks_1.useState(), el = _b[0], setEl = _b[1];
    var doc = window.document;
    hooks_1.useEffect(function () {
        setEl(elRef === null || elRef === void 0 ? void 0 : elRef.current);
    }, [elRef]);
    var toggleElement = hooks_1.useCallback(function () {
        if (isElementDisplayed) {
            setElementDisplayed(false);
            if (doc.fullscreenElement && doc.exitFullscreen) {
                doc.exitFullscreen();
            }
            else if (doc.webkitFullscreenElement && doc.webkitExitFullscreen) {
                doc.webkitExitFullscreen();
            }
            else if (doc.msFullscreenElement && doc.msExitFullscreen) {
                doc.msExitFullscreen();
            }
        }
        else {
            setElementDisplayed(true);
            if (el === null || el === void 0 ? void 0 : el.requestFullscreen) {
                el.requestFullscreen();
            }
            else if (el === null || el === void 0 ? void 0 : el.webkitRequestFullscreen) {
                el.webkitRequestFullscreen();
            }
            else if (el === null || el === void 0 ? void 0 : el.msRequestFullscreen) {
                el.msRequestFullscreen();
            }
        }
    }, [isElementDisplayed, setElementDisplayed, doc, el]);
    var fullscreenChange = hooks_1.useCallback(function () {
        if (!doc.fullscreenElement &&
            !doc.webkitFullscreenElement &&
            !doc.msFullscreenElement)
            setElementDisplayed(false);
    }, [setElementDisplayed, doc]);
    hooks_1.useEffect(function () {
        document.addEventListener("fullscreenchange", function (_e) { return fullscreenChange(); });
        document.addEventListener("webkitfullscreenchange", function (_e) {
            return fullscreenChange();
        });
        document.addEventListener("MSFullscreenChange", function (_e) { return fullscreenChange(); });
        return function () {
            document.removeEventListener("fullscreenchange", function (_e) {
                return fullscreenChange();
            });
            document.removeEventListener("webkitfullscreenchange", function (_e) {
                return fullscreenChange();
            });
            document.removeEventListener("MSFullscreenChange", function (_e) {
                return fullscreenChange();
            });
        };
    }, [fullscreenChange]);
    return { isElementDisplayed: isElementDisplayed, toggleElement: toggleElement };
};
exports.useFullscreenMode = useFullscreenMode;
