"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChapterList = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var goober_1 = require("goober");
var preact_1 = require("preact");
var format_duration_1 = require("../../util/format-duration");
var section_card_1 = require("./section-card");
var styles = {
    chapterList: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 0px 24px;\n    margin-bottom: 8px;\n  "], ["\n    padding: 0px 24px;\n    margin-bottom: 8px;\n  "]))),
    chapterItem: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 14px;\n    display: flex;\n    line-height: 1.5;\n    margin-top: 8px;\n  "], ["\n    font-size: 14px;\n    display: flex;\n    line-height: 1.5;\n    margin-top: 8px;\n  "]))),
    chapterPosition: goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin-right: 20px;\n  "], ["\n    margin-right: 20px;\n  "]))),
    chapterName: goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 100%;\n    text-decoration: underline;\n    color: ", ";\n    &:hover {\n      cursor: pointer;\n      color: ", ";\n    }\n  "], ["\n    width: 100%;\n    text-decoration: underline;\n    color: ", ";\n    &:hover {\n      cursor: pointer;\n      color: ", ";\n    }\n  "])), const_1.COLOR.linkBlue, const_1.COLOR.lightBlue),
};
var ChapterList = function (_a) {
    var chapters = _a.chapters, className = _a.className, handleClickChapter = _a.handleClickChapter;
    //display duration in format hh:mm:ss only when at least one chapter position >= 1 hour = 60*60*1000 = 3600000 miliseconds
    //assumed that chapters are sorted in ascending order, position of the last chapter in list is the biggest one
    var displayHour = chapters[chapters.length - 1].position >= 3600000;
    return (preact_1.h(section_card_1.SectionCard, { title: "動画目次", className: className },
        preact_1.h("ul", { className: styles.chapterList }, chapters.map(function (chapter, index) { return (preact_1.h("li", { className: styles.chapterItem, key: index },
            preact_1.h("p", { className: styles.chapterPosition }, format_duration_1.formatDuration(chapter.position, displayHour)),
            preact_1.h("p", { className: styles.chapterName, onClick: function () { return handleClickChapter(index); } }, chapter.name))); }))));
};
exports.ChapterList = ChapterList;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
