"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HearingRoot = void 0;
var goober_1 = require("goober");
var preact_1 = require("preact");
var hearing_1 = require("../component/hearing");
var show_product_1 = require("../component/show-product");
var use_hearing_root_page_1 = require("../hooks/pages/use-hearing-root-page");
var styles = {
    innerWrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: 1280px;\n    margin-left: auto;\n    margin-right: auto;\n  "], ["\n    max-width: 1280px;\n    margin-left: auto;\n    margin-right: auto;\n  "]))),
};
var HearingRoot = function () {
    var pageState = use_hearing_root_page_1.useHearingRootPage().pageState;
    return (preact_1.h("div", { className: styles.innerWrapper }, pageState.type === "hearing" ? (preact_1.h(hearing_1.Hearing, null)) : pageState.type === "showProduct" ? (preact_1.h(show_product_1.ShowProduct, null)) : (preact_1.h("div", null))));
};
exports.HearingRoot = HearingRoot;
var templateObject_1;
