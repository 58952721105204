"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHearing = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var repository_1 = require("@kaizen-dx-tools/repository");
var util_1 = require("@kaizen-dx-tools/util");
var hooks_1 = require("preact/hooks");
var answer_1 = require("../../module/answer");
var form_1 = require("../../module/form");
var form_submit_status_1 = require("../../module/form-submit-status");
var hearing_state_1 = require("../../module/hearing-state");
var log_state_1 = require("../../module/log-state");
var preview_1 = require("../../module/preview");
var routing_1 = require("../../module/routing");
var toast_1 = require("../../module/toast");
var user_config_1 = require("../../module/user-config");
var getVideoStartTimeFromParams_1 = require("../../util/getVideoStartTimeFromParams");
var reportError_1 = require("../../utils/reportError");
var use_convert_data_model_1 = require("../use-convert-data-model");
var useHearing = function () {
    var _a = hooks_1.useState({ type: "initial" }), pageState = _a[0], setPageState = _a[1];
    var _b = hooks_1.useState(false), sending = _b[0], setSendingState = _b[1];
    var _c = hooks_1.useState(undefined), currentUserAnswerState = _c[0], setCurrentUserAnswer = _c[1];
    var _d = hooks_1.useState(false), shouldSend = _d[0], setShouldSend = _d[1];
    var _e = hooks_1.useState(), videoPlayer = _e[0], setVideoPlayer = _e[1];
    var _f = hooks_1.useState({}), metadata = _f[0], setMetadata = _f[1];
    var shouldShowForm = hooks_1.useState(false)[0];
    var userAnswers = answer_1.useAnswerState();
    var state = use_convert_data_model_1.useConvertDataModel();
    var _g = hearing_state_1.useHearingState(), initState = _g.initState, currentQuestionId = _g.currentQuestionId, goToNextQuestion = _g.goToNextQuestion, backToPreviousQuestion = _g.backToPreviousQuestion, currentIdx = _g.currentIdx, questionIds = _g.questionIds, previousQuestionId = _g.previousQuestionId, latestMove = _g.latestMove;
    var _h = log_state_1.useLogState(), logSequence = _h.logSequence, initLog = _h.initLog, pushLog = _h.pushLog;
    var answer = answer_1.useUpdateAnswer();
    var updateRoute = routing_1.useRoutingUpdate();
    var userConfig = user_config_1.useUserConfig();
    var isFormSubmitted = form_submit_status_1.useFormSubmitState();
    var showToast = toast_1.useSetToastMessage();
    var setShowForm = form_1.useUpdateForm();
    var showForm = form_1.useFormState();
    var shouldSendLog = preview_1.usePreviewState().shouldSendLog;
    hooks_1.useEffect(function () {
        var videoStartTime = pageState.type === "loaded" &&
            pageState.current.content &&
            pageState.current.content.contentType === "videos" &&
            pageState.current.content.isFirstVideo
            ? getVideoStartTimeFromParams_1.getVideoStartTimeFromParams((videoPlayer === null || videoPlayer === void 0 ? void 0 : videoPlayer.duration) || 0)
            : undefined;
        if (!videoPlayer || !videoStartTime)
            return;
        videoPlayer.currentTime = videoStartTime ? videoStartTime / 1000 : 0;
        if (!videoPlayer.playing)
            videoPlayer.play();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoPlayer]);
    //チャプターを押す時の処理
    var handleChangeCurrentChapter = function (nextIndex) {
        var _a;
        if (pageState.type !== "loaded" ||
            ((_a = pageState.current.content) === null || _a === void 0 ? void 0 : _a.contentType) !== "videos" ||
            !videoPlayer)
            return;
        videoPlayer.pause();
        var targetTime = pageState.current.content.chapters[nextIndex].position || 0;
        videoPlayer.currentTime = targetTime / 1000;
        if (!videoPlayer.playing)
            videoPlayer.play();
    };
    // 回答IDの切り替わりの監視
    hooks_1.useEffect(function () {
        var _a;
        if (pageState.type === "initial")
            return;
        if (currentQuestionId === undefined || pageState.type !== "loaded") {
            if (pageState.type === "error")
                return;
            setPageState({ type: "error", message: "質問が読み込まれていません" });
            return;
        }
        // バックなどですでに回答を持っているかの確認
        var answer = (_a = userAnswers.userAnswer) === null || _a === void 0 ? void 0 : _a.get(currentQuestionId);
        if (answer === undefined) {
            setCurrentUserAnswer(undefined);
        }
        else if (answer.otherAnswer === undefined) {
            setCurrentUserAnswer({
                type: "withoutOther",
                answerIds: answer.answerIds,
                otherText: undefined,
            });
        }
        else {
            setCurrentUserAnswer({
                type: "includeOther",
                answerIds: answer.answerIds,
                otherText: answer.otherAnswer,
            });
        }
    }, [currentQuestionId, userAnswers.userAnswer, pageState]);
    // Formを表示するフラグの監視
    hooks_1.useEffect(function () {
        if (state.type === "hearing" &&
            state.config.needsForm &&
            !isFormSubmitted) {
            setShowForm(true);
        }
    }, [setShowForm, state, isFormSubmitted]);
    // 回答送信フラグの監視
    hooks_1.useEffect(function () {
        if (shouldSend && state.type === "hearing") {
            if (userAnswers.userAnswer === undefined) {
                setPageState({ type: "error", message: "回答してください" });
                return;
            }
            if (state.config.needsFeedBack) {
                updateRoute("confirm");
            }
            else if (!shouldSendLog) {
                updateRoute("thankyou");
            }
            else {
                setSendingState(true);
                repository_1.v1Repository
                    .createResult(userConfig.hashToken, {
                    sessionId: userConfig.sessionId,
                    memo: "",
                    questions: userAnswers.userAnswer,
                })
                    .then(function () {
                    updateRoute("thankyou");
                })
                    .catch(function (e) {
                    console.error(e);
                    reportError_1.reportError("送信に失敗しました。");
                    showToast("送信に失敗しました。");
                })
                    .finally(function () {
                    setSendingState(false);
                });
            }
        }
    }, [
        shouldSend,
        userAnswers,
        state,
        updateRoute,
        userConfig.sessionId,
        userConfig.hashToken,
        showToast,
        isFormSubmitted,
        shouldSendLog,
    ]);
    // hearing mode の初期化
    hooks_1.useEffect(function () {
        if (state.type === "init")
            return;
        if (state.type !== "hearing") {
            setPageState({
                type: "error",
                message: "予期せぬ状態が読み込まれました。",
            });
        }
        else {
            // すでに questionId があるとき(=confirm から戻ってきたとき)はinitは不要
            if (currentQuestionId !== undefined) {
                return;
            }
            initState(state.data.questions);
        }
    }, [initState, state, currentQuestionId]);
    // currentIdx が動いた時に呼ばれる処理
    hooks_1.useEffect(function () {
        if (state.type === "init")
            return;
        if (state.type !== "hearing") {
            setPageState({
                type: "error",
                message: "予期せぬ状態が読み込まれました。",
            });
            return;
        }
        if (currentQuestionId !== undefined &&
            questionIds !== undefined &&
            currentIdx !== undefined) {
            var currentQuestion = state.data.questions.get(currentQuestionId);
            var currentChoices = state.data.choices.get(currentQuestionId);
            if (currentQuestion === undefined || currentChoices === undefined) {
                setPageState({
                    type: "error",
                    message: "予期せぬ状態が読み込まれました。",
                });
                return;
            }
            var currentContent = state.data.contents.get(currentQuestion.questionSetId);
            var cursorPosition = util_1.calcCursorPosition(currentIdx, questionIds.length);
            var previousQuestion = previousQuestionId !== undefined
                ? state.data.questions.get(previousQuestionId)
                : undefined;
            var previousQuestionSetId = previousQuestion === null || previousQuestion === void 0 ? void 0 : previousQuestion.questionSetId;
            var currentQuestionSetId = currentQuestion.questionSetId;
            var animationState = "none";
            if (previousQuestionSetId === currentQuestionSetId) {
                if (latestMove === "back") {
                    animationState = "toRight";
                }
                else if (latestMove === "go") {
                    animationState = "toLeft";
                }
            }
            var endpoint = void 0;
            try {
                endpoint = util_1.getLoggerEndpoint();
            }
            catch (_a) {
                console.error("fail to load env");
                reportError_1.reportError("fail to load env");
                setPageState({
                    type: "error",
                    message: "データの取得に失敗しました。",
                });
                return;
            }
            setPageState({
                type: "loaded",
                current: {
                    question: currentQuestion,
                    choices: currentChoices,
                    content: currentContent,
                },
                cursorPosition: {
                    allLength: questionIds.length,
                    currentIdx: currentIdx + const_1.INCREMENT_FOR_VIEW,
                    position: cursorPosition,
                },
                animation: animationState,
                config: { loggerScriptKey: state.config.loggerScriptKey },
                hearingSetId: state.data.hearingSet.hearingSetId,
                loggerEndpoint: endpoint,
                serviceResourceIdForLogger: state.config.serviceResourceIdForLogger,
                hashToken: userConfig.hashToken,
                linkType: state.config.linkType,
                showForm: showForm,
                setShowForm: setShowForm,
                title: state.data.hearingSet.title,
            });
        }
    }, [
        currentQuestionId,
        state,
        currentIdx,
        questionIds,
        latestMove,
        previousQuestionId,
        userConfig.hashToken,
        shouldShowForm,
        showForm,
        setShowForm,
    ]);
    var showOtherTextArea = hooks_1.useMemo(function () {
        if (currentUserAnswerState === undefined)
            return false;
        if (currentUserAnswerState.type === "includeOther")
            return true;
        return false;
    }, [currentUserAnswerState]);
    var answerState = hooks_1.useMemo(function () {
        if (currentUserAnswerState === undefined)
            return undefined;
        return {
            answerIds: currentUserAnswerState.answerIds,
            otherAnswer: currentUserAnswerState.otherText,
        };
    }, [currentUserAnswerState]);
    var canGoNext = hooks_1.useMemo(function () {
        if (currentUserAnswerState === undefined)
            return false;
        if (currentUserAnswerState.type === "includeOther") {
            if (currentUserAnswerState.otherText === undefined ||
                currentUserAnswerState.otherText === "")
                return false;
            return true;
        }
        else if (currentUserAnswerState.type === "withoutOther") {
            if (currentUserAnswerState.answerIds.length === 0)
                return false;
            return true;
        }
        util_1.assertNever(currentUserAnswerState.type);
        return false;
    }, [currentUserAnswerState]);
    /**
     * 回答インプットの操作ハンドラ
     * @param answerId
     * @param type
     * @returns
     */
    var handleCheckAnswer = function (answerId, type) {
        if (pageState.type !== "loaded") {
            setPageState({ type: "error", message: "回答前に初期化が必要です。" });
            return;
        }
        // 初期フォームから初入力したとき
        if (currentUserAnswerState === undefined) {
            if (answerId === const_1.OTHER_KEY) {
                setCurrentUserAnswer({
                    type: "includeOther",
                    answerIds: [],
                    otherText: undefined,
                });
            }
            else {
                setCurrentUserAnswer({
                    type: "withoutOther",
                    answerIds: [answerId],
                    otherText: undefined,
                });
            }
            return;
        }
        // その他が選択されたとき
        if (answerId === const_1.OTHER_KEY) {
            // その他入力フォームが表示されていない場合
            if (showOtherTextArea === false) {
                // radio の場合は既存入力をリセット
                if (type === "radio") {
                    setCurrentUserAnswer({
                        type: "includeOther",
                        answerIds: [],
                        otherText: undefined,
                    });
                }
                else {
                    setCurrentUserAnswer(__assign(__assign({}, currentUserAnswerState), { type: "includeOther", otherText: undefined }));
                }
            }
            else {
                // その他入力フォームが表示されている場合 = その他の解除
                setCurrentUserAnswer(__assign(__assign({}, currentUserAnswerState), { type: "withoutOther" }));
            }
        }
        else {
            if (type === "radio") {
                setCurrentUserAnswer(__assign(__assign({}, currentUserAnswerState), { type: "withoutOther", answerIds: [answerId] }));
            }
            else if (type === "check") {
                if (currentUserAnswerState.answerIds.includes(answerId)) {
                    setCurrentUserAnswer(__assign(__assign({}, currentUserAnswerState), { answerIds: currentUserAnswerState.answerIds.filter(function (id) { return id !== answerId; }) }));
                }
                else {
                    setCurrentUserAnswer(__assign(__assign({}, currentUserAnswerState), { answerIds: __spreadArray(__spreadArray([], currentUserAnswerState.answerIds), [answerId]) }));
                }
            }
            else {
                util_1.assertNever(type);
            }
            return;
        }
    };
    /**
     * その他フォームの入力ハンドラ
     * @param text
     * @returns
     */
    var handleInputOtherText = function (text) {
        if (currentUserAnswerState === undefined) {
            setCurrentUserAnswer({
                type: "includeOther",
                answerIds: [],
                otherText: text,
            });
            return;
        }
        setCurrentUserAnswer(__assign(__assign({}, currentUserAnswerState), { type: "includeOther", otherText: text }));
    };
    // 戻るボタンのクリックハンドラ
    var handleClickBackButton = function () {
        if (pageState.type !== "loaded") {
            setPageState({ type: "error", message: "回答前に初期化が必要です。" });
            return;
        }
        if (currentQuestionId === undefined) {
            setPageState({ type: "error", message: "質問が選択されていません" });
            return;
        }
        if (answerState === undefined) {
            backToPreviousQuestion();
            return;
        }
        answer(currentQuestionId, answerState);
        backToPreviousQuestion();
    };
    // 次へボタンのクリックハンドラ
    var handleClickNextButton = function () {
        if (pageState.type !== "loaded") {
            setPageState({ type: "error", message: "回答前に初期化が必要です。" });
            return;
        }
        if (state.type !== "hearing" ||
            questionIds === undefined ||
            currentQuestionId === undefined ||
            currentIdx === undefined) {
            setPageState({
                type: "error",
                message: "質問が選択されていないか、ヒアリングモードではありません",
            });
            return;
        }
        if (answerState === undefined) {
            setPageState({
                type: "error",
                message: "遷移する前に回答してください。",
            });
            return;
        }
        var cursorPosition = util_1.calcCursorPosition(currentIdx, questionIds.length);
        if (cursorPosition === "last") {
            answer(currentQuestionId, answerState);
            setShouldSend(true);
        }
        else {
            answer(currentQuestionId, answerState);
            goToNextQuestion();
        }
    };
    return {
        pageState: pageState,
        handleClickNextButton: handleClickNextButton,
        handleClickBackButton: handleClickBackButton,
        handleCheckAnswer: handleCheckAnswer,
        handleInputOtherText: handleInputOtherText,
        answerState: answerState,
        showOtherTextArea: showOtherTextArea,
        canGoNext: canGoNext,
        sending: sending,
        handleChangeCurrentChapter: handleChangeCurrentChapter,
        setVideoPlayer: setVideoPlayer,
        logSequence: logSequence,
        initLog: initLog,
        pushLog: pushLog,
        setMetadata: setMetadata,
    };
};
exports.useHearing = useHearing;
