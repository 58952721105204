"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductContentView = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var goober_1 = require("goober");
var preact_1 = require("preact");
var styles = {
    thumbnailWrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 48px;\n    height: 48px;\n    border-radius: 6px;\n    background: ", ";\n    overflow: hidden;\n  "], ["\n    width: 48px;\n    height: 48px;\n    border-radius: 6px;\n    background: ", ";\n    overflow: hidden;\n  "])), const_1.COLOR.black),
    movieIcon: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  "], ["\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  "]))),
    info: goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin-left: 8px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    overflow: hidden;\n    font-size: 14px;\n  "], ["\n    margin-left: 8px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    overflow: hidden;\n    font-size: 14px;\n  "]))),
    watchedInfo: goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    margin: 20px 0px;\n  "], ["\n    display: flex;\n    margin: 20px 0px;\n  "]))),
    date: goober_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), const_1.COLOR.darkGray),
    contentTitle: goober_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: ", ";\n    font-weight: bold;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  "], ["\n    color: ", ";\n    font-weight: bold;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  "])), const_1.COLOR.inputDefault),
};
var ProductContentView = function (props) {
    return (preact_1.h("div", null,
        preact_1.h("p", null, "\u8996\u8074\u3057\u305F\u30B3\u30F3\u30C6\u30F3\u30C4"),
        props.data.map(function (d) { return (preact_1.h("div", { key: d.id, className: styles.watchedInfo },
            preact_1.h("div", { className: styles.thumbnailWrapper },
                preact_1.h("img", { src: d.thumbnail, className: styles.movieIcon })),
            preact_1.h("div", { className: styles.info },
                preact_1.h("span", { className: styles.contentTitle }, d.title),
                preact_1.h("span", { className: styles.date },
                    "\u66F4\u65B0\u65E5: ",
                    d.updatedAt.getFullYear(),
                    "/",
                    d.updatedAt.getMonth() + 1,
                    "/",
                    d.updatedAt.getDate())))); })));
};
exports.ProductContentView = ProductContentView;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
