"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reciever = void 0;
// セキュリティ的に大丈夫なのか懸念している
// TODO: testかく
/**
 * json parseした際にfunction keyが削除されないようにします
 * @param  {any} this
 * @param  {string} key
 * @param  {any} value
 */
function reciever(k, v) {
    if (typeof v === "string" && v.match(/^function/)) {
        return Function.call(this, "return " + v)();
    }
    return v;
}
exports.reciever = reciever;
