"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.qsParse = void 0;
var qsParse = function (queryString) {
    var qs = new URLSearchParams(queryString);
    var keys = Array.from(qs.keys());
    var qsObject = {};
    keys.forEach(function (key) {
        var value = qs.get(key);
        qsObject[key] = value;
    });
    return qsObject;
};
exports.qsParse = qsParse;
