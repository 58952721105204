"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v1ProductContentPdfSchema = exports.v1ProductImageSchema = exports.v1ProductContentSchema = void 0;
var zod_1 = require("zod");
var chapter_1 = require("./chapter");
var extracted_images_1 = require("./extracted-images");
var tag_1 = require("./tag");
exports.v1ProductContentSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content_id: zod_1.z.number(),
    title: zod_1.z.union([zod_1.z.string(), zod_1.z.null()]),
    status: zod_1.z.union([
        zod_1.z.literal("draft"),
        zod_1.z.literal("published"),
        zod_1.z.literal("archived"),
    ]),
    public_description: zod_1.z.string().nullable().optional(),
    organization_id: zod_1.z.number(),
    content_type: zod_1.z.literal("videos"),
    upload_status: zod_1.z.union([
        zod_1.z.literal("not_uploaded"),
        zod_1.z.literal("uploaded"),
        zod_1.z.literal("upload_failed"),
    ]),
    kaizen_files_bucket_id: zod_1.z.string().nullable(),
    kaizen_files_id: zod_1.z.string(),
    kaizen_files_url: zod_1.z.string().nullable(),
    kaizen_files_thumbnail_url: zod_1.z.string().nullable(),
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
    tags: zod_1.z.array(tag_1.tagSchema),
    service_resource_id_for_logger: zod_1.z.string(),
    extracted_images: zod_1.z.union([zod_1.z.null(), zod_1.z.undefined()]),
    chapters: zod_1.z.array(chapter_1.chapterSchema).nullable().optional(),
});
exports.v1ProductImageSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content_id: zod_1.z.number(),
    title: zod_1.z.union([zod_1.z.string(), zod_1.z.null()]),
    status: zod_1.z.union([
        zod_1.z.literal("draft"),
        zod_1.z.literal("published"),
        zod_1.z.literal("archived"),
    ]),
    public_description: zod_1.z.string().nullable().optional(),
    organization_id: zod_1.z.number(),
    content_type: zod_1.z.literal("images"),
    upload_status: zod_1.z.union([
        zod_1.z.literal("not_uploaded"),
        zod_1.z.literal("uploaded"),
        zod_1.z.literal("upload_failed"),
    ]),
    // content_type: "images"だとkaizen_files_idとkaizen_files_thumbnail_urlが存在しない
    kaizen_files_bucket_id: zod_1.z.string().nullable(),
    kaizen_files_url: zod_1.z.string().nullable(),
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
    tags: zod_1.z.array(tag_1.tagSchema),
    service_resource_id_for_logger: zod_1.z.string(),
    extracted_images: zod_1.z.union([zod_1.z.null(), zod_1.z.undefined()]),
});
exports.v1ProductContentPdfSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content_id: zod_1.z.number(),
    title: zod_1.z.union([zod_1.z.string(), zod_1.z.null()]),
    status: zod_1.z.union([
        zod_1.z.literal("draft"),
        zod_1.z.literal("published"),
        zod_1.z.literal("archived"),
    ]),
    public_description: zod_1.z.string().nullable().optional(),
    organization_id: zod_1.z.number(),
    content_type: zod_1.z.literal("pdfs"),
    upload_status: zod_1.z.union([
        zod_1.z.literal("not_uploaded"),
        zod_1.z.literal("uploaded"),
        zod_1.z.literal("upload_failed"),
    ]),
    kaizen_files_bucket_id: zod_1.z.string().nullable(),
    kaizen_files_url: zod_1.z.string(),
    kaizen_files_thumbnail_url: zod_1.z.string().nullable(),
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
    tags: zod_1.z.array(tag_1.tagSchema),
    service_resource_id_for_logger: zod_1.z.string(),
    extracted_images: zod_1.z.union([zod_1.z.null(), zod_1.z.undefined(), extracted_images_1.extractedImages]),
});
