"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPageForPC = void 0;
var component_1 = require("@kaizen-dx-tools/component");
var goober_1 = require("goober");
var preact_1 = require("preact");
var src_1 = require("../../../../../lib/util/src");
var use_show_product_1 = require("../../hooks/pages/use-show-product");
var playlist_mode_1 = require("../../module/playlist-mode");
var preview_1 = require("../../module/preview");
var user_config_1 = require("../../module/user-config");
var entry_form_1 = require("../../pages/entry-form");
var detect_ie_user_1 = require("../../util/detect-ie-user");
var chapter_list_1 = require("../common/chapter-list");
var content_renderer_1 = require("../common/content-renderer");
var preview_card_1 = require("../common/preview-card");
var content_info_1 = require("./content-info");
var playlist_panel_1 = require("./playlist-panel");
var styles = {
    wrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    padding: 36px 20px 20px 20px;\n  "], ["\n    width: 100%;\n    padding: 36px 20px 20px 20px;\n  "]))),
    innerWrapper: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: grid;\n    grid-template-columns: 100% 0px;\n  "], ["\n    display: grid;\n    grid-template-columns: 100% 0px;\n  "]))),
    innerWrapperWithPlaylist: goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: grid;\n    grid-template-columns: 4fr 1fr;\n    grid-gap: 40px;\n    ", " {\n      grid-template-columns: 3fr 1fr;\n      grid-gap: 24px;\n    }\n  "], ["\n    display: grid;\n    grid-template-columns: 4fr 1fr;\n    grid-gap: 40px;\n    ", " {\n      grid-template-columns: 3fr 1fr;\n      grid-gap: 24px;\n    }\n  "])), src_1.query("md")),
    innerWrapperForIE: goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: -ms-grid;\n    -ms-grid-columns: 100% 0px;\n  "], ["\n    display: -ms-grid;\n    -ms-grid-columns: 100% 0px;\n  "]))),
    innerWrapperWithPlaylistForIE: goober_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: -ms-grid;\n    -ms-grid-columns: 4fr 1fr;\n    ", " {\n      -ms-grid-columns: 3fr 1fr;\n    }\n  "], ["\n    display: -ms-grid;\n    -ms-grid-columns: 4fr 1fr;\n    ", " {\n      -ms-grid-columns: 3fr 1fr;\n    }\n  "])), src_1.query("md")),
    root: goober_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    width: 100%;\n    max-width: 1280px;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n  "], ["\n    width: 100%;\n    max-width: 1280px;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n  "]))),
    rootForIE: goober_1.css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    width: 100%;\n    max-width: 1280px;\n    height: 100%;\n    -ms-grid-column: 1;\n    -ms-grid-row: 1;\n  "], ["\n    width: 100%;\n    max-width: 1280px;\n    height: 100%;\n    -ms-grid-column: 1;\n    -ms-grid-row: 1;\n  "]))),
    contentWrapper: goober_1.css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "]))),
    content: goober_1.css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "], ["\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "]))),
    contentInfo: goober_1.css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    margin-top: 20px;\n  "], ["\n    margin-top: 20px;\n  "]))),
    chapterList: goober_1.css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    margin-top: 20px;\n  "], ["\n    margin-top: 20px;\n  "]))),
    visiblePlaylistButtonWrapper: goober_1.css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    width: 20%;\n  "], ["\n    width: 20%;\n  "]))),
    nonVisiblePlaylistButtonWrapper: goober_1.css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    display: none;\n    width: 0%;\n  "], ["\n    display: none;\n    width: 0%;\n  "]))),
    buttonWrapper: goober_1.css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    display: flex;\n    margin-left: 20px;\n    margin-bottom: 20px;\n  "], ["\n    display: flex;\n    margin-left: 20px;\n    margin-bottom: 20px;\n  "]))),
    button: goober_1.css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n  "], ["\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n  "]))),
    buttonRowWrapper: goober_1.css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    margin-top: 20px;\n  "], ["\n    margin-top: 20px;\n  "]))),
    openNewIcon: goober_1.css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    margin-left: 10px;\n  "], ["\n    margin-left: 10px;\n  "]))),
};
var DefaultFinishButtonMessage = "視聴終了";
var ProductPageForPC = function () {
    var _a = use_show_product_1.useShowProduct(), pageState = _a.pageState, backToPreviousQuestion = _a.backToPreviousQuestion, handleClickNextButton = _a.handleClickNextButton, handleClickContent = _a.handleClickContent, sending = _a.sending, handleChangeCurrentChapter = _a.handleChangeCurrentChapter, setVideoPlayer = _a.setVideoPlayer;
    var _b = preview_1.usePreviewState(), showPopup = _b.showPopup, shouldSendLog = _b.shouldSendLog;
    var _c = playlist_mode_1.usePlaylistModeState(), isPlaylistMode = _c.isPlaylistMode, playlist = _c.playlist;
    var isPlaylistMode_ = isPlaylistMode && playlist.contents.length > 0;
    var isIE = detect_ie_user_1.detectIEUser();
    var innerWrapperClassName = (function () {
        if (isIE) {
            return isPlaylistMode_
                ? styles.innerWrapperWithPlaylistForIE
                : styles.innerWrapperForIE;
        }
        return isPlaylistMode_
            ? styles.innerWrapperWithPlaylist
            : styles.innerWrapper;
    })();
    var _d = user_config_1.useUserConfig(), hashToken = _d.hashToken, sessionId = _d.sessionId;
    return (preact_1.h("div", null,
        showPopup ? preact_1.h(preview_card_1.PreviewCard, null) : null,
        pageState.type === "loaded" ? (pageState.showForm ? (preact_1.h(entry_form_1.EntryForm, null)) : (preact_1.h("div", { className: styles.wrapper, key: pageState.content.id },
            preact_1.h("div", { className: innerWrapperClassName },
                preact_1.h("div", { className: "" + (isIE ? styles.rootForIE : styles.root) },
                    preact_1.h("div", { className: styles.contentWrapper },
                        preact_1.h(content_renderer_1.ContentRenderer, { content: pageState.content, config: pageState.config, className: styles.content, loggingOption: {
                                loggerEndpoint: pageState.loggerEndpoint,
                                userId: undefined,
                                placement: pageState.linkType === "hearing"
                                    ? "hearing_link"
                                    : "none",
                                serviceResourceId: pageState.serviceResourceIdForLogger + ":" + pageState.content.serviceResourceIdForLogger,
                                logging: !!shouldSendLog,
                                customValue: { sales_session_id: sessionId },
                            }, setVideoPlayer: setVideoPlayer, isPlaylistMode: isPlaylistMode_ })),
                    preact_1.h(content_info_1.ContentInfo, { title: pageState.content.title, description: pageState.content.public_description || "", className: styles.contentInfo, isIE: isIE }),
                    pageState.content.contentType === "videos" &&
                        pageState.content.chapters &&
                        pageState.content.chapters.length > 0 && (preact_1.h(chapter_list_1.ChapterList, { className: styles.chapterList, chapters: pageState.content.chapters, handleClickChapter: function (chapterIndex) {
                            return handleChangeCurrentChapter(chapterIndex);
                        } }))),
                preact_1.h(playlist_panel_1.PlaylistPanel, { isPlaylistMode: isPlaylistMode_, playlist: playlist, pageState: pageState, sending: sending, handleClickPlaylist: handleClickContent, isIE: isIE })),
            !isPlaylistMode_ && (preact_1.h("div", { className: styles.buttonRowWrapper },
                preact_1.h(component_1.ButtonRow, { onClickNext: handleClickNextButton, onClickBack: backToPreviousQuestion, canGoNext: true, cursor: pageState.cursorPositon, sending: sending, finishButtonMessage: DefaultFinishButtonMessage, hideFinishButton: pageState.linkType === "default" })))))) : pageState.type === "error" ? (preact_1.h("div", null, pageState.message)) : (preact_1.h("div", null))));
};
exports.ProductPageForPC = ProductPageForPC;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
