"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customField = void 0;
// Generated by ts-to-zod
var zod_1 = require("zod");
exports.customField = zod_1.z.object({
    company_name: zod_1.z.boolean(),
    company_pic_name: zod_1.z.boolean(),
    email: zod_1.z.boolean(),
    phone_number: zod_1.z.boolean(),
});
