"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFailSendState = exports.isSuccessSendState = exports.isSendingState = exports.isInitSendState = exports.createFailSendState = exports.createSuccessSendState = exports.createSendingState = exports.isFailState = exports.isSuccessState = exports.isLoadingState = exports.isInitState = exports.createFailState = exports.createSuccessState = exports.createLoadingState = exports.createInitState = void 0;
/**
 * Init型を作る
 * @returns Init型を表現するオブジェクト
 */
var createInitState = function () {
    return undefined;
};
exports.createInitState = createInitState;
/**
 * Loading型を作る
 * @returns Loading型を表現するオブジェクト
 */
var createLoadingState = function () {
    return { isLoading: true };
};
exports.createLoadingState = createLoadingState;
/**
 * Success型を作る
 * @param data データ
 * @returns 成功型を表現するオブジェクト
 */
var createSuccessState = function (data) {
    return { isLoading: false, data: data, error: undefined };
};
exports.createSuccessState = createSuccessState;
/**
 * Fail型を作る
 * @param message エラーメッセージ
 * @returns Fail型を表現するオブジェクト
 */
var createFailState = function (message) {
    return { isLoading: false, data: undefined, error: message };
};
exports.createFailState = createFailState;
var isInitState = function (data) {
    return data === undefined;
};
exports.isInitState = isInitState;
var isLoadingState = function (data) {
    return !exports.isInitState(data) && data.isLoading;
};
exports.isLoadingState = isLoadingState;
var isSuccessState = function (data) {
    return !exports.isInitState(data) && !exports.isLoadingState(data) && data.data !== undefined;
};
exports.isSuccessState = isSuccessState;
var isFailState = function (data) {
    return (!exports.isInitState(data) && !exports.isLoadingState(data) && data.error !== undefined);
};
exports.isFailState = isFailState;
/**
 * Sending型を作る
 * @returns Sending型を表現するオブジェクト
 */
var createSendingState = function () {
    return { isSending: true };
};
exports.createSendingState = createSendingState;
/**
 * SuccessSend型を作る
 * @param received 送信後に受け取ったデータ
 * @returns SuccessSend型を表現するオブジェクト
 */
var createSuccessSendState = function (received) {
    return { isSending: false, received: received, error: undefined };
};
exports.createSuccessSendState = createSuccessSendState;
/**
 * FailSend型を作る
 * @param received 送信失敗のエラーメッセージ
 * @returns FailSend型を表現するオブジェクト
 */
var createFailSendState = function (message) {
    return { isSending: false, received: undefined, error: message };
};
exports.createFailSendState = createFailSendState;
var isInitSendState = function (data) {
    return data === undefined;
};
exports.isInitSendState = isInitSendState;
var isSendingState = function (data) {
    return !exports.isInitSendState(data) && data.isSending;
};
exports.isSendingState = isSendingState;
var isSuccessSendState = function (data) {
    return (!exports.isInitSendState(data) && !exports.isSendingState(data) && data.error === undefined);
};
exports.isSuccessSendState = isSuccessSendState;
var isFailSendState = function (data) {
    return (!exports.isInitSendState(data) && !exports.isSendingState(data) && data.error !== undefined);
};
exports.isFailSendState = isFailSendState;
