"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CTAButton = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var goober_1 = require("goober");
var preact_1 = require("preact");
var CTAButton = function (_a) {
    var displayText = _a.displayText, linkTo = _a.linkTo, className = _a.className;
    return (preact_1.h("div", { className: styles.buttonWrapper + " " + className },
        preact_1.h("a", { href: linkTo, target: "_blank", rel: "noopener noreferrer" },
            preact_1.h("button", { className: styles.linkButton }, displayText || "お問い合わせ"))));
};
exports.CTAButton = CTAButton;
var styles = {
    buttonWrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "]))),
    linkButton: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    text-decoration: none;\n    color: ", ";\n    background: ", ";\n    min-height: 40px;\n    border-radius: 6px;\n    font-weight: bold;\n    cursor: pointer;\n    text-align: center;\n    padding: 12px 24px;\n    font-size: 0.875rem;\n    line-height: 1rem;\n  "], ["\n    width: 100%;\n    text-decoration: none;\n    color: ", ";\n    background: ", ";\n    min-height: 40px;\n    border-radius: 6px;\n    font-weight: bold;\n    cursor: pointer;\n    text-align: center;\n    padding: 12px 24px;\n    font-size: 0.875rem;\n    line-height: 1rem;\n  "])), const_1.COLOR.white, const_1.COLOR.greenPrimary),
};
var templateObject_1, templateObject_2;
