"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLogStateManagement = void 0;
var hooks_1 = require("preact/hooks");
var useLogStateManagement = function () {
    var _a = hooks_1.useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    var initLog = hooks_1.useCallback(function (logSequence) {
        dispatch(initialize({ logSequence: logSequence }));
    }, []);
    var pushLog = hooks_1.useCallback(function (log) {
        dispatch(push({ log: log }));
    }, []);
    return {
        initLog: initLog,
        pushLog: pushLog,
        logSequence: state.logSequence,
    };
};
exports.useLogStateManagement = useLogStateManagement;
var initialState = {
    logSequence: [],
};
var actionTypes = {
    INIT: "LOG/INIT",
    PUSH: "LOG/PUSH",
};
var initialize = function (data) {
    return { type: actionTypes.INIT, payload: data.logSequence };
};
var push = function (data) {
    return { type: actionTypes.PUSH, payload: data.log };
};
var reducer = function (state, action) {
    switch (action.type) {
        case actionTypes.INIT:
            return {
                logSequence: action.payload,
            };
        case actionTypes.PUSH:
            return {
                logSequence: __spreadArray(__spreadArray([], state.logSequence), [action.payload]),
            };
        default:
            return state;
    }
};
