"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PDFViewer = void 0;
var preact_1 = require("preact");
var hooks_1 = require("preact/hooks");
var src_1 = require("../../../../../../../lib/util/src");
var use_fullsreen_mode_1 = require("../../../../hooks/use-fullsreen-mode");
var document_viewing_logs_sender_1 = require("../../log-sender/document-viewing-logs-sender");
var mobile_pdf_modal_1 = require("./mobile-pdf-modal");
var pdf_link_viewer_1 = require("./pdf-link-viewer");
var pdf_modal_1 = require("./pdf-modal");
var pdf_slider_1 = require("./pdf-slider");
var PDFViewer = function (_a) {
    var content = _a.content, isPlaylistMode = _a.isPlaylistMode, wrapperHeight = _a.wrapperHeight;
    var mobile = src_1.isMobile();
    var pdfModalRef = hooks_1.useRef(null);
    var _b = use_fullsreen_mode_1.useFullscreenMode(pdfModalRef), showPDFModal = _b.isElementDisplayed, togglePDFModal = _b.toggleElement;
    if ((content === null || content === void 0 ? void 0 : content.contentType) !== "pdfs")
        return null;
    if (content.extractedImages)
        return (preact_1.h(preact_1.Fragment, null,
            preact_1.h(document_viewing_logs_sender_1.DocumentViewingLogsSender, null),
            preact_1.h(pdf_slider_1.PDFSlider, { togglePDFModal: togglePDFModal, hideButtonLabel: isPlaylistMode, isMobile: mobile, wrapperHeight: wrapperHeight }),
            mobile ? (preact_1.h(mobile_pdf_modal_1.MobilePdfModal, { isOpen: showPDFModal, toggleOpen: togglePDFModal, pdfModalRef: pdfModalRef })) : (preact_1.h(pdf_modal_1.PDFModal, { isOpen: showPDFModal, toggleOpen: togglePDFModal, pdfModalRef: pdfModalRef }))));
    return preact_1.h(pdf_link_viewer_1.PDFLinkViewer, { content: content });
};
exports.PDFViewer = PDFViewer;
