"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hearingSetSchema = void 0;
// Generated by ts-to-zod
var zod_1 = require("zod");
var v2_question_set_1 = require("./v2-question-set");
exports.hearingSetSchema = zod_1.z.object({
    id: zod_1.z.number(),
    title: zod_1.z.string(),
    status: zod_1.z.union([
        zod_1.z.literal("draft"),
        zod_1.z.literal("published"),
        zod_1.z.literal("archived"),
    ]),
    is_interactive: zod_1.z.boolean().nullable().optional(),
    question_sets: zod_1.z.array(v2_question_set_1.v2QuestionSetSchema),
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
});
