"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLOR = void 0;
/** kaizen-sales で使う色定義 */
exports.COLOR = {
    default: "#404040",
    inputDefault: "#262626",
    normal: "#4C566C",
    gray: "#D8D8D8",
    grayMedium: "#EAEAEA",
    grayMedium2: "#666666",
    grayMedium3: "#F5F5F5",
    grayMedium4: "#CACACA",
    grayMedium5: "#E5E5E5",
    lightGray: "#F1F1F1",
    white: "#FFFFFF",
    greenPrimary: "#1DA772",
    greenSecondary: "#2DCC70",
    greenMedium1: "#71DC87",
    greenMedium2: "#F2FAF4",
    darkGray: "#989898",
    darkGray2: "#9399A6",
    darkPrimary: "#5C6986",
    darkMedium: "#656565",
    darkMedium2: "#333333",
    darkMedium3: "#555555",
    black: "#000000",
    red: "#f44336",
    blue: "#007AFF",
    linkBlue: "#0062C4",
    lightBlue: "#057af0",
    boxShadow: "rgba(0, 0, 0, 0.1)",
};
