"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Label = void 0;
var goober_1 = require("goober");
var preact_1 = require("preact");
var color_1 = require("../../const/src/color");
var Component = function (_a) {
    var className = _a.className, children = _a.children;
    return preact_1.h("div", { className: className }, children);
};
var StyledComponent = goober_1.styled(Component)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 0.75rem;\n  color: ", ";\n  background: ", ";\n  border-radius: 4px;\n  padding: 4px 8px;\n  white-space: nowrap;\n"], ["\n  font-size: 0.75rem;\n  color: ", ";\n  background: ", ";\n  border-radius: 4px;\n  padding: 4px 8px;\n  white-space: nowrap;\n"])), color_1.COLOR.white, color_1.COLOR.normal);
exports.Label = StyledComponent;
var templateObject_1;
