"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressTracker = void 0;
var DURATION_THRESHOLD = 1.0; // [sec]
var CURRENT_TIME_THRESHOLD = 2.0; // [sec]
var LOG_QUEUE_MAX_LENGTH = 10;
// src/views/components/compound/VideoPlayer/index.tsx の class 名
var VIDEO_PLAYER_CLASS_NAME = "video-player";
var VIDEO_PLAYER_EVENT_NAME = "video-event";
var ProgressTracker = /** @class */ (function () {
    function ProgressTracker(videoType, videoId, vimeoId) {
        if (vimeoId === void 0) { vimeoId = undefined; }
        this.videoType = videoType;
        this.videoId = videoId;
        this.vimeoId = vimeoId;
        this.enabled = false;
        this.device = "";
        this.browser = "";
        this.checkEnabled();
        this.videoTag = undefined;
        this.videoPlayerTag = undefined;
        this.playing = false;
        this.sessionId = "";
        this.lastProgress = { duration: 0, currentTime: 0 };
        this.queue = [];
    }
    ProgressTracker.prototype.checkEnabled = function () {
        if (!this.enabled) {
            if (ProgressTracker.isSitejsAvailable()) {
                this.enabled = true;
                var browser = window.kzs.navigator.getBrowser();
                this.device = window.kzs.navigator.getDevice();
                this.browser = browser.name + " " + browser.majorVersion;
            }
        }
        return this.enabled;
    };
    ProgressTracker.prototype.track = function (eventName, data, extra) {
        if (extra === void 0) { extra = {}; }
        var duration = data.duration, currentTime = data.currentTime;
        if (eventName === "init" ||
            eventName === "ready" ||
            eventName === "bufferstart" ||
            eventName === "bufferend") {
            this.sendLog(eventName, data, extra);
        }
        else if (eventName === "ended") {
            if (this.playing) {
                this.playing = false;
                this.sendLog("end", data, extra);
            }
        }
        else if (!this.playing) {
            if (eventName !== "play")
                return;
            this.playing = true;
            this.updateSessionId();
            this.lastProgress = { duration: duration, currentTime: currentTime };
            this.sendLog("begin", data, extra);
        }
        else {
            if (!(data.duration >= 0 && data.currentTime >= 0))
                return;
            if (Math.abs(this.lastProgress.duration - duration) >= DURATION_THRESHOLD ||
                Math.abs(this.lastProgress.currentTime - currentTime) >=
                    CURRENT_TIME_THRESHOLD) {
                this.lastProgress = { duration: duration, currentTime: currentTime };
                this.sendLog("progress", data, extra);
            }
        }
    };
    ProgressTracker.prototype.sendLog = function (eventType, data, extra) {
        if (extra === void 0) { extra = {}; }
        var log = {
            eventType: eventType,
            videoType: this.videoType,
            videoId: this.videoId,
            videoTime: Math.round(data.currentTime * 1000.0) / 1000.0,
            videoDuration: Math.round(data.duration * 1000.0) / 1000.0,
            videoSession: this.sessionId,
            extra: extra,
            // possibly sitejs is not yet available at this point in time, so set following attributes later
            //device: this.device,
            //browser: this.browser,
        };
        if (this.vimeoId)
            log.vimeoId = this.vimeoId;
        while (this.queue.length >= LOG_QUEUE_MAX_LENGTH) {
            this.queue.shift();
        }
        this.queue.push(log);
        if (this.checkEnabled()) {
            while (this.queue.length > 0) {
                var logToSend = this.queue.shift();
                if (logToSend) {
                    logToSend.device = this.device;
                    logToSend.browser = this.browser;
                    ProgressTracker.sendLogBySitejs(logToSend);
                }
            }
        }
        if (this.videoPlayerTag) {
            var event = new CustomEvent(VIDEO_PLAYER_EVENT_NAME, { detail: log });
            this.videoPlayerTag.dispatchEvent(event);
        }
    };
    ProgressTracker.prototype.updateSessionId = function () {
        this.sessionId = "xxxxxxxxxxxx".replace(/x/g, function () {
            return Math.floor(Math.random() * 16).toString(16);
        });
    };
    ProgressTracker.prototype.start = function (videoTag) {
        var _this = this;
        this.videoTag = videoTag;
        if (this.videoTag) {
            var videoTag_1 = this.videoTag;
            [
                "play",
                "ended",
                "timeupdate",
                "seeking",
                "seeked",
                "durationchange",
                "loadeddata",
                "loadedmetadata",
            ].forEach(function (type) {
                videoTag_1.addEventListener(type, function (ev) {
                    var duration = videoTag_1.duration || 0;
                    var currentTime = videoTag_1.currentTime || 0;
                    _this.track(ev.type, { duration: duration, currentTime: currentTime });
                });
            });
            this.videoPlayerTag = ProgressTracker.findVideoPlayerTag(this.videoTag);
        }
    };
    ProgressTracker.isSitejsAvailable = function () {
        try {
            if (!window.kzs)
                return false;
            var version = window.kzs.current.sitejsVersion;
            return version === 2 || version === 3;
        }
        catch (err) {
            console.info(err);
            return false;
        }
    };
    ProgressTracker.sendLogBySitejs = function (log) {
        try {
            window.kzs.current.context.tracker
                .insightTracker()
                ._track({ type: "custom", custom: log });
            var debug = [];
            for (var _i = 0, _a = [
                "videoType",
                "videoId",
                "videoSession",
                "eventType",
                "videoTime",
                "videoDuration",
            ]; _i < _a.length; _i++) {
                var key = _a[_i];
                debug.push(log[key]);
            }
            window.kzs.console.warn("send video log: " + debug.join(", "));
        }
        catch (err) {
            console.error(err);
        }
    };
    ProgressTracker.findVideoPlayerTag = function (element) {
        var _a;
        if (element) {
            if ((_a = element.classList) === null || _a === void 0 ? void 0 : _a.contains(VIDEO_PLAYER_CLASS_NAME)) {
                return element;
            }
            else if (element.parentElement && element.parentElement !== element) {
                return this.findVideoPlayerTag(element.parentElement);
            }
        }
        return undefined;
    };
    return ProgressTracker;
}());
exports.ProgressTracker = ProgressTracker;
