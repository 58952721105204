"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v1HearingSetSchema = void 0;
var zod_1 = require("zod");
var v1_question_set_1 = require("./v1-question-set");
exports.v1HearingSetSchema = zod_1.z.object({
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
    id: zod_1.z.number(),
    title: zod_1.z.string().nullable().optional(),
    status: zod_1.z.union([
        zod_1.z.literal("draft"),
        zod_1.z.literal("published"),
        zod_1.z.literal("archived"),
    ]),
    is_interactive: zod_1.z.boolean(),
    question_sets: zod_1.z.array(v1_question_set_1.v1QuestionSetSchema),
});
