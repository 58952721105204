"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioButtonItem = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var goober_1 = require("goober");
var preact_1 = require("preact");
var radio_button_1 = require("./radio-button");
var Component = function (props) {
    return (preact_1.h("div", { className: props.className + " " + const_1.CHOICE_ITEM },
        preact_1.h(radio_button_1.RadioButton, { id: props.id, name: "choice", onChange: props.onChange, value: props.value, checked: props.checked }),
        preact_1.h("label", { htmlFor: props.id }, props.content)));
};
var StyledComponent = goober_1.styled(Component)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: \"Montserrat\", \"Hiragino Kaku Gothic ProN\",\n    \"Hiragino Kaku Gothic Pro\", \"\u6E38\u30B4\u30B7\u30C3\u30AF\", Meiryo, \u30E1\u30A4\u30EA\u30AA, \"\uFF2D\uFF33 \u30B4\u30B7\u30C3\u30AF\",\n    sans-serif;\n  margin-top: 8px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  & > label {\n    margin-left: 10px;\n    color: ", ";\n    line-height: 19px;\n    cursor: pointer;\n  }\n"], ["\n  font-family: \"Montserrat\", \"Hiragino Kaku Gothic ProN\",\n    \"Hiragino Kaku Gothic Pro\", \"\u6E38\u30B4\u30B7\u30C3\u30AF\", Meiryo, \u30E1\u30A4\u30EA\u30AA, \"\uFF2D\uFF33 \u30B4\u30B7\u30C3\u30AF\",\n    sans-serif;\n  margin-top: 8px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  & > label {\n    margin-left: 10px;\n    color: ", ";\n    line-height: 19px;\n    cursor: pointer;\n  }\n"])), const_1.COLOR.inputDefault);
/** radiobutton の input と label をセットにしたコンポーネント */
exports.RadioButtonItem = StyledComponent;
var templateObject_1;
