"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCalcContentHeight = void 0;
var hooks_1 = require("preact/hooks");
var DEFAULT_ASPECT_RATIO = 9 / 16;
var VIDEO_MAX_HEIGHT = 520; //Max height of content wrapper type video which ensures that the content player fit 13 inch PC
var DOCUMENT_MAX_HEIGHT = 640; //Max height of content wrapper type pdfs/images which ensures that the content player fit 13 inch PC
var MOBILE_MAX_HEIGHT = 90 / 100; //Ratio of contentMaxHeight / windhowHeight on Mobile
var useCalcContentHeight = function (wrapperRef, contentType, isMobile) {
    var _a = hooks_1.useState(DEFAULT_ASPECT_RATIO), aspectRatio = _a[0], setAspectRatio = _a[1];
    var _b = hooks_1.useState(0), wrapperWidth = _b[0], setWrapperWidth = _b[1];
    var _c = hooks_1.useState("auto"), wrapperHeight = _c[0], setWrapperHeight = _c[1];
    hooks_1.useLayoutEffect(function () {
        var calculatedHeight = wrapperWidth * aspectRatio;
        if (!calculatedHeight)
            return;
        if (!isMobile) {
            var maxHeight = contentType === "videos" || contentType === "links"
                ? VIDEO_MAX_HEIGHT
                : DOCUMENT_MAX_HEIGHT;
            var height = calculatedHeight < maxHeight
                ? calculatedHeight + "px"
                : maxHeight + "px";
            setWrapperHeight(height);
        }
        else {
            var windowHeight = window.innerHeight;
            var maxHeight = windowHeight * MOBILE_MAX_HEIGHT;
            var height = calculatedHeight < maxHeight
                ? calculatedHeight + "px"
                : maxHeight + "px";
            setWrapperHeight(height);
        }
    }, [wrapperWidth, aspectRatio, isMobile, contentType]);
    hooks_1.useLayoutEffect(function () {
        var _a;
        var localRef = null;
        if (wrapperRef.current)
            localRef = wrapperRef.current;
        setWrapperWidth(((_a = wrapperRef === null || wrapperRef === void 0 ? void 0 : wrapperRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 0);
        window.addEventListener("resize", function (_e) {
            var width = (localRef === null || localRef === void 0 ? void 0 : localRef.offsetWidth) || 0;
            setWrapperWidth(width);
        });
        return function () {
            return window.removeEventListener("resize", function (_e) {
                var width = (localRef === null || localRef === void 0 ? void 0 : localRef.offsetWidth) || 0;
                setWrapperWidth(width);
            });
        };
    }, [wrapperRef]);
    var handleChangeAspectRatio = function (ratio) { return setAspectRatio(ratio); };
    return {
        wrapperHeight: wrapperHeight,
        handleChangeAspectRatio: handleChangeAspectRatio,
    };
};
exports.useCalcContentHeight = useCalcContentHeight;
