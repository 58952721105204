"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentRenderer = exports.renderContent = void 0;
var goober_1 = require("goober");
var preact_1 = require("preact");
var hooks_1 = require("preact/hooks");
var src_1 = require("../../../../../../lib/const/src");
var src_2 = require("../../../../../../lib/util/src");
var use_calc_content_height_1 = require("../../../hooks/use-calc-content-height");
var pdf_viewer_1 = require("./pdf-viewer");
var video_link_player_1 = require("./video-link-player");
var video_player_1 = require("./video-player");
// TODO:
// - player がmute した状態で描画されるかどうかの条件分岐書く (touchDevice)
var renderContent = function (props) {
    var _a;
    switch ((_a = props.content) === null || _a === void 0 ? void 0 : _a.contentType) {
        case "videos":
            return preact_1.h(video_player_1.VideoPlayer, __assign({}, props));
        case "images":
            return (preact_1.h("span", { className: "image " + styles.imageViewer({
                    wrapperHeight: props.wrapperHeight,
                }), style: { backgroundImage: "url(" + props.content.url + ")" } }));
        case "links":
            return (
            // TODO: link のときも kaizen-video-player 使うのか調べる
            preact_1.h("div", { className: "video-link-wrapper " + styles.videoLinkWrapper({
                    wrapperHeight: props.wrapperHeight,
                }) },
                preact_1.h(video_link_player_1.VideoLinkPlayer
                // TODO: null が万が一渡ってきた時のハンドリングを考えよう。というかそもそもnullを返さないAPIを目指そう
                , { 
                    // TODO: null が万が一渡ってきた時のハンドリングを考えよう。というかそもそもnullを返さないAPIを目指そう
                    url: props.content.url || "" })));
        case "pdfs":
            return (preact_1.h(pdf_viewer_1.PDFViewer, { content: props.content, isPlaylistMode: props.isPlaylistMode, wrapperHeight: props.wrapperHeight }));
        default:
            return preact_1.h(preact_1.Fragment, null);
    }
};
exports.renderContent = renderContent;
var Component = function (props) {
    var _a;
    var contentRendererRef = hooks_1.useRef(null);
    var mobile = src_2.isMobile();
    var _b = use_calc_content_height_1.useCalcContentHeight(contentRendererRef, (_a = props.content) === null || _a === void 0 ? void 0 : _a.contentType, mobile), wrapperHeight = _b.wrapperHeight, handleChangeAspectRatio = _b.handleChangeAspectRatio;
    return (preact_1.h("div", { ref: contentRendererRef, className: props.className }, exports.renderContent(__assign(__assign({}, props), { isMobile: mobile, wrapperHeight: wrapperHeight, handleChangeAspectRatio: handleChangeAspectRatio }))));
};
var StyledComponent = goober_1.styled(Component)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 100%;\n"], ["\n  min-height: 100%;\n"])));
var styles = {
    imageViewer: function (props) { return goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    height: ", ";\n    display: block;\n    background-color: ", ";\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: 50% 50%;\n  "], ["\n    width: 100%;\n    height: ", ";\n    display: block;\n    background-color: ", ";\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: 50% 50%;\n  "])), props.wrapperHeight, src_1.COLOR.white); },
    videoLinkWrapper: function (props) { return goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n    height: ", ";\n  "], ["\n    width: 100%;\n    height: ", ";\n  "])), props.wrapperHeight); },
};
exports.ContentRenderer = StyledComponent;
var templateObject_1, templateObject_2, templateObject_3;
