"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UpdateFlagHandler = /** @class */ (function () {
    function UpdateFlagHandler(func, intervalTimeMs, intervalID) {
        var _this = this;
        if (intervalTimeMs === void 0) { intervalTimeMs = 10000; }
        this.func = func;
        this.intervalTimeMs = intervalTimeMs;
        this.intervalID = intervalID;
        this.on = function () {
            var setUpdateInterval = function () {
                return window.setInterval(function () { return _this.func(); }, _this.intervalTimeMs);
            };
            _this.intervalID = setUpdateInterval();
        };
        this.off = function () {
            clearInterval(_this.intervalID);
            _this.intervalID = 0;
        };
    }
    return UpdateFlagHandler;
}());
exports.default = UpdateFlagHandler;
