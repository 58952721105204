"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.detectIOSVersion = void 0;
var detectIOSVersion = function () {
    var agent = navigator.userAgent;
    var isIOSDevice = agent.match(/iPhone|iPad|iPod/i);
    if (!isIOSDevice)
        return -1;
    return (parseFloat(("" + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(agent) || [0, ""])[1])
        .replace("undefined", "3_2")
        .replace("_", ".")
        .replace("_", "")) || -1);
};
exports.detectIOSVersion = detectIOSVersion;
