"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var WindowFocusHandler = /** @class */ (function () {
    function WindowFocusHandler(plyr, pushLog) {
        var _this = this;
        this.plyr = plyr;
        this.pushLog = pushLog;
        this.handlePause = function () {
            _this.plyr.pause();
            _this.pushLog("pause", "current");
        };
        this.handlePlay = function () {
            _this.plyr.play();
            _this.pushLog("start", "current");
        };
        this.addWindowFocusListener = function () {
            window.addEventListener("blur", _this.handlePause);
            window.addEventListener("focus", _this.handlePlay);
        };
        this.removeWindowFocusListener = function () {
            window.removeEventListener("blur", _this.handlePause);
            window.removeEventListener("focus", _this.handlePlay);
        };
    }
    return WindowFocusHandler;
}());
exports.default = WindowFocusHandler;
