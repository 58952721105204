"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcCursorPosition = void 0;
var calcCursorPosition = function (currentIdx, length) {
    return length === 1
        ? "last"
        : currentIdx === 0
            ? "first"
            : currentIdx === length - 1
                ? "last"
                : "middle";
};
exports.calcCursorPosition = calcCursorPosition;
