"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaylistModeContextProvider = exports.usePlaylistModeState = void 0;
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var use_convert_data_model_1 = require("../hooks/use-convert-data-model");
var PlaylistModeContext = compat_1.createContext({
    isPlaylistMode: false,
    playlist: {
        contents: [],
        config: {
            cta: [],
        },
    },
});
var usePlaylistModeState = function () {
    return compat_1.useContext(PlaylistModeContext);
};
exports.usePlaylistModeState = usePlaylistModeState;
function PlaylistModeContextProvider(_a) {
    var children = _a.children;
    var _b = compat_1.useState(false), isPlaylistMode = _b[0], setIsPlaylistMode = _b[1];
    var _c = compat_1.useState({
        contents: [],
        config: {
            cta: [],
        },
    }), playlist = _c[0], setPlaylist = _c[1];
    var state = use_convert_data_model_1.useConvertDataModel();
    compat_1.useEffect(function () {
        if (state.type === "showProduct") {
            var config_ = {
                cta: state.config.cta,
            };
            setPlaylist({ contents: state.data, config: config_ });
            setIsPlaylistMode(!!state.config.isPlaylist);
        }
    }, [state]);
    return (preact_1.h(PlaylistModeContext.Provider, { value: { isPlaylistMode: isPlaylistMode, playlist: playlist } }, children));
}
exports.PlaylistModeContextProvider = PlaylistModeContextProvider;
