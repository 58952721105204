"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v1ProductContentSchema = void 0;
var zod_1 = require("zod");
var v1_content_for_embed_1 = require("./v1-content-for-embed");
exports.v1ProductContentSchema = zod_1.z.object({
    id: zod_1.z.number(),
    title: zod_1.z.string(),
    status: zod_1.z.union([
        zod_1.z.literal("draft"),
        zod_1.z.literal("published"),
        zod_1.z.literal("archived"),
    ]),
    public_description: zod_1.z.string().nullable(),
    content: v1_content_for_embed_1.v1ContentForEmbeddedSchema,
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
    service_resource_id_for_logger: zod_1.z.string(),
});
