"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogWasNotSentError = exports.Log = void 0;
var repository_1 = require("@kaizen-dx-tools/repository");
var DurationIsNegativeError = /** @class */ (function (_super) {
    __extends(DurationIsNegativeError, _super);
    function DurationIsNegativeError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DurationIsNegativeError;
}(RangeError));
var LogWasNotSentError = /** @class */ (function (_super) {
    __extends(LogWasNotSentError, _super);
    function LogWasNotSentError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LogWasNotSentError;
}(Error));
exports.LogWasNotSentError = LogWasNotSentError;
var acceptedFlagTransitions = new Map([
    ["start", new Set(["pause", "update", "end"])],
    ["pause", new Set(["start"])],
    ["update", new Set(["pause", "update", "end"])],
    ["end", new Set(["start"])],
]);
var Log = /** @class */ (function () {
    function Log(sessionId, questionSetId, contentId, hashToken, logId, plyr, player, lastLogSentTimestampMs, event, positionTypeOrPositionMs) {
        var _this = this;
        if (lastLogSentTimestampMs === void 0) { lastLogSentTimestampMs = 0; }
        this.sessionId = sessionId;
        this.questionSetId = questionSetId;
        this.contentId = contentId;
        this.hashToken = hashToken;
        this.logId = logId;
        this.plyr = plyr;
        this.player = player;
        this.lastLogSentTimestampMs = lastLogSentTimestampMs;
        this.event = event;
        this.positionTypeOrPositionMs = positionTypeOrPositionMs;
        this.constraints = {
            assureMarkovProcess: function (acceptedTransitions, lastLog, newLog) {
                if (acceptedTransitions === void 0) { acceptedTransitions = acceptedFlagTransitions; }
                var nextTransitions = acceptedTransitions.get(lastLog.event);
                if (nextTransitions) {
                    return nextTransitions.has(newLog.event);
                }
                throw new Error(lastLog.event + "\u306F\u30ED\u30B0\u30A4\u30D9\u30F3\u30C8\u3067\u306F\u3042\u308A\u307E\u305B\u3093\u3002");
            },
            assureIrreversibilityOfPosition: function (lastLog, newLog) {
                return !(new Set(["start", "update"]).has(lastLog.event) &&
                    new Set(["update", "pause", "end"]).has(newLog.event)) || newLog.positionMs - lastLog.positionMs > -1000;
            },
            // NOTE: Kaizen Video Playerの実装上，不等式 `newData.position > lastData.position` は
            // 常には成立しないため，1000㍉秒のバッファを与えています。
            assurePositionIsUnderDuration: function (newLog) {
                return newLog.positionMs <= _this.plyr.duration * 1000;
            },
            assureUpdateIsUnderPauseOrEnd: function (lastLog, newLog) {
                if (lastLog.event === "update" &&
                    new Set(["pause", "end"]).has(newLog.event)) {
                    return lastLog.positionMs < newLog.positionMs;
                }
                return true;
            },
        };
        this.durationMs = 0;
        this.hasbeenSent = false;
        this.positionType = "current";
        this.calcPosition = function () {
            switch (_this.positionType) {
                case "current":
                    return Math.floor(_this.plyr.currentTime * 1000);
                case "lastCurrent":
                    return Math.floor(_this.player.tracker.lastCurrentTime * 1000);
            }
        };
        /**
         * @throws {DurationIsNegativeError} 前回からのログ送信間隔が負の値になっている。
         */
        this.calcDuration = function () {
            var duration = Math.floor(Date.now() - _this.lastLogSentTimestampMs);
            if (duration >= 0) {
                return duration;
            }
            throw new DurationIsNegativeError("durationの値が負です。");
        };
        /**
         * @func Kaizen Video Playerの実装上，updateフラグのpositionMsがpauseフラグのpositionMsよりも大きいことがあるので，その場合はupdateフラグのpositionMsをpauseフラグのpositionMsに置き換える。
         * @param logSequence
         */
        this.normalize = function (logSequence, toleranceMs) {
            if (toleranceMs === void 0) { toleranceMs = 2000; }
            if (logSequence.length < 2)
                return _this;
            var lastLog = logSequence[logSequence.length - 1];
            if (new Set(["update"]).has(lastLog.event) &&
                new Set(["pause", "end"]).has(_this.event)) {
                var positionDiffMs = _this.positionMs - lastLog.positionMs;
                var durationDiffMs = _this.durationMs - lastLog.durationMs;
                if (Math.abs(durationDiffMs - positionDiffMs) > toleranceMs) {
                    _this.positionMs += _this.durationMs - lastLog.durationMs;
                }
                if (positionDiffMs < 0 && Math.abs(positionDiffMs) < toleranceMs) {
                    _this.positionMs = lastLog.positionMs + 1; // NOTE: endフラグが，updateフラグより1㍉秒だけ大きくなるようにする。
                }
            }
            return _this;
        };
        this.isValid = function (logSequence) {
            if (!logSequence.length)
                return _this.event === "start";
            if (!_this.contentId)
                return false;
            var lastLog = logSequence[logSequence.length - 1];
            return (_this.constraints.assureMarkovProcess(acceptedFlagTransitions, lastLog, _this) &&
                _this.constraints.assureIrreversibilityOfPosition(lastLog, _this) &&
                _this.constraints.assurePositionIsUnderDuration(_this) &&
                _this.constraints.assureUpdateIsUnderPauseOrEnd(lastLog, _this));
        };
        /**
         * @throws {LogWasNotSentError} ログ送信が失敗した。
         */
        this.send = function () { return __awaiter(_this, void 0, Promise, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = {
                            sessionId: this.sessionId,
                            questionSetId: this.questionSetId,
                            contentId: this.contentId,
                            event: this.event,
                            logId: this.logId,
                            position: this.positionMs,
                            duration: this.durationMs,
                        };
                        return [4 /*yield*/, repository_1.v1Repository
                                .sendVideoViewingLogs(this.hashToken, data)
                                .then(function () {
                                _this.hasbeenSent = true;
                                _this.lastLogSentTimestampMs = Date.now();
                            })
                                .catch(function () { return new LogWasNotSentError("failed to send video viewing log"); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        if (typeof this.positionTypeOrPositionMs === "number") {
            this.positionMs = this.positionTypeOrPositionMs;
        }
        else {
            this.positionType = this.positionTypeOrPositionMs;
            this.positionMs = this.calcPosition();
        }
        try {
            this.durationMs = this.calcDuration();
        }
        catch (e) {
            if (e instanceof DurationIsNegativeError) {
                console.error(e.message);
            }
            else {
                throw e;
            }
        }
    }
    return Log;
}());
exports.Log = Log;
