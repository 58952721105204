"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormSubmitStatusContextProvider = exports.useUpdateFormSubmitStatus = exports.useFormSubmitState = void 0;
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var use_form_submit_status_1 = require("../hooks/use-form-submit-status");
var FormSubmitContext = compat_1.createContext(false);
var useFormSubmitState = function () { return compat_1.useContext(FormSubmitContext); };
exports.useFormSubmitState = useFormSubmitState;
var UpdateFormSubmitContext = compat_1.createContext(function () {
    // no op
});
var useUpdateFormSubmitStatus = function () {
    return compat_1.useContext(UpdateFormSubmitContext);
};
exports.useUpdateFormSubmitStatus = useUpdateFormSubmitStatus;
function FormSubmitStatusContextProvider(_a) {
    var children = _a.children;
    var _b = use_form_submit_status_1.useFormSubmitStatus(), isFormSubmitted = _b.isFormSubmitted, setFormSubmitted = _b.setFormSubmitted;
    return (preact_1.h(FormSubmitContext.Provider, { value: isFormSubmitted },
        preact_1.h(UpdateFormSubmitContext.Provider, { value: setFormSubmitted }, children)));
}
exports.FormSubmitStatusContextProvider = FormSubmitStatusContextProvider;
