"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v2ContentSchema = void 0;
var zod_1 = require("zod");
var contentTypeSchema = zod_1.z.literal("videos");
exports.v2ContentSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content_type: contentTypeSchema,
    kaizen_files_bucket_id: zod_1.z.string().nullable(),
    kaizen_files_id: zod_1.z.string().nullable(),
    kaizen_files_thumbnail_url: zod_1.z.string().nullable(),
    kaizen_files_url: zod_1.z.string().nullable(),
    duration: zod_1.z.number().nullable(),
});
