"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowProduct = void 0;
var preact_1 = require("preact");
var src_1 = require("../../../../../lib/util/src");
var product_page_for_mobile_1 = require("./product-page-for-mobile");
var product_page_for_pc_1 = require("./product-page-for-pc");
var ShowProduct = function () {
    var mobile = src_1.isMobile();
    return mobile ? preact_1.h(product_page_for_mobile_1.ProductPageForMobile, null) : preact_1.h(product_page_for_pc_1.ProductPageForPC, null);
};
exports.ShowProduct = ShowProduct;
