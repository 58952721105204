"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HearingPageForMobile = void 0;
var component_1 = require("@kaizen-dx-tools/component");
var const_1 = require("@kaizen-dx-tools/const");
var util_1 = require("@kaizen-dx-tools/util");
var goober_1 = require("goober");
var preact_1 = require("preact");
var use_hearing_1 = require("../../hooks/pages/use-hearing");
var preview_1 = require("../../module/preview");
var user_config_1 = require("../../module/user-config");
var entry_form_1 = require("../../pages/entry-form");
var chapter_list_1 = require("../common/chapter-list");
var content_renderer_1 = require("../common/content-renderer");
var preview_card_1 = require("../common/preview-card");
var section_card_1 = require("../common/section-card");
var choices_1 = require("./choices");
var radios_1 = require("./radios");
var styles = {
    wrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    overflow-x: hidden;\n    padding: 16px 0px 20px 0px;\n  "], ["\n    overflow-x: hidden;\n    padding: 16px 0px 20px 0px;\n  "]))),
    contentWrapper: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""]))),
    questionWrapper: goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: 100%;\n    max-width: 1280px;\n    display: flex;\n    flex-direction: column;\n    ", " {\n      height: 100%;\n    }\n  "], ["\n    width: 100%;\n    max-width: 1280px;\n    display: flex;\n    flex-direction: column;\n    ", " {\n      height: 100%;\n    }\n  "])), util_1.query("sm")),
    content: goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 15px;\n  "], ["\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 15px;\n  "]))),
    chapterList: goober_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin: 16px 16px 0px 16px;\n  "], ["\n    margin: 16px 16px 0px 16px;\n  "]))),
    toRight: component_1.fromLeftToRightAnimation,
    toLeft: component_1.fromRightToLeftAnimation,
    questionTitle: goober_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: ", ";\n    font-weight: bold;\n  "], ["\n    color: ", ";\n    font-weight: bold;\n  "])), const_1.COLOR.inputDefault),
    buttonRowWrapper: goober_1.css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin: 20px 16px 0px 16px;\n    white-space: nowrap;\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n  "], ["\n    margin: 20px 16px 0px 16px;\n    white-space: nowrap;\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n    & .", " {\n      height: 32px !important;\n      padding: 8px 16px !important;\n    }\n  "])), const_1.BUTTON_ROW_BACK, const_1.BUTTON_ROW_NEXT, const_1.BUTTON_ROW_SEND),
    input: goober_1.css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    margin: 8px 24px 0px 24px;\n    width: calc(100% - 48px);\n    padding: 8px !important;\n  "], ["\n    margin: 8px 24px 0px 24px;\n    width: calc(100% - 48px);\n    padding: 8px !important;\n  "]))),
    questionPanel: goober_1.css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    margin: 16px 16px 0px 16px;\n  "], ["\n    margin: 16px 16px 0px 16px;\n  "]))),
    answersPanel: goober_1.css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    padding: 0px 24px;\n    margin-bottom: 8px;\n    font-size: 14px;\n  "], ["\n    padding: 0px 24px;\n    margin-bottom: 8px;\n    font-size: 14px;\n  "]))),
};
var HearingPageForMobile = function () {
    var _a, _b;
    var _c = use_hearing_1.useHearing(), pageState = _c.pageState, handleClickNextButton = _c.handleClickNextButton, handleClickBackButton = _c.handleClickBackButton, handleCheckAnswer = _c.handleCheckAnswer, handleInputOtherText = _c.handleInputOtherText, answerState = _c.answerState, showOtherTextArea = _c.showOtherTextArea, canGoNext = _c.canGoNext, sending = _c.sending, handleChangeCurrentChapter = _c.handleChangeCurrentChapter, setVideoPlayer = _c.setVideoPlayer;
    var _d = user_config_1.useUserConfig(), hashToken = _d.hashToken, sessionId = _d.sessionId;
    var _e = preview_1.usePreviewState(), shouldSendLog = _e.shouldSendLog, showPopup = _e.showPopup;
    return (preact_1.h("div", { className: styles.wrapper },
        showPopup ? preact_1.h(preview_card_1.PreviewCard, { isMobile: true }) : null,
        pageState.type === "loaded" ? (pageState.showForm ? (preact_1.h(entry_form_1.EntryForm, null)) : (preact_1.h("div", { className: styles.questionWrapper, key: (_a = pageState.current.content) === null || _a === void 0 ? void 0 : _a.id },
            pageState.current.content && (preact_1.h("div", { className: styles.contentWrapper },
                preact_1.h(content_renderer_1.ContentRenderer, { content: pageState.current.content, className: styles.content, config: {
                        loggerScriptKey: pageState.config.loggerScriptKey,
                    }, loggingOption: {
                        loggerEndpoint: pageState.loggerEndpoint,
                        userId: undefined,
                        placement: pageState.linkType === "hearing"
                            ? "hearing_link"
                            : "none",
                        serviceResourceId: pageState.serviceResourceIdForLogger + ":" + pageState.current.question.serviceResourceIdForLogger,
                        logging: !!shouldSendLog,
                        customValue: { sales_session_id: sessionId },
                    }, setVideoPlayer: setVideoPlayer }))),
            ((_b = pageState.current.content) === null || _b === void 0 ? void 0 : _b.contentType) === "videos" &&
                pageState.current.content.chapters &&
                pageState.current.content.chapters.length > 0 && (preact_1.h(chapter_list_1.ChapterList, { chapters: pageState.current.content.chapters, className: styles.chapterList, handleClickChapter: function (chapterIndex) {
                    return handleChangeCurrentChapter(chapterIndex);
                } })),
            preact_1.h(section_card_1.SectionCard, { title: pageState.current.question.body, className: (pageState.animation === "toLeft"
                    ? styles.toLeft
                    : pageState.animation === "toRight"
                        ? styles.toRight
                        : "") + " " + styles.questionPanel, titleClassName: const_1.QUESTION_TITLE, key: pageState.current.question.id },
                preact_1.h("div", { className: (pageState.current.question.isMultipleChoice
                        ? const_1.CHECKBOX_PANEL
                        : const_1.RADIOBUTTON_PANEL) + " " + styles.answersPanel }, pageState.current.question.isMultipleChoice ? (preact_1.h(choices_1.Choices, { choices: pageState.current.choices, handleCheck: handleCheckAnswer, isNeedOther: pageState.current.question.hasOther, answerState: answerState, isSelectedOther: showOtherTextArea })) : (preact_1.h(radios_1.Radios, { choices: pageState.current.choices, handleCheck: handleCheckAnswer, isNeedOther: pageState.current.question.hasOther, answerState: answerState, isSelectedOther: showOtherTextArea }))),
                showOtherTextArea && (preact_1.h(component_1.Input, { onChange: function (e) {
                        e.preventDefault();
                        handleInputOtherText(e.target.value);
                    }, value: answerState === null || answerState === void 0 ? void 0 : answerState.otherAnswer, className: styles.input }))),
            preact_1.h("div", { className: styles.buttonRowWrapper },
                preact_1.h(component_1.ButtonRow, { onClickBack: handleClickBackButton, onClickNext: handleClickNextButton, cursor: pageState.cursorPosition, canGoNext: canGoNext, sending: sending, finishButtonMessage: sending ? "送信中" : "回答を完了", hideFinishButton: false }))))) : pageState.type === "error" ? (preact_1.h("p", null, pageState.message)) : (preact_1.h("div", null))));
};
exports.HearingPageForMobile = HearingPageForMobile;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
