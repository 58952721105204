"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVideoStartTimeFromParams = void 0;
var getVideoStartTimeFromParams = function (videoDuration) {
    var params = new URLSearchParams(window.location.search);
    var timeParams = params.getAll("t") || [];
    if (timeParams.length === 1 &&
        parseInt(timeParams[0], 10) <= videoDuration * 1000) {
        return parseInt(timeParams[0], 10);
    }
    return 0;
};
exports.getVideoStartTimeFromParams = getVideoStartTimeFromParams;
