"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PCHeader = void 0;
var component_1 = require("@kaizen-dx-tools/component");
var const_1 = require("@kaizen-dx-tools/const");
var util_1 = require("@kaizen-dx-tools/util");
var goober_1 = require("goober");
var preact_1 = require("preact");
var use_header_1 = require("../../hooks/pages/use-header");
var styles = {
    wrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n    height: 64px;\n    padding: 12px 24px;\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    height: 64px;\n    padding: 12px 24px;\n    color: ", ";\n  "])), const_1.COLOR.white, const_1.COLOR.default),
    innerWrapper: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    max-width: 1280px;\n    margin: 0 auto;\n    height: 100%;\n  "], ["\n    display: flex;\n    max-width: 1280px;\n    margin: 0 auto;\n    height: 100%;\n  "]))),
    logoWrapper: goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    min-width: 160px;\n    height: 40px;\n    margin: 0px 30px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    ", " {\n      display: none;\n    }\n  "], ["\n    min-width: 160px;\n    height: 40px;\n    margin: 0px 30px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    ", " {\n      display: none;\n    }\n  "])), util_1.query("md")),
    separate: goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    border-left: solid 1px ", ";\n    height: 100%;\n    margin-right: 48px;\n    ", " {\n      display: none;\n    }\n  "], ["\n    border-left: solid 1px ", ";\n    height: 100%;\n    margin-right: 48px;\n    ", " {\n      display: none;\n    }\n  "])), const_1.COLOR.gray, util_1.query("md")),
    logo: goober_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 100%;\n    height: 100%;\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: 50% 50%;\n  "], ["\n    width: 100%;\n    height: 100%;\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: 50% 50%;\n  "]))),
    movieTitle: goober_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 20px;\n    line-height: 1.5;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    font-weight: bold;\n    color: ", ";\n  "], ["\n    font-size: 20px;\n    line-height: 1.5;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    font-weight: bold;\n    color: ", ";\n  "])), const_1.COLOR.darkMedium2),
    contentInfo: goober_1.css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    max-width: calc(100% - 220px);\n    display: flex;\n    align-items: center;\n    ", " {\n      margin-left: 0px;\n      max-width: 100%;\n    }\n  "], ["\n    max-width: calc(100% - 220px);\n    display: flex;\n    align-items: center;\n    ", " {\n      margin-left: 0px;\n      max-width: 100%;\n    }\n  "])), util_1.query("md")),
    label: goober_1.css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    margin-right: 16px;\n  "], ["\n    margin-right: 16px;\n  "]))),
};
var PCHeader = function () {
    var headerState = use_header_1.useHeader().headerState;
    return headerState !== undefined ? (preact_1.h("div", { className: styles.wrapper },
        preact_1.h("div", { className: styles.innerWrapper },
            preact_1.h("div", { className: styles.logoWrapper },
                preact_1.h("span", { className: styles.logo, style: { backgroundImage: "url(" + headerState.orgLogo + ")" } })),
            preact_1.h("div", { className: "" + styles.contentInfo },
                preact_1.h("div", { className: styles.separate }),
                headerState.contentType && (preact_1.h(component_1.Label, { className: styles.label }, headerState.contentType === "videos" ||
                    headerState.contentType === "links"
                    ? "動画"
                    : "資料")),
                preact_1.h("h2", { className: styles.movieTitle }, headerState.hearingSetTitle))))) : (preact_1.h("div", null));
};
exports.PCHeader = PCHeader;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
