"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataCacheContextProvider = exports.useDataCacheUpdate = exports.DataCacheUpdateContext = exports.useDataCache = void 0;
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var use_data_cache_1 = require("../hooks/use-data-cache");
var DataCacheContext = compat_1.createContext({
    state: "init",
});
var useDataCache = function () { return compat_1.useContext(DataCacheContext); };
exports.useDataCache = useDataCache;
exports.DataCacheUpdateContext = compat_1.createContext(function () {
    // no op
});
var useDataCacheUpdate = function () {
    return compat_1.useContext(exports.DataCacheUpdateContext);
};
exports.useDataCacheUpdate = useDataCacheUpdate;
function DataCacheContextProvider(_a) {
    var children = _a.children;
    var data = use_data_cache_1.useApplicationInit().data;
    return (preact_1.h(DataCacheContext.Provider, { value: data }, children));
}
exports.DataCacheContextProvider = DataCacheContextProvider;
