"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showHearingLinksResponseTypeSchema = void 0;
// Generated by ts-to-zod
var zod_1 = require("zod");
var created_by_1 = require("../partial/created-by");
var hearing_set_1 = require("../partial/hearing-set");
var v1_embed_playlist_content_1 = require("../partial/v1-embed-playlist-content");
var v1_embedding_video_1 = require("../partial/v1-embedding-video");
var v1_product_content_for_embed_1 = require("../partial/v1-product-content-for-embed");
exports.showHearingLinksResponseTypeSchema = zod_1.z.object({
    id: zod_1.z.number(),
    created_by: created_by_1.createdBySchema,
    logger_script_key: zod_1.z.number(),
    organization_logo: zod_1.z.union([zod_1.z.string(), zod_1.z.null()]),
    hash_token: zod_1.z.string(),
    product_contents: zod_1.z
        .union([zod_1.z.array(v1_product_content_for_embed_1.v1ProductContentSchema), zod_1.z.null()])
        .optional(),
    hearing_set: zod_1.z.union([hearing_set_1.hearingSetSchema, zod_1.z.null()]).optional(),
    embedding_interactive_hearing_set: zod_1.z
        .union([
        zod_1.z.object({
            hearing_set: zod_1.z.union([hearing_set_1.hearingSetSchema, zod_1.z.null()]),
        }),
        zod_1.z.null(),
    ])
        .optional(),
    embedding_video: zod_1.z.union([v1_embedding_video_1.v1EmbeddingVideoSchema, zod_1.z.null()]).optional(),
    content_playlist: zod_1.z
        .union([v1_embed_playlist_content_1.v1EmbedPlaylistContentSchema, zod_1.z.null()])
        .optional(),
    service_resource_id_for_logger: zod_1.z.string(),
});
