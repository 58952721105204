"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLoggerEndpoint = exports.getCdnOrigin = exports.getOrigin = void 0;
var error_1 = require("@kaizen-dx-tools/error");
var getOrigin = function () {
    var origin = process.env.API_ENDPOINT_ORIGIN;
    if (origin === undefined)
        throw error_1.Errors.INSUFFICIENT_ENV_ERROR;
    return origin;
};
exports.getOrigin = getOrigin;
var getCdnOrigin = function () {
    var origin = process.env.API_ENDPOINT_CDN_ORIGIN;
    if (origin === undefined)
        throw error_1.Errors.INSUFFICIENT_ENV_ERROR;
    return origin;
};
exports.getCdnOrigin = getCdnOrigin;
var getLoggerEndpoint = function () {
    var ep = process.env.VIDEO_LOGGER_ENDPOINT;
    if (ep === undefined)
        throw error_1.Errors.INSUFFICIENT_ENV_ERROR;
    return ep;
};
exports.getLoggerEndpoint = getLoggerEndpoint;
