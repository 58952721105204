"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMobile = void 0;
var isMobile = function () {
    if (window.navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
        return true;
    }
    return false;
};
exports.isMobile = isMobile;
