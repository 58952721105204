"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHOICE_ITEM = exports.RADIOBUTTON_PANEL = exports.CHECKBOX_PANEL = exports.QUESTION_TITLE = exports.CONTENT_WRAPPER = exports.BUTTON_PLAYLIST_FINISH = exports.BUTTON_PLAYLIST = exports.BUTTON_ROW_SEND = exports.BUTTON_ROW_BACK = exports.BUTTON_ROW_NEXT = exports.ANSWER_BUTTON_ROW_WRAPPER = exports.CHECKBOX_CHOICE_CLASSNAME = exports.CHECKBOX_CHOCIE_SUFFIX = exports.RADIO_CHOICE_CLASSNAME = exports.RADIO_CHOCIE_SUFFIX = exports.CHOCIE_PREFIX = void 0;
/** 選択肢のクラス名のprefix */
exports.CHOCIE_PREFIX = "choice";
/** radio button の選択肢のクラス名の suffix */
exports.RADIO_CHOCIE_SUFFIX = "radio";
/** radio button の選択肢に割り振るクラス名 */
exports.RADIO_CHOICE_CLASSNAME = exports.CHOCIE_PREFIX + "-" + exports.RADIO_CHOCIE_SUFFIX;
/** checkbox の選択肢のクラス名の suffix */
exports.CHECKBOX_CHOCIE_SUFFIX = "checkbox";
/** radio button の選択肢に割り振るクラス名 */
exports.CHECKBOX_CHOICE_CLASSNAME = exports.CHOCIE_PREFIX + "-" + exports.CHECKBOX_CHOCIE_SUFFIX;
/** 回答ボタンの行のラッパーに割り振るクラス名 */
exports.ANSWER_BUTTON_ROW_WRAPPER = "answer-button-row";
/** button-row の "次へ" ボタンに割り振るID */
exports.BUTTON_ROW_NEXT = "button-row-next";
/** button-row の "戻る" ボタンに割り振るID */
exports.BUTTON_ROW_BACK = "button-row-back";
/** button-row の "回答を送信" ボタンに割り振るID */
exports.BUTTON_ROW_SEND = "button-row-send";
/** playlist の動画ボタンに割り振るクラス名 */
exports.BUTTON_PLAYLIST = "button-playlist";
/** playlist の視聴終了ボタンに割り振るクラス名 */
exports.BUTTON_PLAYLIST_FINISH = "button-playlist-finish";
/** JS で DOM を掴むためのハンドラ */
exports.CONTENT_WRAPPER = "conent-wrapper";
/** JS で DOM を掴むためのハンドラ */
exports.QUESTION_TITLE = "question-title";
/** JS で DOM を掴むためのハンドラ */
exports.CHECKBOX_PANEL = "checkbox-panel";
/** JS で DOM を掴むためのハンドラ */
exports.RADIOBUTTON_PANEL = "radiobutton-panel";
/** check + label */
exports.CHOICE_ITEM = "choice-item";
