"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShowProduct = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var repository_1 = require("@kaizen-dx-tools/repository");
var hooks_1 = require("preact/hooks");
var src_1 = require("../../../../../lib/util/src");
var log_1 = require("../../component/common/log-sender/video/log");
var form_1 = require("../../module/form");
var form_submit_status_1 = require("../../module/form-submit-status");
var preview_1 = require("../../module/preview");
var product_content_state_1 = require("../../module/product-content-state");
var routing_1 = require("../../module/routing");
var toast_1 = require("../../module/toast");
var user_config_1 = require("../../module/user-config");
var calc_cursor_position_1 = require("../../util/calc-cursor-position");
var getVideoStartTimeFromParams_1 = require("../../util/getVideoStartTimeFromParams");
var reportError_1 = require("../../utils/reportError");
var use_convert_data_model_1 = require("../use-convert-data-model");
var useShowProduct = function () {
    var _a = hooks_1.useState({ type: "init" }), pageState = _a[0], setPageState = _a[1];
    var _b = hooks_1.useState(false), sending = _b[0], setSendingState = _b[1];
    var _c = hooks_1.useState(false), shouldSend = _c[0], setShouldSend = _c[1];
    var _d = hooks_1.useState(null), videoPlayer = _d[0], setVideoPlayer = _d[1];
    var _e = hooks_1.useState(false), isContentDisplay = _e[0], setContentDisplay = _e[1];
    var state = use_convert_data_model_1.useConvertDataModel();
    var _f = product_content_state_1.useProductContentState(), initState = _f.initState, backToPreviousQuestion = _f.backToPreviousQuestion, goToNextQuestion = _f.goToNextQuestion, jumpToQuestion = _f.jumpToQuestion, productContentsState = _f.productContentsState;
    var userConfig = user_config_1.useUserConfig();
    var isFormSubmitted = form_submit_status_1.useFormSubmitState();
    var showToast = toast_1.useSetToastMessage();
    var updateRoute = routing_1.useRoutingUpdate();
    var setShowForm = form_1.useUpdateForm();
    var showForm = form_1.useFormState();
    var shouldSendLog = preview_1.usePreviewState().shouldSendLog;
    var _g = hooks_1.useState([]), logSequenceProxy = _g[0], setLogSequenceProxy = _g[1];
    var _h = hooks_1.useState({}), metadata = _h[0], setMetadata = _h[1];
    hooks_1.useEffect(function () {
        var videoStartTime = pageState.type === "loaded" &&
            pageState.content.contentType === "videos" &&
            pageState.content.isFirstVideo
            ? getVideoStartTimeFromParams_1.getVideoStartTimeFromParams(videoPlayer === null || videoPlayer === void 0 ? void 0 : videoPlayer.duration)
            : undefined;
        if (!videoPlayer || !videoStartTime)
            return;
        videoPlayer.currentTime = videoStartTime ? videoStartTime / 1000 : 0;
        if (!videoPlayer.playing)
            videoPlayer.play();
    }, [pageState, videoPlayer]);
    /** 次へボタンを押したときの処理 */
    var handleClickNextButton = function () {
        if (state.type !== "showProduct" ||
            productContentsState.contentIds === undefined) {
            setPageState({ type: "error", message: "unexpected error" });
            return;
        }
        if (productContentsState.currentIdx === undefined) {
            setPageState({ type: "error", message: "unexpected error" });
            return;
        }
        var cursorPosition = calc_cursor_position_1.calcCursorPosition(productContentsState.currentIdx, productContentsState.contentIds.length);
        if (cursorPosition === "last") {
            if (state.config.needsFeedBack) {
                // feedback が必要なら confirm ページへ遷移
                updateRoute("confirm");
            }
            else {
                // feedback が不要ならこの画面からデータ送信
                setShouldSend(true);
            }
        }
        else {
            goToNextQuestion();
        }
    };
    // コンテンツを押した時の処理
    var handleClickContent = function (contentId) {
        if (state.type !== "showProduct" ||
            productContentsState.contentIds === undefined) {
            setPageState({ type: "error", message: "unexpected error" });
            return;
        }
        if (productContentsState.currentIdx === undefined) {
            setPageState({ type: "error", message: "unexpected error" });
            return;
        }
        jumpToQuestion(contentId);
        setContentDisplay(true);
    };
    //モバイルデバイスで一覧に戻るボタンを押す時の処理
    var handleBackToContentList = function () {
        setContentDisplay(false);
    };
    // プレイリストモードで視聴終了ボタンを押した時の処理
    var handleClickFinishButton = function () {
        if (state.type !== "showProduct" ||
            productContentsState.contentIds === undefined) {
            setPageState({ type: "error", message: "unexpected error" });
            return;
        }
        if (productContentsState.currentIdx === undefined) {
            setPageState({ type: "error", message: "unexpected error" });
            return;
        }
        if (state.config.needsFeedBack) {
            // feedback が必要なら confirm ページへ遷移
            updateRoute("confirm");
        }
        else {
            // feedback が不要ならこの画面からデータ送信
            setShouldSend(true);
        }
    };
    //チャプターを押す時の処理
    var handleChangeCurrentChapter = function (nextIndex) {
        if (pageState.type !== "loaded" ||
            pageState.content.contentType !== "videos" ||
            !videoPlayer)
            return;
        videoPlayer.pause();
        var targetTime = pageState.content.chapters
            ? pageState.content.chapters[nextIndex].position
            : 0;
        metadata &&
            logSequenceProxy.push(new (log_1.Log.bind.apply(log_1.Log, __spreadArray(__spreadArray([void 0], metadata), ["pause", videoPlayer.currentTime / 1000])))());
        videoPlayer.currentTime = targetTime / 1000;
        metadata &&
            logSequenceProxy.push(new (log_1.Log.bind.apply(log_1.Log, __spreadArray(__spreadArray([void 0], metadata), ["start", targetTime])))());
        if (!videoPlayer.playing)
            videoPlayer.play();
    };
    // Formを表示するフラグの監視
    hooks_1.useEffect(function () {
        if (state.type === "showProduct" &&
            state.config.needsForm &&
            !isFormSubmitted) {
            setShowForm(true);
        }
    }, [setShowForm, state, isFormSubmitted]);
    hooks_1.useEffect(function () {
        if (state.type !== "showProduct") {
            setPageState({ type: "error", message: "データーエラー" });
            return;
        }
        // すでに questionId があるとき(=confirm から戻ってきたとき)はinitは不要
        if (productContentsState.currentContentId !== undefined) {
            return;
        }
        initState(state.data);
    }, [initState, state, productContentsState.currentContentId]);
    // currentIdx が動いた時に呼ばれる処理
    hooks_1.useEffect(function () {
        if (state.type !== "showProduct") {
            setPageState({ type: "error", message: "データーエラー" });
            return;
        }
        if (productContentsState.currentIdx !== undefined) {
            var currentContent = state.data[productContentsState.currentIdx];
            var cursorPosition = calc_cursor_position_1.calcCursorPosition(productContentsState.currentIdx, productContentsState.contentIds.length);
            var endpoint = void 0;
            try {
                endpoint = src_1.getLoggerEndpoint();
            }
            catch (_a) {
                console.error("fail to load env");
                setPageState({
                    type: "error",
                    message: "データの取得に失敗しました。",
                });
                return;
            }
            setPageState({
                type: "loaded",
                content: currentContent,
                cursorPositon: {
                    allLength: productContentsState.contentIds.length,
                    currentIdx: productContentsState.currentIdx + const_1.INCREMENT_FOR_VIEW,
                    position: cursorPosition,
                },
                config: state.config,
                hearingSetId: state.hearingSetId,
                loggerEndpoint: endpoint,
                serviceResourceIdForLogger: state.config.serviceResourceIdForLogger,
                linkType: state.config.linkType,
                hashToken: userConfig.hashToken,
                showForm: showForm,
                setShowForm: setShowForm,
                contentList: state.data,
            });
        }
    }, [
        state,
        productContentsState.contentIds,
        productContentsState.currentIdx,
        userConfig.hashToken,
        showForm,
        setShowForm,
    ]);
    // 送信フラグが切り替わった時
    hooks_1.useEffect(function () {
        if (shouldSend) {
            if (!shouldSendLog) {
                updateRoute("thankyou");
            }
            else {
                setSendingState(true);
                repository_1.v1Repository
                    .crateProductContentResult(userConfig.hashToken, {
                    sessionId: userConfig.sessionId,
                    memo: "",
                })
                    .then(function () {
                    updateRoute("thankyou");
                })
                    .catch(function (e) {
                    console.error(e);
                    reportError_1.reportError(e);
                    showToast("送信に失敗しました。やり直してください。");
                })
                    .finally(function () {
                    setSendingState(false);
                });
            }
        }
    }, [
        shouldSendLog,
        shouldSend,
        showToast,
        updateRoute,
        userConfig.sessionId,
        userConfig.hashToken,
        isFormSubmitted,
    ]);
    return {
        pageState: pageState,
        backToPreviousQuestion: backToPreviousQuestion,
        handleClickNextButton: handleClickNextButton,
        handleClickContent: handleClickContent,
        handleClickFinishButton: handleClickFinishButton,
        sending: sending,
        handleChangeCurrentChapter: handleChangeCurrentChapter,
        setVideoPlayer: setVideoPlayer,
        isContentDisplay: isContentDisplay,
        handleBackToContentList: handleBackToContentList,
        setLogSequenceProxy: setLogSequenceProxy,
        setMetadata: setMetadata,
    };
};
exports.useShowProduct = useShowProduct;
