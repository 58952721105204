"use strict";
// Generated by ts-to-zod
Object.defineProperty(exports, "__esModule", { value: true });
exports.v1EmbeddingVideoSchema = void 0;
var zod_1 = require("zod");
var v1_content_for_embed_1 = require("./v1-content-for-embed");
exports.v1EmbeddingVideoSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content: v1_content_for_embed_1.v1ContentForEmbeddedSchema,
    show_playback_control: zod_1.z.boolean(),
    show_volume_control: zod_1.z.boolean(),
    show_center_playback_button: zod_1.z.boolean(),
    fullscreen: zod_1.z.boolean(),
    end_screen_type: zod_1.z.union([zod_1.z.literal("thumbnail"), zod_1.z.literal("loop")]),
    autoplay: zod_1.z.boolean(),
    muted: zod_1.z.boolean(),
    service_resource_id_for_logger: zod_1.z.string(),
    title: zod_1.z.string().optional(),
    public_description: zod_1.z.string().nullable().optional(),
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
});
