"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PdfStateContextProvider = exports.usePdfState = void 0;
var error_1 = require("@kaizen-dx-tools/error");
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var use_pdf_state_management_1 = require("../hooks/use-pdf-state-management");
var PdfStateContext = compat_1.createContext(undefined);
var usePdfState = function () {
    var state = compat_1.useContext(PdfStateContext);
    if (state === undefined)
        throw error_1.Errors.UNEXPECTED_VALUE;
    return state;
};
exports.usePdfState = usePdfState;
function PdfStateContextProvider(_a) {
    var children = _a.children;
    var state = use_pdf_state_management_1.usePdfStateManagement();
    return (preact_1.h(PdfStateContext.Provider, { value: state }, children));
}
exports.PdfStateContextProvider = PdfStateContextProvider;
