"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hearing = void 0;
var preact_1 = require("preact");
var src_1 = require("../../../../../lib/util/src");
var hearing_page_for_mobile_1 = require("./hearing-page-for-mobile");
var hearing_page_for_pc_1 = require("./hearing-page-for-pc");
var Hearing = function () {
    var mobile = src_1.isMobile();
    return mobile ? preact_1.h(hearing_page_for_mobile_1.HearingPageForMobile, null) : preact_1.h(hearing_page_for_pc_1.HearingPageForPC, null);
};
exports.Hearing = Hearing;
