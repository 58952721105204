"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserConfigContextProvider = exports.useUpdateUserConfig = exports.UpdateUserConfigContext = exports.useUserConfig = void 0;
var error_1 = require("@kaizen-dx-tools/error");
var util_1 = require("@kaizen-dx-tools/util");
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var uuid_1 = require("uuid");
var UserConfigContext = compat_1.createContext(undefined);
var useUserConfig = function () {
    var userConfig = compat_1.useContext(UserConfigContext);
    if (userConfig === undefined)
        throw error_1.Errors.UNEXPECTED_VALUE;
    return userConfig;
};
exports.useUserConfig = useUserConfig;
exports.UpdateUserConfigContext = compat_1.createContext(function () {
    // no op
});
var useUpdateUserConfig = function () { return compat_1.useContext(exports.UpdateUserConfigContext); };
exports.useUpdateUserConfig = useUpdateUserConfig;
function UserConfigContextProvider(_a) {
    var children = _a.children;
    var _b = compat_1.useState(""), sessionId = _b[0], setSessionId = _b[1];
    var token = util_1.parseHashToken();
    compat_1.useEffect(function () {
        if (!sessionId) {
            var sessionId_ = uuid_1.v4();
            setSessionId(sessionId_);
        }
    }, [sessionId]);
    return (preact_1.h(UserConfigContext.Provider, { value: { hashToken: token, sessionId: sessionId } }, children));
}
exports.UserConfigContextProvider = UserConfigContextProvider;
