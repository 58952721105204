"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useThankyouPage = void 0;
var util_1 = require("@kaizen-dx-tools/util");
var hooks_1 = require("preact/hooks");
var use_convert_data_model_1 = require("../use-convert-data-model");
var use_countdown_1 = require("../use-countdown");
var SECONDS_TO_REDIRECT = 5;
var useThankyouPage = function () {
    var _a = hooks_1.useState({ tag: "init" }), pageState = _a[0], setPageState = _a[1];
    var data = use_convert_data_model_1.useConvertDataModel();
    var count = use_countdown_1.useCountdown(SECONDS_TO_REDIRECT);
    hooks_1.useEffect(function () {
        if (data.type === "init")
            return;
        if (data.type !== "hearing" && data.type !== "showProduct") {
            setPageState({
                tag: "error",
                message: "redirect データが見つかりません",
            });
            return;
        }
        if (data.type === "hearing") {
            setPageState({ tag: "loaded", config: data.config, mode: "hearing" });
        }
        else if (data.type === "showProduct") {
            setPageState({ tag: "loaded", config: data.config, mode: "showProduct" });
        }
        else {
            try {
                util_1.assertNever(data);
            }
            catch (e) {
                setPageState({
                    tag: "error",
                    message: "正常でない操作を検知しました。",
                });
                return;
            }
        }
        var _a = data.config, cta = _a.cta, ctaAutoRedirect = _a.ctaAutoRedirect;
        if (ctaAutoRedirect) {
            if (!cta[0] || !cta[0].link_to) {
                setPageState({
                    tag: "error",
                    message: "redirect 先が設定されていません。",
                });
                return;
            }
            setTimeout(function () {
                window.location.href = cta[0].link_to;
            }, SECONDS_TO_REDIRECT * 1000);
        }
    }, [data]);
    return { pageState: pageState, count: count };
};
exports.useThankyouPage = useThankyouPage;
