"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileHeader = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var goober_1 = require("goober");
var preact_1 = require("preact");
var use_header_1 = require("../../hooks/pages/use-header");
var styles = {
    wrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n    height: 64px;\n    padding: 12px 24px;\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    height: 64px;\n    padding: 12px 24px;\n    color: ", ";\n  "])), const_1.COLOR.white, const_1.COLOR.default),
    innerWrapper: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    max-width: 1280px;\n    margin: 0 auto;\n    height: 100%;\n  "], ["\n    display: flex;\n    max-width: 1280px;\n    margin: 0 auto;\n    height: 100%;\n  "]))),
    logoWrapper: goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    min-width: 160px;\n    height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "], ["\n    min-width: 160px;\n    height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "]))),
    logo: goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 100%;\n    height: 100%;\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: 50% 50%;\n  "], ["\n    width: 100%;\n    height: 100%;\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: 50% 50%;\n  "]))),
    downloadPdfLink: goober_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    min-width: 132px;\n    margin-left: 16px;\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n  "], ["\n    min-width: 132px;\n    margin-left: 16px;\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n  "]))),
    downloadIcon: goober_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-right: 10px;\n  "], ["\n    margin-right: 10px;\n  "]))),
    downloadButton: goober_1.css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-size: 0.75rem;\n    padding: 8px 12px;\n    height: 32px;\n  "], ["\n    font-size: 0.75rem;\n    padding: 8px 12px;\n    height: 32px;\n  "]))),
};
var MobileHeader = function () {
    var headerState = use_header_1.useHeader().headerState;
    return headerState !== undefined ? (preact_1.h("div", { className: styles.wrapper },
        preact_1.h("div", { className: styles.innerWrapper },
            preact_1.h("div", { className: styles.logoWrapper },
                preact_1.h("span", { className: styles.logo, style: { backgroundImage: "url(" + headerState.orgLogo + ")" } }))))) : (preact_1.h("div", null));
};
exports.MobileHeader = MobileHeader;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
