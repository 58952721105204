"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAnswer = void 0;
var util_1 = require("@kaizen-dx-tools/util");
var hooks_1 = require("preact/hooks");
var useAnswer = function () {
    var _a = hooks_1.useReducer(reducer, initialState), answerState = _a[0], dispatch = _a[1];
    var answerQuestion = function (questionId, answer) {
        dispatch(saveNewAnswer(questionId, answer));
    };
    return { answerState: answerState, answerQuestion: answerQuestion };
};
exports.useAnswer = useAnswer;
var actionTypes = {
    ANSWER: "ANSWER/ANSWER",
    RECORD_CURRENT_ANSWER_CHOICE: "ANSWER/RECORD_CURRENT_ANSWER_CHOICE",
    RECORD_CURRENT_ANSWER_OTHER_TEXT: "ANSWER/RECORD_CURRENT_ANSWER_OTHER_TEXT",
};
var initialState = {
    currentUserAnswer: undefined,
    userAnswer: undefined,
};
var recordCurrentAnswer = function (answerId, type) {
    return {
        type: actionTypes.RECORD_CURRENT_ANSWER_CHOICE,
        payload: {
            answerId: answerId,
            type: type,
        },
    };
};
var recordCurrentOtherText = function (text) {
    return {
        type: actionTypes.RECORD_CURRENT_ANSWER_OTHER_TEXT,
        payload: text,
    };
};
var saveNewAnswer = function (questionId, answer) {
    return { type: actionTypes.ANSWER, payload: { questionId: questionId, answer: answer } };
};
var reducer = function (state, action) {
    switch (action.type) {
        case actionTypes.ANSWER: {
            var copiedAnswerMap = state.userAnswer !== undefined ? new Map(state.userAnswer) : new Map();
            copiedAnswerMap.set(action.payload.questionId, action.payload.answer);
            return __assign(__assign({}, state), { userAnswer: copiedAnswerMap });
        }
        case actionTypes.RECORD_CURRENT_ANSWER_CHOICE: {
            var input = action.payload;
            if (state.currentUserAnswer === undefined) {
                return __assign(__assign({}, state), { currentUserAnswer: {
                        answerIds: [],
                        otherAnswer: undefined,
                    } });
            }
            if (input.type === "check") {
                return __assign(__assign({}, state), { currentUserAnswer: __assign(__assign({}, state.currentUserAnswer), { answerIds: [] }) });
            }
            else if (input.type === "radio") {
            }
            else {
                util_1.assertNever(input.type);
            }
            return __assign({}, state);
        }
        default:
            return state;
    }
};
