"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConfirmPage = void 0;
var error_1 = require("@kaizen-dx-tools/error");
var repository_1 = require("@kaizen-dx-tools/repository");
var type_1 = require("@kaizen-dx-tools/type");
var hooks_1 = require("preact/hooks");
var answer_1 = require("../../module/answer");
var preview_1 = require("../../module/preview");
var routing_1 = require("../../module/routing");
var toast_1 = require("../../module/toast");
var user_config_1 = require("../../module/user-config");
var reportError_1 = require("../../utils/reportError");
var use_convert_data_model_1 = require("../use-convert-data-model");
var useConfirmPage = function () {
    var _a = hooks_1.useState(undefined), memo = _a[0], setMemo = _a[1];
    var _b = hooks_1.useState(false), sending = _b[0], setSendState = _b[1];
    var _c = hooks_1.useState({ tag: "init" }), pageState = _c[0], setPageState = _c[1];
    var userConfig = user_config_1.useUserConfig();
    var data = use_convert_data_model_1.useConvertDataModel();
    var answers = answer_1.useAnswerState();
    var showToast = toast_1.useSetToastMessage();
    var updateRoute = routing_1.useRoutingUpdate();
    var shouldSendLog = preview_1.usePreviewState().shouldSendLog;
    hooks_1.useEffect(function () {
        if (data.type === "hearing") {
            var questionMap_1 = data.data.questions;
            var contents_1 = data.data.contents;
            var choices_1 = data.data.choices;
            var questionSet = data.data.questionSet;
            var arr = void 0;
            try {
                arr = Array.from(questionSet.entries()).map(function (entry) {
                    var qsetId = entry[0];
                    var qids = entry[1];
                    var content = contents_1.get(qsetId);
                    var questions = qids.map(function (qid) {
                        var _a;
                        var question = questionMap_1.get(qid);
                        if (question === undefined) {
                            console.error("questionset に該当する question が見つかりません");
                            throw error_1.Errors.UNEXPECTED_VALUE;
                        }
                        var choice = choices_1.get(question.id);
                        var answer = (_a = answers.userAnswer) === null || _a === void 0 ? void 0 : _a.get(question.id);
                        // content はないことがありえるので、存在チェック不要
                        if (choice === undefined || answer === undefined) {
                            console.error("question: ", question);
                            throw error_1.Errors.UNEXPECTED_VALUE;
                        }
                        var answerChoices = answer.answerIds.map(function (cid) {
                            var matchedChoice = choice.find(function (c) { return c.id === cid; });
                            if (matchedChoice === undefined) {
                                console.error("answerChoices: ", answerChoices);
                                throw error_1.Errors.UNEXPECTED_VALUE;
                            }
                            return matchedChoice;
                        });
                        var questionAnswer = {
                            id: question.id,
                            title: question.body,
                            answers: answer.otherAnswer
                                ? __spreadArray(__spreadArray([], answerChoices), [
                                    {
                                        id: type_1.toChoiceID(-1),
                                        content: "[\u305D\u306E\u4ED6(\u81EA\u7531\u5165\u529B)] " + answer.otherAnswer,
                                        feedback: "",
                                    },
                                ]) : __spreadArray([], answerChoices),
                        };
                        return questionAnswer;
                    });
                    var composed = {
                        questionSetId: qsetId,
                        thumbnail: content === null || content === void 0 ? void 0 : content.thumbnail,
                        questions: questions,
                    };
                    return composed;
                });
            }
            catch (e) {
                setPageState({ tag: "error", message: "データが見つかりません" });
                console.error(e);
                return;
            }
            setPageState({
                tag: "hearing",
                data: arr,
                hearingSetTitle: data.data.hearingSet.title,
            });
        }
        else if (data.type === "showProduct") {
            setPageState({
                tag: "productContent",
                contents: data.data,
            });
        }
    }, [data, answers.userAnswer]);
    var handleInputMemo = function (e) {
        setMemo(e.target.value);
    };
    var handleClickSubmitButton = function () {
        if (!shouldSendLog) {
            updateRoute("thankyou");
            return;
        }
        setSendState(true);
        if (pageState.tag === "hearing") {
            if (answers.userAnswer === undefined) {
                setPageState({ tag: "error", message: "回答が必要です。" });
                return;
            }
            var body = {
                sessionId: userConfig.sessionId,
                memo: memo || "",
                questions: answers.userAnswer,
            };
            repository_1.v1Repository
                .createResult(userConfig.hashToken, body)
                .then(function () {
                updateRoute("thankyou");
            })
                .catch(function (e) {
                console.error(e);
                reportError_1.reportError("送信に失敗しました。");
                setPageState({ tag: "error", message: "送信に失敗しました。" });
            })
                .finally(function () {
                setSendState(false);
            });
        }
        else if (pageState.tag === "productContent") {
            var body = {
                sessionId: userConfig.sessionId,
                memo: memo || "",
            };
            repository_1.v1Repository
                .crateProductContentResult(userConfig.hashToken, body)
                .then(function () {
                updateRoute("thankyou");
            })
                .catch(function (e) {
                showToast("送信に失敗しました。");
                console.error(e);
                setPageState({ tag: "error", message: "送信に失敗しました。" });
            })
                .finally(function () {
                setSendState(false);
            });
        }
        else {
            setPageState({ tag: "error", message: "送信できる状態にありません。" });
        }
    };
    var handleClickBackButton = function () {
        updateRoute("hearing");
    };
    return {
        pageState: pageState,
        handleInputMemo: handleInputMemo,
        handleClickSubmitButton: handleClickSubmitButton,
        handleClickBackButton: handleClickBackButton,
        sending: sending,
    };
};
exports.useConfirmPage = useConfirmPage;
