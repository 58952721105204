"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCountdown = void 0;
var hooks_1 = require("preact/hooks");
var MILLI_SECOND = 1000;
var useCountdown = function (count) {
    var timer = hooks_1.useRef(null);
    var _a = hooks_1.useState(count), seconds = _a[0], setSeconds = _a[1];
    var decrement = hooks_1.useCallback(function () {
        if (seconds <= 0)
            return;
        setSeconds(function (val) { return val - 1; });
    }, [seconds]);
    hooks_1.useEffect(function () {
        // stop counting if count hits 0
        if (timer.current && seconds <= 0)
            clearInterval(timer.current);
    }, [seconds]);
    hooks_1.useEffect(function () {
        timer.current = setInterval(decrement, MILLI_SECOND);
        return function () {
            if (timer.current)
                clearInterval(timer.current);
        };
    }, [decrement]);
    return seconds;
};
exports.useCountdown = useCountdown;
