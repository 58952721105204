"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Creator = exports.ContentType = exports.toContentId = exports.ContentId = exports.toChoiceID = exports.ChoiceID = exports.toQuestionSetID = exports.QuestionSetID = exports.toQuestionID = exports.QuestionID = void 0;
var question_1 = require("./question");
Object.defineProperty(exports, "QuestionID", { enumerable: true, get: function () { return question_1.QuestionID; } });
Object.defineProperty(exports, "toQuestionID", { enumerable: true, get: function () { return question_1.toQuestionID; } });
var question_set_1 = require("./question-set");
Object.defineProperty(exports, "QuestionSetID", { enumerable: true, get: function () { return question_set_1.QuestionSetID; } });
Object.defineProperty(exports, "toQuestionSetID", { enumerable: true, get: function () { return question_set_1.toQuestionSetID; } });
var choice_1 = require("./choice");
Object.defineProperty(exports, "ChoiceID", { enumerable: true, get: function () { return choice_1.ChoiceID; } });
Object.defineProperty(exports, "toChoiceID", { enumerable: true, get: function () { return choice_1.toChoiceID; } });
var content_1 = require("./content");
Object.defineProperty(exports, "ContentId", { enumerable: true, get: function () { return content_1.ContentId; } });
Object.defineProperty(exports, "toContentId", { enumerable: true, get: function () { return content_1.toContentId; } });
Object.defineProperty(exports, "ContentType", { enumerable: true, get: function () { return content_1.ContentType; } });
var creator_1 = require("./creator");
Object.defineProperty(exports, "Creator", { enumerable: true, get: function () { return creator_1.Creator; } });
