"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.query = exports.BREAK_POINT = void 0;
exports.BREAK_POINT = {
    xs: "(max-width: 540px)",
    sm: "(max-width: 768px)",
    md: "(max-width: 1024px )",
};
function query(breakpoint) {
    var condition = exports.BREAK_POINT[breakpoint];
    var query = "@media screen and " + condition;
    return "" + query;
}
exports.query = query;
