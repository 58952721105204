"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviewContextProvider = exports.usePreviewState = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var repository_1 = require("@kaizen-dx-tools/repository");
var util_1 = require("@kaizen-dx-tools/util");
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var data_cache_1 = require("./data-cache");
var user_config_1 = require("./user-config");
var defaultPreviewContent = {
    shouldSendLog: false,
    showPopup: true,
};
var PreviewContext = compat_1.createContext(defaultPreviewContent);
var usePreviewState = function () {
    return compat_1.useContext(PreviewContext);
};
exports.usePreviewState = usePreviewState;
function PreviewContextProvider(_a) {
    var children = _a.children;
    var userConfig = user_config_1.useUserConfig();
    var dataCache = data_cache_1.useDataCache();
    var _b = compat_1.useState(__assign({}, defaultPreviewContent)), previewState = _b[0], setPreviewState = _b[1];
    var supportedOntouch = util_1.isSupportedOntouch();
    var createSessionVisits = compat_1.useCallback(function () {
        var body = {
            sessionId: userConfig.sessionId,
            hashToken: userConfig.hashToken,
            maLink: window.location.href,
            supportedOntouch: supportedOntouch,
        };
        repository_1.v1Repository.createSessionVisits(body).catch(function (e) {
            console.error(e);
        });
    }, [userConfig, supportedOntouch]);
    var getPreviewState = compat_1.useCallback(function () {
        var params = new URLSearchParams(window.location.search);
        if (!userConfig.sessionId || dataCache.state !== "success")
            return;
        var previewParam = parseInt(params.get(const_1.PREVIEW) || "0", 10);
        switch (previewParam) {
            case 0:
                //previewモードではない場合、ログを送信して、通知ポップアップを表示しません。
                setPreviewState({
                    shouldSendLog: true,
                    showPopup: false,
                });
                createSessionVisits();
                return;
            case 1:
                //previewの値は１の場合、ログを送信しないようにします。通知ポップアップを表示しません。
                setPreviewState({
                    shouldSendLog: false,
                    showPopup: true,
                });
                return;
            case 2:
                //previewの値は２の場合、ログの送信はしません。通知ポップアップも表示しません。
                setPreviewState({
                    shouldSendLog: false,
                    showPopup: false,
                });
                return;
            default:
                //previewの値は上記のケースに異なる場合、preview=1と同じい挙動にします。（ログを送信しません。ポップアップを表示します。）
                setPreviewState({
                    shouldSendLog: false,
                    showPopup: true,
                });
                return;
        }
    }, [createSessionVisits, userConfig.sessionId, dataCache.state]);
    compat_1.useEffect(function () {
        getPreviewState();
    }, [getPreviewState]);
    return (preact_1.h(PreviewContext.Provider, { value: previewState }, children));
}
exports.PreviewContextProvider = PreviewContextProvider;
