"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Root = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var query_1 = require("@kaizen-dx-tools/const/src/query");
var goober_1 = require("goober");
var preact_1 = require("preact");
var header_1 = require("../component/header");
var answer_1 = require("../module/answer");
var data_cache_1 = require("../module/data-cache");
var form_1 = require("../module/form");
var form_submit_status_1 = require("../module/form-submit-status");
var hearing_state_1 = require("../module/hearing-state");
var log_state_1 = require("../module/log-state");
var pdf_state_1 = require("../module/pdf-state");
var playlist_mode_1 = require("../module/playlist-mode");
var preview_1 = require("../module/preview");
var product_content_state_1 = require("../module/product-content-state");
var routing_1 = require("../module/routing");
var toast_1 = require("../module/toast");
var user_config_1 = require("../module/user-config");
var confirm_1 = require("./confirm");
var hearing_root_1 = require("./hearing-root");
var not_found_1 = require("./not-found");
var thankyou_1 = require("./thankyou");
var styles = {
    wrapper: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-left: auto;\n    margin-right: auto;\n    background-color: ", ";\n    min-height: 100vh;\n    font-family: \"Noto Sans JP\", sans-serif;\n  "], ["\n    margin-left: auto;\n    margin-right: auto;\n    background-color: ", ";\n    min-height: 100vh;\n    font-family: \"Noto Sans JP\", sans-serif;\n  "])), const_1.COLOR.lightGray),
};
var Root = function () {
    var route = routing_1.useRouting();
    // ページヘッダの表示/非表示を決定します
    var params = new URLSearchParams(window.location.search);
    var hideHeader = params.has(query_1.HEADER) && params.get(query_1.HEADER) === "0";
    var header = hideHeader ? null : preact_1.h(header_1.Header, null);
    return (preact_1.h(toast_1.ToastContextProvider, null,
        preact_1.h(user_config_1.UserConfigContextProvider, null,
            preact_1.h(answer_1.AnswerContextProvider, null,
                preact_1.h(data_cache_1.DataCacheContextProvider, null,
                    preact_1.h(form_1.FormContextProvider, null,
                        preact_1.h(form_submit_status_1.FormSubmitStatusContextProvider, null,
                            preact_1.h(log_state_1.LogStateContextProvider, null,
                                preact_1.h(hearing_state_1.HearingStateContextProvider, null,
                                    preact_1.h(product_content_state_1.ProductContentContextProvider, null,
                                        preact_1.h(preview_1.PreviewContextProvider, null,
                                            preact_1.h(pdf_state_1.PdfStateContextProvider, null,
                                                preact_1.h(playlist_mode_1.PlaylistModeContextProvider, null,
                                                    preact_1.h("div", { className: styles.wrapper },
                                                        header,
                                                        preact_1.h(not_found_1.NotFound, null),
                                                        route === "init" ? (preact_1.h("div", null)) : route === "hearing" ? (preact_1.h(hearing_root_1.HearingRoot, null)) : route === "confirm" ? (preact_1.h(confirm_1.Confirm, null)) : route === "thankyou" ? (preact_1.h(thankyou_1.Thankyou, null)) : (preact_1.h("div", null, "ERROR: not match path state"))))))))))))))));
};
exports.Root = Root;
var templateObject_1;
