"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HearingStateContextProvider = exports.useUpdateHearingState = exports.UpdateHearingStateContext = exports.useHearingState = void 0;
var error_1 = require("@kaizen-dx-tools/error");
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var use_hearing_state_management_1 = require("../hooks/use-hearing-state-management");
var HearingStateContext = compat_1.createContext(undefined);
var useHearingState = function () {
    var state = compat_1.useContext(HearingStateContext);
    if (state === undefined)
        throw error_1.Errors.UNEXPECTED_VALUE;
    return state;
};
exports.useHearingState = useHearingState;
exports.UpdateHearingStateContext = compat_1.createContext(function () {
    // no op
});
var useUpdateHearingState = function () {
    return compat_1.useContext(exports.UpdateHearingStateContext);
};
exports.useUpdateHearingState = useUpdateHearingState;
function HearingStateContextProvider(_a) {
    var children = _a.children;
    var state = use_hearing_state_management_1.useHearingStateManagement();
    return (preact_1.h(HearingStateContext.Provider, { value: state }, children));
}
exports.HearingStateContextProvider = HearingStateContextProvider;
