"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var goober_1 = require("goober");
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var src_1 = require("../../util/src");
var mobile = src_1.isMobile();
var Component = compat_1.forwardRef(function (props, ref) {
    return preact_1.h("input", __assign({ ref: ref }, props));
});
var StyledComponent = goober_1.styled(Component, compat_1.forwardRef)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 14px;\n  text-align: left;\n  width: 100%;\n  opacity: 1;\n  padding: 12px 10px;\n  border: 1px solid rgb(234, 234, 234);\n  border-radius: 6px;\n  ", ";\n  &:focus {\n    border-color: ", ";\n  }\n  &::placeholder {\n    color: ", ";\n    -webkit-text-fill-color: ", ";\n  }\n"], ["\n  font-size: 14px;\n  text-align: left;\n  width: 100%;\n  opacity: 1;\n  padding: 12px 10px;\n  border: 1px solid rgb(234, 234, 234);\n  border-radius: 6px;\n  ", ";\n  &:focus {\n    border-color: ", ";\n  }\n  &::placeholder {\n    color: ", ";\n    -webkit-text-fill-color: ", ";\n  }\n"])), mobile ? "font-size: 1rem !important" : "", const_1.COLOR.greenPrimary, const_1.COLOR.grayMedium4, const_1.COLOR.grayMedium4);
var Container = compat_1.forwardRef(function (props, ref) {
    return preact_1.h(StyledComponent, __assign({}, props, { ref: ref }));
});
exports.Input = Container;
var templateObject_1;
