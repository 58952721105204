"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NullQuestion = void 0;
exports.NullQuestion = {
    id: 0,
    isMultipleChoice: false,
    hasOther: false,
    body: "",
    choiceIds: [],
    questionSetId: 0,
    serviceResourceIdForLogger: "",
};
