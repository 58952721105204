"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v1EmbedPlaylistContentSchema = void 0;
var zod_1 = require("zod");
var v1_product_content_for_embed_1 = require("../partial/v1-product-content-for-embed");
exports.v1EmbedPlaylistContentSchema = zod_1.z.object({
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
    id: zod_1.z.number(),
    title: zod_1.z.string(),
    status: zod_1.z.union([
        zod_1.z.literal("draft"),
        zod_1.z.literal("published"),
        zod_1.z.literal("archived"),
    ]),
    description: zod_1.z.string().nullable().optional(),
    thumbnail_url: zod_1.z.string().nullable(),
    product_contents_count: zod_1.z.number(),
    product_contents: zod_1.z.array(v1_product_content_for_embed_1.v1ProductContentSchema),
});
