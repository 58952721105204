"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.detectIEUser = void 0;
var detectIEUser = function () {
    var userAgent = window.navigator.userAgent.toUpperCase();
    if (userAgent.indexOf("MSIE") === -1 && userAgent.indexOf("TRIDENT") === -1) {
        return false;
    }
    return true;
};
exports.detectIEUser = detectIEUser;
