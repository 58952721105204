"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioButton = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var goober_1 = require("goober");
var preact_1 = require("preact");
var Component = function (props) {
    return (preact_1.h("input", { type: "radio", name: props.name, onChange: props.onChange, id: props.id, value: props.value, className: props.className + " " + const_1.RADIO_CHOICE_CLASSNAME + " " + const_1.CHOCIE_PREFIX + "-" + props.id, checked: props.checked }));
};
var StyledComponent = goober_1.styled(Component)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  -ms-appearance: none;\n  -o-appearance: none;\n  appearance: none;\n  position: relative;\n  height: 24px;\n  min-width: 24px;\n  cursor: pointer;\n  border-radius: 50%;\n  border: 1px solid ", ";\n  &::before,\n  &::after {\n    position: absolute;\n    content: \"\";\n  }\n  &:checked {\n    background: ", ";\n    border: 1px solid ", ";\n    &::before {\n      position: absolute;\n      top: 6px;\n      left: 6px;\n      width: 10px;\n      height: 10px;\n      background: ", ";\n      border-radius: 50%;\n    }\n  }\n"], ["\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  -ms-appearance: none;\n  -o-appearance: none;\n  appearance: none;\n  position: relative;\n  height: 24px;\n  min-width: 24px;\n  cursor: pointer;\n  border-radius: 50%;\n  border: 1px solid ", ";\n  &::before,\n  &::after {\n    position: absolute;\n    content: \"\";\n  }\n  &:checked {\n    background: ", ";\n    border: 1px solid ", ";\n    &::before {\n      position: absolute;\n      top: 6px;\n      left: 6px;\n      width: 10px;\n      height: 10px;\n      background: ", ";\n      border-radius: 50%;\n    }\n  }\n"])), const_1.COLOR.grayMedium4, const_1.COLOR.normal, const_1.COLOR.normal, const_1.COLOR.white);
var ContainerComponent = StyledComponent;
exports.RadioButton = ContainerComponent;
var templateObject_1;
