"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBlobDowloader = void 0;
var hooks_1 = require("preact/hooks");
var detect_ie_user_1 = require("../util/detect-ie-user");
var toast_1 = require("./../module/toast");
var useBlobDowloader = function () {
    var _a = hooks_1.useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var showToast = toast_1.useSetToastMessage();
    var isIE = detect_ie_user_1.detectIEUser();
    var request = function (url, fileName) {
        setIsLoading(true);
        if (isIE) {
            var link = document.createElement("a");
            link.href = url;
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noreferrer");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsLoading(false);
            return;
        }
        fetch(url)
            .then(function (response) { return response.blob(); })
            .then(function (blob) {
            var blob_ = new Blob([blob], { type: "application/octet-stream" });
            var blobURL = URL.createObjectURL(blob_);
            var link = document.createElement("a");
            link.href = blobURL;
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noreferrer");
            link.setAttribute("download", createFileName(blob.type, fileName));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
            .catch(function (error) {
            console.error(error);
            showToast("ファイルをダウンロードできませんでした。");
        })
            .finally(function () {
            setIsLoading(false);
        });
    };
    var createFileName = hooks_1.useCallback(function (responseDataType, fileName) {
        var extension = responseDataType.split("/")[1];
        return fileName !== "" && fileName !== undefined
            ? fileName + "." + extension
            : "";
    }, []);
    var reset = hooks_1.useCallback(function () {
        setIsLoading(false);
    }, []);
    return {
        isLoading: isLoading,
        request: request,
        reset: reset,
    };
};
exports.useBlobDowloader = useBlobDowloader;
