"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentViewingLogsSender = void 0;
var repository_1 = require("@kaizen-dx-tools/repository");
var hooks_1 = require("preact/hooks");
var use_hearing_1 = require("../../../hooks/pages/use-hearing");
var use_show_product_1 = require("../../../hooks/pages/use-show-product");
var pdf_state_1 = require("../../../module/pdf-state");
var preview_1 = require("../../../module/preview");
var user_config_1 = require("../../../module/user-config");
var DocumentViewingLogsSender = function () {
    var _a = user_config_1.useUserConfig(), hashToken = _a.hashToken, sessionId = _a.sessionId;
    var currentPage = pdf_state_1.usePdfState().currentPage;
    var hearingState = use_hearing_1.useHearing().pageState;
    var showProductState = use_show_product_1.useShowProduct().pageState;
    var shouldSendLog = preview_1.usePreviewState().shouldSendLog;
    var timeStamp = hooks_1.useRef(Date.now());
    var previousePage = hooks_1.useRef(1);
    var _b = hooks_1.useState(false), isFirstSend = _b[0], setIsFirstSend = _b[1];
    var _c = hooks_1.useState(false), isBlur = _c[0], setIsBlur = _c[1];
    var sendLog = hooks_1.useCallback(function () {
        var now = Date.now();
        var duration = !isFirstSend ? 0 : now - timeStamp.current;
        var page = previousePage.current;
        if ((0 < duration && duration < 1000) || !shouldSendLog)
            return;
        if (showProductState.type === "loaded") {
            repository_1.v1Repository.sendDocumentViewingLogs(hashToken, {
                sessionId: sessionId,
                questionSetId: null,
                contentId: showProductState.content.contentId,
                page: page,
                duration: duration,
            });
            timeStamp.current = now;
        }
        if (hearingState.type === "loaded" && hearingState.current.content) {
            repository_1.v1Repository.sendDocumentViewingLogs(hashToken, {
                sessionId: sessionId,
                questionSetId: hearingState.current.question.questionSetId,
                contentId: hearingState.current.content.id,
                page: page,
                duration: duration,
            });
            timeStamp.current = now;
        }
    }, [
        showProductState,
        sessionId,
        hashToken,
        hearingState,
        isFirstSend,
        shouldSendLog,
    ]);
    // 10秒ごとに送信
    hooks_1.useEffect(function () {
        if (isBlur)
            return;
        var interval = setInterval(function () {
            sendLog();
        }, 10 * 1000);
        return function () {
            clearInterval(interval);
        };
    }, [currentPage, sendLog, isBlur]);
    // コンテンツ切り替え時、視聴終了ボタン押した時の処理
    hooks_1.useEffect(function () {
        var prv = previousePage;
        var cur = timeStamp;
        var _hearingState = hearingState;
        return function () {
            if (!isFirstSend)
                return;
            var now = Date.now();
            var duration = !isFirstSend ? 0 : now - cur.current;
            var page = prv.current;
            if ((0 < duration && duration < 1000) || !shouldSendLog)
                return;
            if (showProductState.type === "loaded") {
                repository_1.v1Repository.sendDocumentViewingLogs(hashToken, {
                    sessionId: sessionId,
                    questionSetId: null,
                    contentId: showProductState.content.contentId,
                    page: page,
                    duration: duration,
                });
                setIsFirstSend(false);
                timeStamp.current = now;
            }
            if (_hearingState.type === "loaded" && _hearingState.current.content) {
                repository_1.v1Repository.sendDocumentViewingLogs(hashToken, {
                    sessionId: sessionId,
                    questionSetId: _hearingState.current.question.questionSetId,
                    contentId: _hearingState.current.content.id,
                    page: page,
                    duration: duration,
                });
                setIsFirstSend(false);
                timeStamp.current = now;
            }
        };
    }, [
        showProductState,
        hearingState,
        sessionId,
        hashToken,
        isFirstSend,
        shouldSendLog,
    ]);
    hooks_1.useEffect(function () {
        // コンテンツが切り替わった時にログ送信
        sendLog();
        setIsFirstSend(true);
    }, [sendLog]);
    hooks_1.useEffect(function () {
        // ページを変更した時の処理
        if (!isFirstSend)
            return;
        sendLog();
        previousePage.current = currentPage;
    }, [currentPage, sendLog, isFirstSend]);
    hooks_1.useEffect(function () {
        var sendLogsWhenBlur = function () {
            sendLog();
            setIsBlur(true);
        };
        var setTimeStampWhenFocus = function () {
            setIsBlur(false);
        };
        window.addEventListener("blur", sendLogsWhenBlur);
        window.addEventListener("focus", setTimeStampWhenFocus);
        return function () {
            window.removeEventListener("blur", sendLogsWhenBlur);
            window.removeEventListener("focus", setTimeStampWhenFocus);
        };
    }, [sendLog]);
    return null;
};
exports.DocumentViewingLogsSender = DocumentViewingLogsSender;
