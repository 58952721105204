"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.KaizenPreactVideoPlayer = void 0;
var kaizen_video_player_1 = require("@kaizenplatform/kaizen-video-player");
var preact_1 = require("preact");
var src_1 = require("../../util/src");
var KaizenPreactVideoPlayer = /** @class */ (function (_super) {
    __extends(KaizenPreactVideoPlayer, _super);
    function KaizenPreactVideoPlayer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.player = null;
        _this.ref = preact_1.createRef();
        _this.state = { shouldPause: false };
        _this.iOSVer = src_1.detectIOSVersion();
        return _this;
    }
    KaizenPreactVideoPlayer.prototype.componentDidMount = function () {
        if (!this.player && this.ref.current) {
            var container = this.ref.current;
            this.player = new kaizen_video_player_1.Player(container, this.props);
            this.player.start();
            // Fix svg icons are not displayed on iOS < 12 when using <use> tag with svg sprite.
            // Show icons by copying the whole svg icon and attaching them to plyr__control button
            if (this.iOSVer > -1 && this.iOSVer < 12) {
                var root = this.player.root;
                root && src_1.replaceUseTag(root);
            }
        }
    };
    KaizenPreactVideoPlayer.prototype.componentWillUnmount = function () {
        if (this.player) {
            this.player.destroy();
            this.player = null;
        }
    };
    KaizenPreactVideoPlayer.prototype.componentDidUpdate = function (prevProps) {
        if (!this.player)
            return;
        if (prevProps.shouldPause !== this.props.shouldPause) {
            this.player.pause();
        }
        if (prevProps.videoId !== this.props.videoId && this.props.videoId) {
            this.player.changeVideo(this.props);
        }
    };
    KaizenPreactVideoPlayer.prototype.render = function () {
        return (preact_1.h("div", { id: this.props.kzHearingToken !== "" &&
                this.props.kzHearingToken !== undefined
                ? "preact-kaizen-player-" + this.props.kzHearingToken
                : "preact-kaizen-player", ref: this.ref, className: "preact-kaizen-player", style: {
                width: "100%",
                height: "100%",
            } }));
    };
    return KaizenPreactVideoPlayer;
}(preact_1.Component));
exports.KaizenPreactVideoPlayer = KaizenPreactVideoPlayer;
