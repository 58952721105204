"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetComponentSize = void 0;
var hooks_1 = require("preact/hooks");
var useGetComponentSize = function (ref) {
    var _a = hooks_1.useState({
        width: 0,
        height: 0,
    }), componentSize = _a[0], setComponentSize = _a[1];
    hooks_1.useEffect(function () {
        var localRef = null;
        if (ref === null || ref === void 0 ? void 0 : ref.current)
            localRef = ref.current;
        setComponentSize({
            width: (localRef === null || localRef === void 0 ? void 0 : localRef.offsetWidth) || 0,
            height: (localRef === null || localRef === void 0 ? void 0 : localRef.offsetHeight) || 0,
        });
        window.addEventListener("resize", function (_e) {
            return setComponentSize({
                width: (localRef === null || localRef === void 0 ? void 0 : localRef.offsetWidth) || 0,
                height: (localRef === null || localRef === void 0 ? void 0 : localRef.offsetHeight) || 0,
            });
        });
        return function () {
            return window.removeEventListener("resize", function (_e) {
                return setComponentSize({
                    width: (localRef === null || localRef === void 0 ? void 0 : localRef.offsetWidth) || 0,
                    height: (localRef === null || localRef === void 0 ? void 0 : localRef.offsetHeight) || 0,
                });
            });
        };
    }, [ref]);
    return componentSize;
};
exports.useGetComponentSize = useGetComponentSize;
