"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEntryForm = void 0;
var error_1 = require("@kaizen-dx-tools/error");
var repository_1 = require("@kaizen-dx-tools/repository");
var hooks_1 = require("preact/hooks");
var index_ie11_1 = require("react-hook-form/dist/index.ie11");
var form_1 = require("../../module/form");
var form_submit_status_1 = require("../../module/form-submit-status");
var preview_1 = require("../../module/preview");
var toast_1 = require("../../module/toast");
var user_config_1 = require("../../module/user-config");
var use_convert_data_model_1 = require("../use-convert-data-model");
var useEntryForm = function () {
    var state = use_convert_data_model_1.useConvertDataModel();
    var setFormSubmitted = form_submit_status_1.useUpdateFormSubmitStatus();
    var setShowForm = form_1.useUpdateForm();
    var showToast = toast_1.useSetToastMessage();
    var userConfig = user_config_1.useUserConfig();
    var _a = preview_1.usePreviewState(), shouldSendLog = _a.shouldSendLog, showPopup = _a.showPopup;
    var _b = index_ie11_1.useForm({
        mode: "onChange",
    }), register = _b.register, formState = _b.formState, handleSubmit = _b.handleSubmit;
    var _c = hooks_1.useState({
        tag: "inti",
    }), pageState = _c[0], setPageState = _c[1];
    // application による API のデータ取得を監視
    hooks_1.useEffect(function () {
        if (state.type === "hearing" || state.type === "showProduct") {
            setPageState({ tag: "loaded", data: state.config });
        }
        else {
            setPageState({ tag: "error", message: "不正な状態を検知しました" });
        }
    }, [state]);
    var onSubmit = function (data) {
        if (!shouldSendLog) {
            setShowForm(false);
            setFormSubmitted(true);
            return;
        }
        var body = {
            sessionId: userConfig.sessionId,
            hashToken: userConfig.hashToken,
            companyName: data.companyName || "",
            companyPicName: data.name || "",
            email: data.email || "",
            phoneNumber: data.phone || "",
        };
        repository_1.v1Repository
            .createSessionForms(body)
            .then(function () {
            setShowForm(false);
            setFormSubmitted(true);
        })
            .catch(function (e) {
            handleCreateFormError(e);
            console.error(e);
        });
    };
    var onError = function (data) {
        showToast("フォームの入力を完了してください。");
    };
    var handleCreateFormError = function (e) {
        if (e === error_1.Errors.CLIENT_ERROR) {
            showToast("フォーム内容の送信エラーです。お手数ですが、リロードして入力し直して下さい。");
        }
        else {
            showToast("サーバーが応答しませんでした。お手数ですが、数分後にもう一度試して下さい。");
        }
    };
    return {
        handleSubmit: handleSubmit,
        onSubmit: onSubmit,
        pageState: pageState,
        register: register,
        formState: formState,
        onError: onError,
    };
};
exports.useEntryForm = useEntryForm;
