"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductContentContextProvider = exports.useProductContentState = void 0;
var error_1 = require("@kaizen-dx-tools/error");
var preact_1 = require("preact");
var compat_1 = require("preact/compat");
var use_show_product_state_management_1 = require("../hooks/use-show-product-state-management");
var ProductContentContext = compat_1.createContext(undefined);
var useProductContentState = function () {
    var state = compat_1.useContext(ProductContentContext);
    if (state === undefined)
        throw error_1.Errors.UNEXPECTED_VALUE;
    return state;
};
exports.useProductContentState = useProductContentState;
function ProductContentContextProvider(_a) {
    var children = _a.children;
    var state = use_show_product_state_management_1.useShowProductStateManagement();
    return (preact_1.h(ProductContentContext.Provider, { value: state }, children));
}
exports.ProductContentContextProvider = ProductContentContextProvider;
