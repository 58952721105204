"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseHashToken = void 0;
var error_1 = require("@kaizen-dx-tools/error");
var parseHashToken = function () {
    var _a = window.location, pathname = _a.pathname, search = _a.search, href = _a.href;
    // /hearings/:hearing_hash_token の形で抜き出す
    var reg = new RegExp(/\/hearings\/[0-9a-zA-Z]+/);
    var regResult = pathname.match(reg);
    var pathname_;
    if (regResult) {
        pathname_ = regResult[0];
    }
    else {
        console.error("不正なURLです");
        throw error_1.Errors.NOT_FOUND_KAIZEN_HEARING_TOKEN;
    }
    var hearingId = pathname_.replace("/hearings/", "");
    var parsedPathname = window.location.origin + "/hearings/" + hearingId + search;
    if (parsedPathname != href) {
        window.history.replaceState("", "", parsedPathname);
    }
    return hearingId;
};
exports.parseHashToken = parseHashToken;
