"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createButtonStyle = void 0;
var const_1 = require("@kaizen-dx-tools/const");
var error_1 = require("@kaizen-dx-tools/error");
var util_1 = require("@kaizen-dx-tools/util");
var createButtonStyle = function (skin) {
    switch (skin) {
        case "default":
            return {
                "background-color": const_1.COLOR.normal,
                color: const_1.COLOR.white,
                "&:hover": {
                    "background-color": const_1.COLOR.darkPrimary,
                },
            };
        case "primary":
            return {
                "background-color": const_1.COLOR.greenPrimary,
                color: const_1.COLOR.white,
                "&:hover": {
                    "background-color": const_1.COLOR.greenSecondary,
                },
            };
        case "outlined":
            return {
                backgroundColor: const_1.COLOR.white,
                color: const_1.COLOR.inputDefault,
                border: "1px solid " + const_1.COLOR.grayMedium,
                "&:hover": {
                    "background-color": const_1.COLOR.grayMedium3,
                    border: "1px solid " + const_1.COLOR.gray,
                },
            };
        case "disabled":
            return {
                "background-color": const_1.COLOR.gray,
                color: const_1.COLOR.white,
            };
        default: {
            util_1.assertNever(skin);
            throw error_1.Errors.UNREACHABLE_LINE;
        }
    }
};
exports.createButtonStyle = createButtonStyle;
