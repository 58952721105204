"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShowProductStateManagement = void 0;
var hooks_1 = require("preact/hooks");
var useShowProductStateManagement = function () {
    var _a = hooks_1.useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    var initState = hooks_1.useCallback(function (contents) {
        var contentIds = contents.map(function (c) { return c.id; });
        dispatch(initialize({ contentIds: contentIds }));
    }, []);
    var goToNextQuestion = hooks_1.useCallback(function () {
        dispatch(nextContent());
    }, []);
    var backToPreviousQuestion = hooks_1.useCallback(function () {
        dispatch(previousContent());
    }, []);
    var jumpToQuestion = hooks_1.useCallback(function (contentId) {
        dispatch(jumpContent(contentId));
    }, []);
    return {
        productContentsState: state,
        initState: initState,
        goToNextQuestion: goToNextQuestion,
        backToPreviousQuestion: backToPreviousQuestion,
        jumpToQuestion: jumpToQuestion,
    };
};
exports.useShowProductStateManagement = useShowProductStateManagement;
var initialState = {
    currentIdx: undefined,
    contentIds: undefined,
    currentContentId: undefined,
};
var actionTypes = {
    INIT: "APP/INIT",
    NEXT_CONTENT: "APP/NEXT_CONTENT",
    BACK_CONTENT: "APP/BACK_CONTENT",
    JUMP_CONTENT: "APP/JUMP_CONTENT",
};
var initialize = function (data) {
    return { type: actionTypes.INIT, payload: data.contentIds };
};
var nextContent = function () {
    return { type: actionTypes.NEXT_CONTENT };
};
var previousContent = function () {
    return { type: actionTypes.BACK_CONTENT };
};
var jumpContent = function (contentId) {
    return { type: actionTypes.JUMP_CONTENT, payload: contentId };
};
var reducer = function (state, action) {
    switch (action.type) {
        case actionTypes.INIT:
            return __assign(__assign({}, state), { currentContentId: action.payload[0], contentIds: action.payload, currentIdx: 0 });
        case actionTypes.NEXT_CONTENT: {
            if (state.currentContentId === undefined) {
                console.error("should init before click");
                return __assign({}, state);
            }
            var nextIdx = state.currentIdx + 1;
            return __assign(__assign({}, state), { currentIdx: nextIdx, currentContentId: state.contentIds[nextIdx] });
        }
        case actionTypes.BACK_CONTENT: {
            if (state.currentContentId === undefined) {
                console.error("should init before click");
                return __assign({}, state);
            }
            if (state.currentIdx === 0) {
                console.error("should not call backing");
                return __assign({}, state);
            }
            var prevIdx = state.currentIdx - 1;
            return __assign(__assign({}, state), { currentIdx: prevIdx, currentContentId: state.contentIds[prevIdx] });
        }
        case actionTypes.JUMP_CONTENT: {
            if (state.currentContentId === undefined) {
                console.error("should init before click");
                return __assign({}, state);
            }
            var state_ = __assign({}, state);
            var jumpIndex = state_.contentIds.findIndex(function (c) { return c === action.payload; });
            if (jumpIndex === state_.currentIdx) {
                return __assign({}, state);
            }
            return __assign(__assign({}, state), { currentIdx: jumpIndex, currentContentId: action.payload });
        }
        default:
            return state;
    }
};
