"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDuration = void 0;
var formatDuration = function (miliseconds, displayHour) {
    var durationInSeconds = Math.floor(miliseconds / 1000);
    var hours = Math.floor(durationInSeconds / 60 / 60);
    var minutes = Math.floor((durationInSeconds - hours * 60 * 60) / 60);
    var seconds = durationInSeconds - hours * 60 * 60 - minutes * 60;
    var hourStr = hours < 10 ? "0" + hours : hours.toString();
    var minuteStr = minutes < 10 ? "0" + minutes : minutes.toString();
    var secondStr = seconds < 10 ? "0" + seconds : seconds.toString();
    return displayHour
        ? hourStr + ":" + minuteStr + ":" + secondStr
        : minuteStr + ":" + secondStr;
};
exports.formatDuration = formatDuration;
