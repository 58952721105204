"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRoot = void 0;
var hooks_1 = require("preact/hooks");
var useRoot = function () {
    var _a = hooks_1.useState("init"), page = _a[0], setPageRoute = _a[1];
    var changeRoute = hooks_1.useCallback(function (route) {
        setPageRoute(route);
    }, []);
    return { page: page, changeRoute: changeRoute };
};
exports.useRoot = useRoot;
