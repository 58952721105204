"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntryForm = void 0;
var component_1 = require("@kaizen-dx-tools/component");
var const_1 = require("@kaizen-dx-tools/const");
var goober_1 = require("goober");
var preact_1 = require("preact");
var use_entry_form_page_1 = require("../hooks/pages/use-entry-form-page");
var styles = {
    bg: goober_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: fixed;\n    left: 0;\n    top: 0;\n    background-color: ", ";\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100vw;\n    height: 100vh;\n  "], ["\n    position: fixed;\n    left: 0;\n    top: 0;\n    background-color: ", ";\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100vw;\n    height: 100vh;\n  "])), const_1.COLOR.default),
    content: goober_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: relative;\n    border-radius: 6px;\n    padding: 40px 30px 50px;\n    width: 550px;\n    background-color: ", ";\n    text-align: center;\n  "], ["\n    position: relative;\n    border-radius: 6px;\n    padding: 40px 30px 50px;\n    width: 550px;\n    background-color: ", ";\n    text-align: center;\n  "])), const_1.COLOR.white),
    icon: goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: absolute;\n    top: 0;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background: ", ";\n    border: 5px solid ", ";\n    width: 82px;\n    height: 82px;\n    border-radius: 50%;\n    margin-bottom: 24px;\n  "], ["\n    position: absolute;\n    top: 0;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background: ", ";\n    border: 5px solid ", ";\n    width: 82px;\n    height: 82px;\n    border-radius: 50%;\n    margin-bottom: 24px;\n  "])), const_1.COLOR.white, const_1.COLOR.white),
    input: goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 14px;\n    text-align: left;\n    width: 100%;\n    opacity: 1;\n    padding: 12px 10px;\n    border: 1px solid rgb(234, 234, 234);\n    border-radius: 6px;\n    &:focus {\n      border-color: ", ";\n    }\n    &[data-error=\"true\"] {\n      border-color: #e00;\n      opacity: 0.8;\n    }\n  "], ["\n    font-size: 14px;\n    text-align: left;\n    width: 100%;\n    opacity: 1;\n    padding: 12px 10px;\n    border: 1px solid rgb(234, 234, 234);\n    border-radius: 6px;\n    &:focus {\n      border-color: ", ";\n    }\n    &[data-error=\"true\"] {\n      border-color: #e00;\n      opacity: 0.8;\n    }\n  "])), const_1.COLOR.greenPrimary),
    text: goober_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    margin-bottom: 20px;\n    color: ", ";\n    font-size: 14px;\n    line-height: 18px;\n  "], ["\n    margin-bottom: 20px;\n    color: ", ";\n    font-size: 14px;\n    line-height: 18px;\n  "])), const_1.COLOR.darkMedium),
    firstLine: goober_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-top: 20px;\n    margin-bottom: 30px;\n  "], ["\n    margin-top: 20px;\n    margin-bottom: 30px;\n  "]))),
    label: goober_1.css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: block;\n    font-size: 14px;\n    text-align: left;\n    color: ", ";\n    margin-bottom: 8px;\n    &::after {\n      color: #e00;\n      content: \" *\";\n      opacity: 0.8;\n    }\n  "], ["\n    display: block;\n    font-size: 14px;\n    text-align: left;\n    color: ", ";\n    margin-bottom: 8px;\n    &::after {\n      color: #e00;\n      content: \" *\";\n      opacity: 0.8;\n    }\n  "])), const_1.COLOR.darkGray),
    button: goober_1.css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    display: block;\n    margin: 0 auto;\n    margin-top: 20px;\n  "], ["\n    display: block;\n    margin: 0 auto;\n    margin-top: 20px;\n  "]))),
    mb10: goober_1.css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    margin-bottom: 10px;\n  "], ["\n    margin-bottom: 10px;\n  "]))),
    errorMessage: goober_1.css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    margin-top: 4px;\n    text-align: left;\n    font-size: 14px;\n    color: #e00;\n    opacity: 0.8;\n  "], ["\n    margin-top: 4px;\n    text-align: left;\n    font-size: 14px;\n    color: #e00;\n    opacity: 0.8;\n  "]))),
};
var EntryForm = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = use_entry_form_page_1.useEntryForm(), pageState = _j.pageState, register = _j.register, onSubmit = _j.onSubmit, formState = _j.formState, handleSubmit = _j.handleSubmit, onError = _j.onError;
    return (preact_1.h("div", { className: styles.bg }, pageState.tag === "loaded" ? (preact_1.h("div", { className: styles.content },
        preact_1.h("p", { className: styles.text }, "\u30B3\u30F3\u30C6\u30F3\u30C4\u3092\u95B2\u89A7\u3059\u308B\u305F\u3081\u306B\u3001\u4EE5\u4E0B\u306E\u30D5\u30A9\u30FC\u30E0\u306B\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"),
        preact_1.h("form", { onSubmit: handleSubmit(onSubmit, onError) },
            pageState.data.needsForm && (preact_1.h(preact_1.Fragment, null,
                pageState.data.customField.companyName && (preact_1.h("div", { className: styles.mb10 },
                    preact_1.h("label", { className: styles.label }, "\u4F1A\u793E\u540D"),
                    preact_1.h(component_1.Input, { className: "" + styles.input, name: "companyName", ref: register({
                            required: {
                                value: true,
                                message: "必須項目です",
                            },
                            maxLength: {
                                value: 40,
                                message: "最大文字数を超えています",
                            },
                        }), "data-error": formState.errors.companyName !== undefined }),
                    ((_b = (_a = formState.errors) === null || _a === void 0 ? void 0 : _a.companyName) === null || _b === void 0 ? void 0 : _b.message) && (preact_1.h("p", { className: styles.errorMessage }, formState.errors.companyName.message)))),
                pageState.data.customField.companyPicName && (preact_1.h("div", { className: styles.mb10 },
                    preact_1.h("label", { className: styles.label }, "\u304A\u540D\u524D"),
                    preact_1.h(component_1.Input, { className: "" + styles.input, name: "name", ref: register({
                            required: {
                                value: true,
                                message: "必須項目です",
                            },
                            maxLength: {
                                value: 40,
                                message: "最大文字数を超えています",
                            },
                        }), "data-error": formState.errors.name !== undefined }),
                    ((_d = (_c = formState.errors) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.message) && (preact_1.h("p", { className: styles.errorMessage }, formState.errors.name.message)))),
                pageState.data.customField.email && (preact_1.h("div", { className: styles.mb10 },
                    preact_1.h("label", { className: styles.label }, "Email"),
                    preact_1.h(component_1.Input, { className: "" + styles.input, name: "email", ref: register({
                            maxLength: {
                                value: 255,
                                message: "最大文字数を超えています",
                            },
                            required: {
                                value: true,
                                message: "必須項目です",
                            },
                            validate: {
                                isHalfWidth: function (value) {
                                    return /[ -~]+/i.test(value) ||
                                        "半角文字で入力してください。";
                                },
                                isEmail: function (value) {
                                    return /^[\w+\-.]+@[a-z\d-]+(\.[a-z\d-]+)*\.[a-z]+$/i.test(value) || "有効なEmailを入力してください";
                                },
                            },
                        }), "data-error": formState.errors.email !== undefined }),
                    ((_f = (_e = formState.errors) === null || _e === void 0 ? void 0 : _e.email) === null || _f === void 0 ? void 0 : _f.message) && (preact_1.h("p", { className: styles.errorMessage }, formState.errors.email.message)))),
                pageState.data.customField.phoneNumber && (preact_1.h("div", { className: styles.mb10 },
                    preact_1.h("label", { className: styles.label }, "\u96FB\u8A71\u756A\u53F7"),
                    preact_1.h(component_1.Input, { className: "" + styles.input, name: "phone", ref: register({
                            maxLength: {
                                value: 20,
                                message: "最大桁数を超えています",
                            },
                            minLength: {
                                value: 8,
                                message: "電話番号は最低8桁必要です",
                            },
                            required: {
                                value: true,
                                message: "必須項目です",
                            },
                            validate: {
                                isOnlyNumber: function (value) {
                                    return /^[0-9]+$/i.test(value) ||
                                        "半角数字で入力してください";
                                },
                            },
                        }), "data-error": formState.errors.phone !== undefined }),
                    ((_h = (_g = formState.errors) === null || _g === void 0 ? void 0 : _g.phone) === null || _h === void 0 ? void 0 : _h.message) && (preact_1.h("p", { className: styles.errorMessage }, formState.errors.phone.message)))))),
            preact_1.h(component_1.Button, { type: "submit", className: styles.button, disabled: !formState.isValid, skin: formState.isValid ? "primary" : "disabled" }, "\u9001\u4FE1\u3057\u3066\u30B3\u30F3\u30C6\u30F3\u30C4\u3092\u307F\u308B")))) : pageState.tag === "error" ? (preact_1.h("p", null, pageState.message)) : (preact_1.h("div", null))));
};
exports.EntryForm = EntryForm;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
