"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resToV1Model = void 0;
var type_1 = require("@kaizen-dx-tools/type");
var resToV1Model = function (res) {
    var creator = res.created_by;
    var config = { loggerScriptKey: res.logger_script_key };
    var hearingSetResponse = res.embedding_interactive_hearing_set
        ? res.embedding_interactive_hearing_set.hearing_set
        : res.hearing_set;
    if (res.embedding_video) {
        var content = {
            id: type_1.toContentId(res.embedding_video.content.id),
            contentType: res.embedding_video.content.content_type,
            thumbnail: res.embedding_video.content.kaizen_files_thumbnail_url || undefined,
            url: res.embedding_video.content.kaizen_files_url || undefined,
            videoId: res.embedding_video.content.kaizen_files_id || undefined,
            duration: res.embedding_video.content.duration || 0,
        };
        return {
            creator: creator,
            config: config,
            content: content,
            type: "simple",
            showPlaybackControl: res.embedding_video.show_playback_control,
            showVolumeControl: res.embedding_video.show_volume_control,
            showCenterPlaybackButton: res.embedding_video.show_center_playback_button,
            fullscreen: res.embedding_video.fullscreen,
            endScreenType: res.embedding_video.end_screen_type,
            autoplay: res.embedding_video.autoplay,
            muted: res.embedding_video.muted,
            serviceResourceIdForLogger: res.service_resource_id_for_logger,
            serviceResourceIdForEmbeddingVideo: res.embedding_video.service_resource_id_for_logger,
            title: res.embedding_video.title || "",
            publicDescription: res.embedding_video.public_description || "",
            createdAt: res.embedding_video.created_at,
        };
    }
    else if (hearingSetResponse) {
        var questions_1 = new Map();
        var choices_1 = new Map();
        var questionSets_1 = new Map();
        var questionIds_1 = [];
        var contents_1 = new Map();
        hearingSetResponse.question_sets.forEach(function (qs) {
            var questionSetMap = {
                id: type_1.toQuestionSetID(qs.id),
                hearingSetId: qs.hearing_set_id || hearingSetResponse.id,
                orderIndex: qs.order_index,
                popUpFrame: qs.pop_up_frame,
                colorCode: qs.color_code,
                choicesColumnCount: qs.choices_column_count,
                borderRadius: qs.border_radius,
                isSkipQuestions: qs.is_skip_questions,
                serviceResourceIdForLogger: qs.service_resource_id_for_logger,
                createdAt: qs.created_at,
            };
            questionSets_1.set(type_1.toQuestionSetID(qs.id), questionSetMap);
            contents_1.set(type_1.toQuestionSetID(qs.id), {
                id: type_1.toContentId(qs.content.id),
                contentType: qs.content.content_type || undefined,
                thumbnail: qs.content.kaizen_files_thumbnail_url || undefined,
                url: qs.content.kaizen_files_url || undefined,
                videoId: qs.content.kaizen_files_id || undefined,
                duration: qs.content.duration || 0,
            });
            qs.questions.forEach(function (q) {
                var map = {
                    id: type_1.toQuestionID(q.id),
                    isMultipleChoice: q.is_multiple_choice,
                    hasOther: q.has_other,
                    body: q.body,
                    choiceIds: q.choices.map(function (c) { return type_1.toChoiceID(c.id); }),
                    questionSetId: type_1.toQuestionSetID(q.question_set_id),
                    serviceResourceIdForLogger: qs.service_resource_id_for_logger,
                };
                questions_1.set(type_1.toQuestionID(q.id), map);
                var cs = q.choices.map(function (c) {
                    var choice = {
                        id: type_1.toChoiceID(c.id),
                        content: c.content,
                        ctaLink: c.cta_link || null,
                        nextQuestionSetId: c.next_question_set_id
                            ? type_1.toQuestionSetID(c.next_question_set_id)
                            : null,
                    };
                    return choice;
                });
                choices_1.set(type_1.toQuestionID(q.id), cs);
                qs.questions.forEach(function (q) {
                    questionIds_1.push(type_1.toQuestionID(q.id));
                });
            });
        });
        var hearingSet = {
            title: hearingSetResponse.title,
            hearingSetId: hearingSetResponse.id,
            questionIds: questionIds_1,
            contentIds: res.product_contents
                ? res.product_contents.map(function (c) { return type_1.toContentId(c.id); })
                : [],
            isInteractive: hearingSetResponse.is_interactive,
            createdAt: hearingSetResponse.created_at,
        };
        return {
            creator: creator,
            config: config,
            questionSets: questionSets_1,
            questions: questions_1,
            choices: choices_1,
            contents: contents_1,
            hearingSet: hearingSet,
            serviceResourceIdForLogger: res.service_resource_id_for_logger,
            type: "questionnaire",
        };
    }
    else if (res.content_playlist) {
        var productContents = res.content_playlist.product_contents.map(function (productContent) { return ({
            id: type_1.toContentId(productContent.id),
            title: productContent.title,
            status: productContent.status,
            publicDescription: productContent.public_description || "",
            createdAt: productContent.created_at,
            content: {
                id: type_1.toContentId(productContent.content.id),
                contentType: productContent.content.content_type,
                url: productContent.content.kaizen_files_url || undefined,
                thumbnail: productContent.content.kaizen_files_thumbnail_url || undefined,
                videoId: productContent.content.kaizen_files_id || undefined,
                duration: productContent.content.duration || 0,
            },
            serviceResourceIdForLogger: productContent.service_resource_id_for_logger,
        }); });
        var contentPlaylist = {
            id: res.content_playlist.id,
            title: res.content_playlist.title,
            status: res.content_playlist.status,
            description: res.content_playlist.description || "",
            thumbnail: res.content_playlist.thumbnail_url,
            productContentCounts: res.content_playlist.product_contents_count,
            productContents: productContents,
        };
        return {
            creator: creator,
            config: config,
            type: "playlist",
            serviceResourceIdForLogger: res.service_resource_id_for_logger,
            contentPlaylist: contentPlaylist,
        };
    }
    throw new Error("invalid response");
};
exports.resToV1Model = resToV1Model;
