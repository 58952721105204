"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Errors = void 0;
exports.Errors = {
    // FETCH ERROR
    NETWORK_CONNECTION_ERROR: new Error("fail to connect"),
    JSON_PARSE_ERROR: new Error("fail to parse JSON"),
    INVALID_DATA_ERROR: new Error("invalid data format"),
    INTERNAL_SERVER_ERROR: new Error("invalid data format"),
    FORBIDDEN: new Error("forbidden"),
    DATA_NOT_FOUND: new Error("not found"),
    CLIENT_ERROR: new Error("request contains bad syntax or cannot be fulfilled"),
    // Unexpected Operation
    UNEXPECTED_VALUE: new Error("unexpected value"),
    UNREACHABLE_LINE: new Error("unexpected call"),
    // 回復不能なエラー
    NOT_FOUND_MOUNT_TARGET: new Error("not found mount target"),
    NOT_FOUND_KAIZEN_HEARING_TOKEN: new Error("not found kzHearingToken"),
    NOT_FOUND_CLASSNAME: new Error("invalid class name"),
    INSUFFICIENT_ENV_ERROR: new Error("insufficient env error"),
};
