"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v1Repository = exports.v2Repository = void 0;
var v1_1 = require("./v1");
exports.v2Repository = {
    saveHearingAnswer: v1_1.saveHearingAnswer,
    getHearingLinks: v1_1.getHearingLinks,
};
exports.v1Repository = {
    getSharingLinks: v1_1.getSharingLinks,
    createResult: v1_1.createResult,
    createSessionVisits: v1_1.createSessionVisits,
    crateProductContentResult: v1_1.crateProductContentResult,
    sendDocumentViewingLogs: v1_1.sendDocumentViewingLogs,
    sendVideoViewingLogs: v1_1.sendVideoViewingLogs,
    getHearingLinks: v1_1.getHearingLinks,
    saveHearingAnswer: v1_1.saveHearingAnswer,
    createSessionForms: v1_1.createSessionForms,
};
