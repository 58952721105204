"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v2QuestionSchema = void 0;
var zod_1 = require("zod");
var choice_1 = require("./choice");
exports.v2QuestionSchema = zod_1.z.object({
    id: zod_1.z.number(),
    question_set_id: zod_1.z.number(),
    is_multiple_choice: zod_1.z.boolean(),
    is_overlay: zod_1.z.boolean(),
    has_other: zod_1.z.boolean(),
    body: zod_1.z.string(),
    choices: zod_1.z.array(choice_1.choiceSchema),
});
