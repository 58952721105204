"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertNever = void 0;
var error_1 = require("@kaizen-dx-tools/error");
/**
 * 網羅性チェックに使う
 * @param x 呼ばれるはずのない引数
 */
var assertNever = function (x) {
    throw error_1.Errors.UNREACHABLE_LINE;
};
exports.assertNever = assertNever;
