"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@kaizen-dx-tools/util/src/object-assign-polyfil");
require("url-search-params-polyfill");
require("babel-polyfill");
require("fetch-polyfill");
var util_1 = require("@kaizen-dx-tools/util");
var browser_1 = require("@sentry/browser");
var tracing_1 = require("@sentry/tracing");
var goober_1 = require("goober");
var preact_1 = require("preact");
var routing_1 = require("./module/routing");
var root_1 = require("./pages/root");
goober_1.setup(preact_1.h);
util_1.resetCSS();
browser_1.init({
    dsn: process.env.SENTRY_API_DSN,
    environment: process.env.STAGE || process.env.NODE_ENV,
    integrations: [new tracing_1.Integrations.BrowserTracing()],
});
var App = function () {
    return (preact_1.h(routing_1.RoutingContextProvider, null,
        preact_1.h(root_1.Root, null)));
};
preact_1.render(preact_1.h(App, null), document.body);
