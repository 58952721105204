"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v1ContentForEmbeddedSchema = void 0;
var zod_1 = require("zod");
exports.v1ContentForEmbeddedSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content_type: zod_1.z.literal("videos"),
    kaizen_files_bucket_id: zod_1.z.string().nullable(),
    kaizen_files_id: zod_1.z.string().nullable(),
    kaizen_files_thumbnail_url: zod_1.z.string().nullable(),
    kaizen_files_url: zod_1.z.string().nullable(),
    duration: zod_1.z.number().nullable(),
});
