"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.choiceSchema = void 0;
// Generated by ts-to-zod
var zod_1 = require("zod");
exports.choiceSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content: zod_1.z.string(),
    cta_link: zod_1.z.string().nullable().optional(),
    next_question_set_id: zod_1.z.number().nullable().optional(),
});
