"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLoggerEndpoint = exports.myFetch = void 0;
var error_1 = require("@kaizen-dx-tools/error");
var util_1 = require("@kaizen-dx-tools/util");
var myFetch = function (path, method, data, withCookie, origin) {
    if (method === void 0) { method = "GET"; }
    if (withCookie === void 0) { withCookie = false; }
    var origin_ = origin || util_1.getOrigin();
    var url = "" + origin_ + path;
    if (method === "GET") {
        return fetch(url, {
            method: method,
            credentials: withCookie ? "include" : "omit",
        });
    }
    return fetch(url, {
        method: method,
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: withCookie ? "include" : "omit",
    });
};
exports.myFetch = myFetch;
var getLoggerEndpoint = function () {
    var loggerEndpoint = process.env.VIDEO_LOGGER_ENDPOINT;
    if (loggerEndpoint === undefined)
        throw error_1.Errors.INSUFFICIENT_ENV_ERROR;
    return loggerEndpoint;
};
exports.getLoggerEndpoint = getLoggerEndpoint;
