"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header = void 0;
var preact_1 = require("preact");
var src_1 = require("../../../../../lib/util/src");
var mobile_header_1 = require("./mobile-header");
var pc_header_1 = require("./pc-header");
var Header = function () {
    var mobile = src_1.isMobile();
    return mobile ? preact_1.h(mobile_header_1.MobileHeader, null) : preact_1.h(pc_header_1.PCHeader, null);
};
exports.Header = Header;
