"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConvertDataModel = void 0;
var type_1 = require("@kaizen-dx-tools/type");
var hooks_1 = require("preact/hooks");
var src_1 = require("../../../../lib/util/src");
var data_cache_1 = require("../module/data-cache");
var useConvertDataModel = function () {
    var _a = hooks_1.useState({
        type: "init",
        data: undefined,
    }), pageState = _a[0], setPageState = _a[1];
    var dataCache = data_cache_1.useDataCache();
    hooks_1.useEffect(function () {
        var _a, _b, _c;
        if (dataCache.state === "success") {
            var data = dataCache.data;
            var questioner = {
                name: dataCache.data.created_by.username,
                profileImageUrl: dataCache.data.created_by.profile_image_url,
            };
            if (data.content_type === "hearing_set" && "hearing_set" in data) {
                //   hearing の分岐
                var questions_1 = new Map();
                var choices_1 = new Map();
                var contents_1 = new Map();
                var questionSet_1 = new Map();
                var firstQSWithVideoContent_1 = data.hearing_set.question_sets.find(function (qs) { var _a; return ((_a = qs.content) === null || _a === void 0 ? void 0 : _a.content_type) === "videos"; });
                data.hearing_set.question_sets.forEach(function (qs) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                    var questionSetID = type_1.V1NEW_toQuestionSetID(qs.id);
                    // question の構築
                    questionSet_1.set(questionSetID, qs.questions.map(function (q) { return type_1.toQuestionID(q.id); }));
                    if (qs.content.content_type) {
                        var contentID = type_1.toContentId(qs.content.id);
                        if (qs.content.content_type === "images") {
                            var content = {
                                id: contentID,
                                contentType: (_a = qs.content) === null || _a === void 0 ? void 0 : _a.content_type,
                                url: (_b = qs.content) === null || _b === void 0 ? void 0 : _b.kaizen_files_url,
                                thumbnail: qs.content.kaizen_files_url,
                                uploadStatus: (_c = qs.content) === null || _c === void 0 ? void 0 : _c.upload_status,
                            };
                            contents_1.set(questionSetID, content);
                        }
                        else if (qs.content.content_type === "pdfs") {
                            var content = {
                                id: contentID,
                                contentType: (_d = qs.content) === null || _d === void 0 ? void 0 : _d.content_type,
                                url: (_e = qs.content) === null || _e === void 0 ? void 0 : _e.kaizen_files_url,
                                thumbnail: ((_f = qs.content) === null || _f === void 0 ? void 0 : _f.kaizen_files_thumbnail_url) || undefined,
                                uploadStatus: (_g = qs.content) === null || _g === void 0 ? void 0 : _g.upload_status,
                                extractedImages: (_h = qs.content) === null || _h === void 0 ? void 0 : _h.extracted_images,
                            };
                            contents_1.set(questionSetID, content);
                        }
                        else if (qs.content.content_type === "videos") {
                            var content = {
                                id: contentID,
                                contentType: (_j = qs.content) === null || _j === void 0 ? void 0 : _j.content_type,
                                url: (_k = qs.content) === null || _k === void 0 ? void 0 : _k.kaizen_files_url,
                                fileId: qs.content.kaizen_files_id,
                                thumbnail: ((_l = qs.content) === null || _l === void 0 ? void 0 : _l.kaizen_files_thumbnail_url) || undefined,
                                uploadStatus: (_m = qs.content) === null || _m === void 0 ? void 0 : _m.upload_status,
                                chapters: ((_o = qs.content) === null || _o === void 0 ? void 0 : _o.chapters) || [],
                                isFirstVideo: !!firstQSWithVideoContent_1 &&
                                    firstQSWithVideoContent_1.id === qs.id,
                            };
                            contents_1.set(questionSetID, content);
                        }
                        else if (qs.content.content_type === "links") {
                            var content = {
                                id: contentID,
                                contentType: (_p = qs.content) === null || _p === void 0 ? void 0 : _p.content_type,
                                url: qs.content.url,
                                thumbnail: qs.content.thumbnail,
                            };
                            contents_1.set(questionSetID, content);
                        }
                        else {
                            src_1.assertNever(qs.content.content_type);
                        }
                    }
                    else {
                        contents_1.set(questionSetID, undefined);
                    }
                    qs.questions.forEach(function (q) {
                        var questionId = type_1.toQuestionID(q.id);
                        var question = {
                            id: questionId,
                            isMultipleChoice: q.is_multiple_choice,
                            hasOther: q.has_other,
                            body: q.body,
                            choiceIds: q.choices.map(function (c) { return type_1.toChoiceID(c.id); }),
                            questionSetId: questionSetID,
                            serviceResourceIdForLogger: qs.service_resource_id_for_logger,
                        };
                        questions_1.set(questionId, question);
                        var choiceArr = q.choices.map(function (c) { return ({
                            id: type_1.toChoiceID(c.id),
                            content: c.content,
                            feedback: c.feedback,
                        }); });
                        choices_1.set(questionId, choiceArr);
                    });
                });
                var hearingSet = {
                    hearingSetId: data.hearing_set_id,
                    title: data.title,
                    questionIds: Array.from(questions_1.keys()),
                    contentIds: Array.from(contents_1.values()).map(function (c) { return c === null || c === void 0 ? void 0 : c.id; }),
                    orgLogo: data.organization_logo || undefined,
                };
                var needForm = false;
                for (var key in data.custom_fields) {
                    if (data.custom_fields[key]) {
                        needForm =
                            data.custom_fields[key];
                        break;
                    }
                    //no-op
                }
                var customField = {
                    companyName: data.custom_fields.company_name,
                    companyPicName: data.custom_fields.company_pic_name,
                    email: data.custom_fields.email,
                    phoneNumber: data.custom_fields.phone_number,
                };
                var config = {
                    cta: data.cta ? data.cta.filter(function (ctaItem) { return !!ctaItem.link_to; }) : [],
                    ctaAutoRedirect: ((_a = data.cta) === null || _a === void 0 ? void 0 : _a.length) === 1 && !!data.cta[0].auto_redirect,
                    needsFeedBack: data.needs_feedback,
                    loggerScriptKey: data.logger_script_key,
                    serviceResourceIdForLogger: data.service_resource_id_for_logger,
                    linkType: data.link_type,
                    needsForm: needForm,
                    customField: customField,
                    questioner: questioner,
                };
                var hearingSets = {
                    hearingSet: hearingSet,
                    questions: questions_1,
                    choices: choices_1,
                    contents: contents_1,
                    questionSet: questionSet_1,
                };
                setPageState({
                    type: "hearing",
                    data: hearingSets,
                    config: config,
                });
            }
            else if (data.content_type === "product_contents" &&
                "product_contents" in data) {
                var firstVideo_1 = data.product_contents.find(function (content) { return content.content_type === "videos"; }) || null;
                var products = data.product_contents.map(function (d) {
                    if (d.content_type === "videos") {
                        return {
                            id: type_1.V1New_toProductContentId(d.id),
                            contentId: d.content_id,
                            title: d.title || "",
                            public_description: d.public_description || "",
                            uploadStatus: d.upload_status,
                            status: d.status,
                            fileId: d.kaizen_files_id,
                            url: d.kaizen_files_url || undefined,
                            updatedAt: new Date(d.updated_at),
                            contentType: d.content_type,
                            thumbnail: d.kaizen_files_thumbnail_url || undefined,
                            serviceResourceIdForLogger: d.service_resource_id_for_logger,
                            extractedImages: d.extracted_images,
                            chapters: d.chapters || [],
                            isFirstVideo: !!firstVideo_1 && d.id === firstVideo_1.id,
                        };
                    }
                    if (d.content_type === "images") {
                        return {
                            id: type_1.V1New_toProductContentId(d.id),
                            contentId: d.content_id,
                            title: d.title || "",
                            public_description: d.public_description || "",
                            uploadStatus: d.upload_status,
                            status: d.status,
                            fileId: undefined,
                            url: d.kaizen_files_url || undefined,
                            updatedAt: new Date(d.updated_at),
                            contentType: d.content_type,
                            thumbnail: d.kaizen_files_url || undefined,
                            serviceResourceIdForLogger: d.service_resource_id_for_logger,
                            extractedImages: d.extracted_images,
                        };
                    }
                    return {
                        id: type_1.V1New_toProductContentId(d.id),
                        contentId: d.content_id,
                        title: d.title || "",
                        public_description: d.public_description || "",
                        uploadStatus: d.upload_status,
                        status: d.status,
                        fileId: undefined,
                        url: d.kaizen_files_url,
                        updatedAt: new Date(d.updated_at),
                        contentType: d.content_type,
                        thumbnail: d.kaizen_files_thumbnail_url || undefined,
                        serviceResourceIdForLogger: d.service_resource_id_for_logger,
                        extractedImages: d.extracted_images,
                    };
                });
                var needForm = false;
                for (var key in data.custom_fields) {
                    if (data.custom_fields[key]) {
                        needForm =
                            data.custom_fields[key];
                        break;
                    }
                    //no-op
                }
                var customField = {
                    companyName: data.custom_fields.company_name,
                    companyPicName: data.custom_fields.company_pic_name,
                    email: data.custom_fields.email,
                    phoneNumber: data.custom_fields.phone_number,
                };
                var config = {
                    cta: data.cta ? data.cta.filter(function (cta) { return !!cta.link_to; }) : [],
                    ctaAutoRedirect: ((_b = data.cta) === null || _b === void 0 ? void 0 : _b.length) === 1 && !!data.cta[0].auto_redirect,
                    needsFeedBack: data.needs_feedback,
                    loggerScriptKey: data.logger_script_key,
                    serviceResourceIdForLogger: data.service_resource_id_for_logger,
                    linkType: data.link_type,
                    needsForm: needForm,
                    customField: customField,
                    questioner: questioner,
                    isPlaylist: false,
                };
                setPageState({
                    type: "showProduct",
                    data: products,
                    config: config,
                    orgLogo: data.organization_logo || undefined,
                    // TODO: ここが null は本来あり得ないはずなのでAPIチームに問い合わせる。
                    hearingSetId: data.hearing_set_id || undefined,
                });
            }
            else if (data.content_type === "playlist" &&
                "content_playlist" in data) {
                var firstVideo_2 = data.content_playlist.product_contents
                    ? data.content_playlist.product_contents.find(function (content) { return content.content_type === "videos"; })
                    : null;
                var products = data.content_playlist
                    .product_contents
                    ? data.content_playlist.product_contents.map(function (d) {
                        if (d.content_type === "videos") {
                            return {
                                id: type_1.V1New_toProductContentId(d.id),
                                contentId: d.content_id,
                                title: d.title || "",
                                public_description: d.public_description || "",
                                uploadStatus: d.upload_status,
                                status: d.status,
                                fileId: d.kaizen_files_id,
                                url: d.kaizen_files_url || undefined,
                                updatedAt: new Date(d.updated_at),
                                contentType: d.content_type,
                                thumbnail: d.kaizen_files_thumbnail_url || undefined,
                                serviceResourceIdForLogger: d.service_resource_id_for_logger,
                                extractedImages: d.extracted_images,
                                chapters: d.chapters || [],
                                isFirstVideo: !!firstVideo_2 && d.id === firstVideo_2.id,
                            };
                        }
                        if (d.content_type === "images") {
                            return {
                                id: type_1.V1New_toProductContentId(d.id),
                                contentId: d.content_id,
                                title: d.title || "",
                                public_description: d.public_description || "",
                                uploadStatus: d.upload_status,
                                status: d.status,
                                fileId: undefined,
                                url: d.kaizen_files_url || undefined,
                                updatedAt: new Date(d.updated_at),
                                contentType: d.content_type,
                                thumbnail: d.kaizen_files_url || undefined,
                                serviceResourceIdForLogger: d.service_resource_id_for_logger,
                                extractedImages: d.extracted_images,
                            };
                        }
                        return {
                            id: type_1.V1New_toProductContentId(d.id),
                            contentId: d.content_id,
                            title: d.title || "",
                            public_description: d.public_description || "",
                            uploadStatus: d.upload_status,
                            status: d.status,
                            fileId: undefined,
                            url: d.kaizen_files_url || undefined,
                            updatedAt: new Date(d.updated_at),
                            contentType: d.content_type,
                            thumbnail: d.kaizen_files_thumbnail_url || undefined,
                            serviceResourceIdForLogger: d.service_resource_id_for_logger,
                            extractedImages: d.extracted_images,
                        };
                    })
                    : [];
                var needForm = false;
                for (var key in data.custom_fields) {
                    if (data.custom_fields[key]) {
                        needForm =
                            data.custom_fields[key];
                        break;
                    }
                    //no-op
                }
                var customField = {
                    companyName: data.custom_fields.company_name,
                    companyPicName: data.custom_fields.company_pic_name,
                    email: data.custom_fields.email,
                    phoneNumber: data.custom_fields.phone_number,
                };
                var config = {
                    cta: data.cta ? data.cta.filter(function (cta) { return !!cta.link_to; }) : [],
                    ctaAutoRedirect: ((_c = data.cta) === null || _c === void 0 ? void 0 : _c.length) === 1 && !!data.cta[0].auto_redirect,
                    needsFeedBack: data.needs_feedback,
                    loggerScriptKey: data.logger_script_key,
                    serviceResourceIdForLogger: data.service_resource_id_for_logger,
                    linkType: data.link_type,
                    needsForm: needForm,
                    customField: customField,
                    questioner: questioner,
                    isPlaylist: true,
                };
                setPageState({
                    type: "showProduct",
                    data: products,
                    config: config,
                    orgLogo: data.organization_logo || undefined,
                    hearingSetId: data.hearing_set_id || undefined,
                });
            }
            else {
                throw new Error("データ不正・紹介用コンテンツもヒアリング動画もありません。");
            }
        }
        else {
            setPageState({ type: "error", data: undefined });
        }
    }, [dataCache]);
    return pageState;
};
exports.useConvertDataModel = useConvertDataModel;
