"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromRightToLeftAnimation = exports.fromLeftToRightAnimation = void 0;
var goober_1 = require("goober");
var fromRightToLeft = goober_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    from {\n      opacity: 0;\n      margin-left: 15%;\n    }\n    to {\n      opacity: 1;\n      margin-left: 0;\n    }\n"], ["\n    from {\n      opacity: 0;\n      margin-left: 15%;\n    }\n    to {\n      opacity: 1;\n      margin-left: 0;\n    }\n"])));
var fromLeftToRight = goober_1.keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n from {\n      opacity: 0;\n      margin-left: -15%;\n    }\n    to {\n      opacity: 1;\n      margin-left: 0;\n    }\n"], ["\n from {\n      opacity: 0;\n      margin-left: -15%;\n    }\n    to {\n      opacity: 1;\n      margin-left: 0;\n    }\n"])));
exports.fromLeftToRightAnimation = goober_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  animation-name: ", ";\n  animation-duration: 0.6s;\n"], ["\n  animation-name: ", ";\n  animation-duration: 0.6s;\n"])), fromLeftToRight);
exports.fromRightToLeftAnimation = goober_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  animation-name: ", ";\n  animation-duration: 0.6s;\n"], ["\n  animation-name: ", ";\n  animation-duration: 0.6s;\n"])), fromRightToLeft);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
