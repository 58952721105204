"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v1ContentPlaylistSchema = void 0;
var zod_1 = require("zod");
var v1_product_content_1 = require("./v1-product-content");
exports.v1ContentPlaylistSchema = zod_1.z.object({
    created_at: zod_1.z.string(),
    updated_at: zod_1.z.string(),
    id: zod_1.z.number(),
    title: zod_1.z.string(),
    status: zod_1.z.union([
        zod_1.z.literal("draft"),
        zod_1.z.literal("published"),
        zod_1.z.literal("archived"),
    ]),
    description: zod_1.z.string().nullable().optional(),
    thumbnail_url: zod_1.z.string().nullable(),
    product_contents_count: zod_1.z.number(),
    product_contents: zod_1.z.union([
        zod_1.z.array(zod_1.z.union([
            v1_product_content_1.v1ProductContentSchema,
            v1_product_content_1.v1ProductContentPdfSchema,
            v1_product_content_1.v1ProductImageSchema,
        ])),
        zod_1.z.null(),
    ]),
});
