"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v1ContentSchema = void 0;
var zod_1 = require("zod");
var chapter_1 = require("./chapter");
var extracted_images_1 = require("./extracted-images");
var contentTypeSchema = zod_1.z.union([
    zod_1.z.literal("images"),
    zod_1.z.literal("links"),
    zod_1.z.literal("pdfs"),
    zod_1.z.literal("videos"),
]);
var NormalSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content_type: zod_1.z.union([
        zod_1.z.literal("images"),
        zod_1.z.literal("pdfs"),
        zod_1.z.literal("videos"),
    ]),
    kaizen_files_bucket_id: zod_1.z.string().nullable(),
    kaizen_files_url: zod_1.z.string(),
    kaizen_files_id: zod_1.z.string(),
    kaizen_files_thumbnail_url: zod_1.z.string().nullable(),
    upload_status: zod_1.z.union([
        zod_1.z.literal("not_uploaded"),
        zod_1.z.literal("uploaded"),
        zod_1.z.literal("upload_failed"),
    ]),
    extracted_images: zod_1.z.union([zod_1.z.null(), zod_1.z.undefined(), extracted_images_1.extractedImages]),
    chapters: zod_1.z.array(chapter_1.chapterSchema).nullable().optional(),
});
var Pdfschema = zod_1.z.object({
    id: zod_1.z.number(),
    content_type: zod_1.z.literal("pdfs"),
    kaizen_files_bucket_id: zod_1.z.string().nullable(),
    kaizen_files_url: zod_1.z.string(),
    kaizen_files_thumbnail_url: zod_1.z.string().nullable(),
    upload_status: zod_1.z.union([
        zod_1.z.literal("not_uploaded"),
        zod_1.z.literal("uploaded"),
        zod_1.z.literal("upload_failed"),
    ]),
    extracted_images: zod_1.z.union([zod_1.z.null(), zod_1.z.undefined(), extracted_images_1.extractedImages]),
});
var ImageSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content_type: zod_1.z.literal("images"),
    kaizen_files_bucket_id: zod_1.z.string().nullable(),
    kaizen_files_url: zod_1.z.string(),
    kaizen_files_thumbnail_url: zod_1.z.string().nullable(),
    upload_status: zod_1.z.union([
        zod_1.z.literal("not_uploaded"),
        zod_1.z.literal("uploaded"),
        zod_1.z.literal("upload_failed"),
    ]),
});
var LinkSchema = zod_1.z.object({
    id: zod_1.z.number(),
    content_type: zod_1.z.literal("links"),
    thumbnail: zod_1.z.string(),
    upload_status: zod_1.z.string(),
    url: zod_1.z.string(),
});
var noContent = zod_1.z.object({
    content_type: zod_1.z.undefined(),
    kaizen_files_bucket_id: zod_1.z.string().nullable(),
});
exports.v1ContentSchema = zod_1.z.union([
    NormalSchema,
    LinkSchema,
    ImageSchema,
    Pdfschema,
    noContent,
]);
