"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.distributeSharingLinkResponse = exports.getSharingLinksSchema = exports.sharingLinkWithContentPlaylistSchema = exports.sharingLinkWithProductContentSchema = exports.sharingLinkWithHearingSetSchema = void 0;
var error_1 = require("@kaizen-dx-tools/error");
var zod_1 = require("zod");
var created_by_1 = require("../partial/created-by");
var cta_1 = require("../partial/cta");
var custom_field_1 = require("../partial/custom-field");
var v1_hearing_set_1 = require("../partial/v1-hearing-set");
var v1_product_content_1 = require("../partial/v1-product-content");
var v1_content_playlist_1 = require("./../partial/v1-content-playlist");
exports.sharingLinkWithHearingSetSchema = zod_1.z.object({
    hearing_set_id: zod_1.z.number(),
    needs_feedback: zod_1.z.boolean(),
    custom_fields: custom_field_1.customField,
    cta: zod_1.z.union([zod_1.z.array(cta_1.ctaSchema), zod_1.z.null()]),
    created_by: created_by_1.createdBySchema,
    organization_logo: zod_1.z.union([zod_1.z.string(), zod_1.z.null()]),
    title: zod_1.z.string(),
    hearing_set: v1_hearing_set_1.v1HearingSetSchema,
    logger_script_key: zod_1.z.number(),
    link_type: zod_1.z.union([zod_1.z.literal("hearing"), zod_1.z.literal("default")]),
    service_resource_id_for_logger: zod_1.z.string(),
    content_type: zod_1.z.literal("hearing_set"),
    organization_id: zod_1.z.number().optional(),
});
exports.sharingLinkWithProductContentSchema = zod_1.z.object({
    hearing_set_id: zod_1.z.union([zod_1.z.number(), zod_1.z.null()]),
    needs_feedback: zod_1.z.boolean(),
    custom_fields: custom_field_1.customField,
    cta: zod_1.z.union([zod_1.z.array(cta_1.ctaSchema), zod_1.z.null()]),
    created_by: created_by_1.createdBySchema,
    organization_logo: zod_1.z.union([zod_1.z.string(), zod_1.z.null()]),
    product_contents: zod_1.z.array(zod_1.z.union([
        v1_product_content_1.v1ProductContentSchema,
        v1_product_content_1.v1ProductContentPdfSchema,
        v1_product_content_1.v1ProductImageSchema,
    ])),
    logger_script_key: zod_1.z.number(),
    link_type: zod_1.z.union([zod_1.z.literal("hearing"), zod_1.z.literal("default")]),
    service_resource_id_for_logger: zod_1.z.string(),
    content_type: zod_1.z.literal("product_contents"),
    organization_id: zod_1.z.number().optional(),
});
exports.sharingLinkWithContentPlaylistSchema = zod_1.z.object({
    custom_fields: custom_field_1.customField,
    content_type: zod_1.z.literal("playlist"),
    hearing_set_id: zod_1.z.null(),
    needs_feedback: zod_1.z.boolean(),
    cta: zod_1.z.union([zod_1.z.array(cta_1.ctaSchema), zod_1.z.null()]),
    service_resource_id_for_logger: zod_1.z.string(),
    logger_script_key: zod_1.z.number(),
    link_type: zod_1.z.union([zod_1.z.literal("hearing"), zod_1.z.literal("default")]),
    organization_logo: zod_1.z.union([zod_1.z.string(), zod_1.z.null()]),
    created_by: created_by_1.createdBySchema,
    content_playlist: v1_content_playlist_1.v1ContentPlaylistSchema,
    organization_id: zod_1.z.number().optional(),
});
exports.getSharingLinksSchema = zod_1.z.union([
    exports.sharingLinkWithHearingSetSchema,
    exports.sharingLinkWithProductContentSchema,
    exports.sharingLinkWithContentPlaylistSchema,
]);
var distributeSharingLinkResponse = function (res) {
    if (res.content_type === "product_contents") {
        return res;
    }
    if (res.content_type === "hearing_set") {
        return res;
    }
    if (res.content_type === "playlist") {
        return res;
    }
    throw error_1.Errors.INVALID_DATA_ERROR;
};
exports.distributeSharingLinkResponse = distributeSharingLinkResponse;
