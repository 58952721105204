"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceUseTag = void 0;
var replaceUseTag = function (root) {
    var _a;
    var nodeList = root.querySelectorAll("use") || [];
    var svgSprite = (_a = root.querySelector(".plyr__sprite")) === null || _a === void 0 ? void 0 : _a.querySelector("svg");
    if (nodeList.length > 0) {
        nodeList.forEach(function (node, index) {
            var _a;
            //Extract id of icon from <use> node
            var href = node.getAttribute("href");
            //Find target <symbol> element
            var targetSvg = svgSprite === null || svgSprite === void 0 ? void 0 : svgSprite.querySelector(href || "");
            if (!targetSvg)
                return;
            //Create <svg> element with contents and attributes copied from target <symbol>
            var cloneSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            cloneSvg.innerHTML = targetSvg.innerHTML || "";
            for (var i = 0, a = targetSvg.attributes, l = a.length; i < l; i++) {
                if (a[i].name !== "id") {
                    cloneSvg.setAttribute(a[i].name, a[i].value);
                }
                else {
                    cloneSvg.setAttribute("id", a[i].value + "-" + index);
                }
            }
            //Replace <use> by cloned svg
            (_a = node.parentNode) === null || _a === void 0 ? void 0 : _a.replaceChild(cloneSvg, node);
        });
    }
};
exports.replaceUseTag = replaceUseTag;
